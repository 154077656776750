import axios from 'axios';
import { getToken, refreshToken, logout } from './../utils/index';

const api_privada = axios.create({
    baseURL: "https://k7m7c8qigb.execute-api.us-east-1.amazonaws.com/prod/api/private-endpoint",
});

api_privada.interceptors.request.use(

    (config) => {

        const token = getToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;

    },
    (error) => {

        return Promise.reject(error);

    }
);

api_privada.interceptors.response.use((response) => response,

    async (error) => {

        const { response } = error;
        let statusCode = response?.status || 0;

        if (statusCode === 401) {

            try {

                await refreshToken();
                return api_privada.request(response.config);

            } catch (refreshError) {

                logout();
                window.location.reload();
                return Promise.reject(refreshError);

            }

        }

        return Promise.reject(error);
    }
);

export default api_privada;

import React from 'react';
import PropTypes from 'prop-types';
import { StyleMessageBox } from 'globalStyles';

const StatusMessage = ({ error, messages }) => {
    const messageArray = Array.isArray(messages) ? messages : [];

    if (messageArray.length === 0) return null;

    return (
        <StyleMessageBox className={`${error ? "bg-danger" : "bg-success"} text-white text-center`}
        >
            {messages.map((message, index) => (
                <p className="m-0" key={index}>{message}</p>
            ))}
        </StyleMessageBox>
    );
};

StatusMessage.propTypes = {
    error: PropTypes.bool.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StatusMessage;
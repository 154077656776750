import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "contexts/global-context";
import StyleModal, {
    StyleHeaderAuthModal,
    StyleTitleModal,
    StyleButtonClose,
    StyleContentModal,
    StyleContainerModal,
} from "./style";
import ForgotPassword from "./forgot-password";
import Login from "./login";
import Register from "./register";
import SuccessLogin from "./success-login";

export default () => {
    const { openAuthModal, setOpenAuthModal } = useContext(GlobalContext);
    const [showLogin, setShowLogin] = useState(true);
    const [showRegister, setShowRegister] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showSuccessLogin, setShowSuccessLogin] = useState(false);

    useEffect(() => {
        if (openAuthModal) {
            document.querySelector("body").classList.add("modal-open");
        } else {
            document.querySelector("body").classList.remove("modal-open");
        }
    }, [openAuthModal]);

    return (
        <StyleModal open={openAuthModal}>
            <div>
                <StyleContainerModal>
                    <StyleHeaderAuthModal>
                        <StyleButtonClose
                            onClick={() => setOpenAuthModal(false)}
                        >
                            <span>Fechar</span>
                            <img
                                src="/assets/img/vivamagia/icon-close.svg"
                                alt="Fechar"
                            />
                        </StyleButtonClose>
                        <StyleTitleModal>
                            {showLogin && "Já possui perfil?"}
                            {showRegister && "Cadastro"}
                            {showForgotPassword && "Recupere seu acesso"}
                        </StyleTitleModal>
                    </StyleHeaderAuthModal>
                    <StyleContentModal>
                        <ForgotPassword
                            setShowLogin={setShowLogin}
                            setShowForgotPassword={setShowForgotPassword}
                            showForgotPassword={showForgotPassword}
                        />
                        <Login
                            setShowRegister={setShowRegister}
                            setShowForgotPassword={setShowForgotPassword}
                            setShowLogin={setShowLogin}
                            showLogin={showLogin}
                            setShowSuccessLogin={setShowSuccessLogin}
                        />
                        <Register
                            setShowLogin={setShowLogin}
                            setShowRegister={setShowRegister}
                            showRegister={showRegister}
                        />
                        <SuccessLogin showSuccessLogin={showSuccessLogin} />
                    </StyleContentModal>
                </StyleContainerModal>
            </div>
        </StyleModal>
    );
};

import { useState } from "react";
import api_publica from './../../utils/api-publica';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import {
    ContainerSmall,
    StyleWrapperInput
} from "globalStyles";
import { StyleSubtitleModal } from "../style";
import StyleWrapper from "./style";
import Button from "components/button";

export default ({
    setShowLogin,
    setShowForgotPassword,
    showForgotPassword,
}) => {
    const { status, handleRequest } = useApiRequest();

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // 1: Send email, 2: Confirm code, 3: Reset password, 4: Success message
    const [step, setStep] = useState(1);

    const handleShowLogin = () => {
        setShowForgotPassword(false);
        setTimeout(() => { setShowLogin(true); }, 200);
    };

    const handleSubmitForgotPasswordSuccess = (response) => {
        setStep(2);
    };

    const handleSubmitForgotPassword = async (evt) => {
        evt.preventDefault();

        handleRequest(
            () => api_publica.post('/auth/forgot-password', { email }),
            handleSubmitForgotPasswordSuccess,
            "Código de confirmação enviado para o seu e-mail."
        );
    };

    const handleSubmitConfirmCodeSuccess = (response) => {
        setStep(3);
    };

    const handleSubmitConfirmCode = async (evt) => {
        evt.preventDefault();

        handleRequest(
            () => api_publica.post('/auth/validate-token', { email, token: code }),
            handleSubmitConfirmCodeSuccess,
            "Código confirmado. Agora defina sua nova senha."
        );
    };

    const handleSubmitNewPasswordSuccess = (response) => {
        setStep(4);
    };

    const handleSubmitNewPassword = async (evt) => {
        evt.preventDefault();

        const payload = {
            email,
            token: code,
            password: newPassword,
            confirmPassword: confirmPassword
        }

        handleRequest(
            () => api_publica.post('/auth/update-password', payload),
            handleSubmitNewPasswordSuccess,
            "Senha alterada com sucesso."
        );
    };

    return (
        <StyleWrapper open={showForgotPassword}>
            <ContainerSmall>
                {step === 1 && (
                    <form onSubmit={handleSubmitForgotPassword}>
                        <StyleWrapperInput>
                            <label htmlFor="email">E-mail</label>
                            <input
                                placeholder="Insira seu e-mail"
                                type="email"
                                id="email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </StyleWrapperInput>

                        <StatusMessage error={status.error} messages={status.messages} />

                        <StyleWrapperInput className="text-center mb-5">
                            <Button className="w-100" type="submit" disabled={status.disabled}>
                                Enviar
                            </Button>
                        </StyleWrapperInput>
                    </form>
                )}

                {step === 2 && (
                    <form onSubmit={handleSubmitConfirmCode}>
                        <StyleWrapperInput>
                            <label htmlFor="code">Código de Confirmação</label>
                            <input
                                placeholder="Insira o código recebido"
                                type="text"
                                id="code"
                                name="code"
                                value={code}
                                onChange={(e) => setCode(e.target.value.replace(/\D/g, ''))}
                                maxLength="6"
                                required
                            />
                        </StyleWrapperInput>

                        <StatusMessage error={status.error} messages={status.messages} />

                        <StyleWrapperInput className="text-center mb-5">
                            <Button className="w-100" type="submit" disabled={status.disabled}>
                                Confirmar Código
                            </Button>
                        </StyleWrapperInput>
                    </form>
                )}

                {step === 3 && (
                    <form onSubmit={handleSubmitNewPassword}>
                        <StyleWrapperInput>
                            <label htmlFor="newPassword">Nova Senha</label>
                            <input
                                placeholder="Insira sua nova senha"
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </StyleWrapperInput>
                        <StyleWrapperInput>
                            <label htmlFor="confirmPassword">Confirmar Nova Senha</label>
                            <input
                                placeholder="Confirme sua nova senha"
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </StyleWrapperInput>

                        <StatusMessage error={status.error} messages={status.messages} />

                        <StyleWrapperInput className="text-center mb-5">
                            <Button className="w-100" type="submit" disabled={status.disabled}>
                                Alterar Senha
                            </Button>
                        </StyleWrapperInput>
                    </form>
                )}

                {step === 4 && (
                    <div>
                        <div>
                            <StatusMessage error={status.error} messages={status.messages} />
                        </div>
                        <div>
                            <StyleWrapperInput>
                                <Button
                                    className="w-100"
                                    theme="theme2"
                                    onClick={handleShowLogin}
                                >
                                    Acessar minha conta
                                </Button>
                            </StyleWrapperInput>
                        </div>
                    </div>
                )}

                {step !== 4 && (
                    <div>
                        <StyleSubtitleModal>Já possui login?</StyleSubtitleModal>
                        <StyleWrapperInput>
                            <Button
                                className="w-100"
                                theme="theme2"
                                onClick={handleShowLogin}
                            >
                                Acessar minha conta
                            </Button>
                        </StyleWrapperInput>
                    </div>
                )}

            </ContainerSmall>
        </StyleWrapper>
    );
};

import { useState } from "react";
import Header from "components/header";
import api_privada from './../../components/utils/api-privada';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import Button from "components/button";
import Footer from "components/footer";
import {
    ContainerMedium,
    StyleWrapperInput,
    StyleTitlePageInternal,
    Loader
} from "globalStyles";
import { menuList } from "components/utils/constants";
import StyleWrapperPage, {
    StyleSection,
    StyleFormWrapper,
    StyleFormFlex,
} from "./style";
import { StyleCard } from "pages/home/style";

export default () => {

    const { status, handleRequest } = useApiRequest();
    const [codigo, setCodigo] = useState("");

    const handleSuccess = (response) => {
        const { produtoCadastradoId } = response.data;
        window.location.href = `/premio-instantaneo?product-id=${produtoCadastradoId}`;
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        
        handleRequest(
            () => api_privada.post('/produto-cadastrado', { codigo }),
            handleSuccess,
            "Sucesso! Produto cadastrado."
        );
    };

    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <StyleSection>
                <ContainerMedium>
                    <StyleTitlePageInternal>Cadastro de produto</StyleTitlePageInternal>
                    <StyleFormWrapper>
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <StyleFormFlex>
                                <div>
                                    <StyleWrapperInput>
                                        <label htmlFor="codigo">Código de barras do produto</label>
                                        <input
                                            placeholder="Somente números"
                                            id="codigo"
                                            name="codigo"
                                            maxLength={13}
                                            value={codigo}
                                            onChange={(e) => setCodigo(e.target.value)}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <Button
                                        type="submit"
                                        theme="theme2"
                                        className="w-100 mt-3"
                                        disabled={status.disabled}
                                    >
                                        {(status.disabled) ? <Loader /> : "Avançar"}
                                    </Button>
                                </div>
                                <div>
                                    <StyleCard>
                                        <img src="/assets/img/vivamagia/barcode.png" alt="Imagem ilustrativa - Código de barras" />
                                    </StyleCard>
                                </div>
                            </StyleFormFlex>

                            <StatusMessage error={status.error} messages={status.messages} />

                        </form>
                    </StyleFormWrapper>
                </ContainerMedium>
                <Footer />
            </StyleSection>
        </StyleWrapperPage>
    );
};

import { useState } from "react";
import { jwtDecode } from 'jwt-decode';
import api_publica from './../../utils/api-publica';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import {
    ContainerSmall,
    StyleWrapperInput,
    Loader
} from "globalStyles";
import { StyleSubtitleModal } from "../style";
import StyleWrapper from "./style";
import Button from "components/button";

export default ({
    setShowRegister,
    setShowForgotPassword,
    setShowLogin,
    showLogin,
    setShowSuccessLogin,
}) => {
    const { status, handleRequest } = useApiRequest();

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const handleShowRegister = () => {
        setShowLogin(false);
        setTimeout(() => { setShowRegister(true); }, 200);
    };

    const handleShowForgotPassword = () => {
        setShowLogin(false);
        setTimeout(() => { setShowForgotPassword(true); }, 200);
    };

    const handleSuccess = (response) => {
        const { accessToken, refreshToken } = response.data;
        const decodedToken = jwtDecode(accessToken);
        const userData = {
            id: decodedToken.nameid,
            email: decodedToken.email,
            nome: decodedToken.unique_name,
        };

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem("userData", JSON.stringify(userData));

        setShowLogin(false);
        setTimeout(() => { setShowSuccessLogin(true); }, 200);
        
    };

    const handleSubmitLogin = async (evt) => {
        evt.preventDefault();

        handleRequest(
            () => api_publica.post('/auth', { email: email, password: password }),
            handleSuccess,
            "Sucesso! Login realizado."
        );
    };

    return (
        <StyleWrapper open={showLogin}>
            <ContainerSmall>
                <form onSubmit={(e) => handleSubmitLogin(e)}>
                    <StyleWrapperInput>
                        <label htmlFor="email">E-mail</label>
                        <input
                            placeholder="Insira seu e-mail"
                            type="email"
                            id="email"
                            name="email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            required
                        />
                    </StyleWrapperInput>
                    <StyleWrapperInput>
                        <label htmlFor="password">Senha</label>
                        <input
                            placeholder="Insira sua senha"
                            id="password"
                            type="password"
                            name="password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            required
                        />
                    </StyleWrapperInput>
                    <StyleWrapperInput className="text-center">
                        <Button className="w-100" type="submit" disabled={status.disabled} theme="theme2">
                            {(status.disabled) ? <Loader /> : "Entrar"}
                        </Button>
                    </StyleWrapperInput>

                    <StatusMessage error={status.error} messages={status.messages} />

                    <StyleWrapperInput className="text-center mb-5">
                        <button
                            className="btn-transparent"
                            type="button"
                            onClick={() => handleShowForgotPassword()}
                        >
                            Esqueci minha senha
                        </button>
                    </StyleWrapperInput>
                </form>
                <div>
                    <StyleSubtitleModal>
                        Não possui cadastro?
                    </StyleSubtitleModal>
                    <StyleWrapperInput className="text-center">
                        <Button
                            className="w-100"
                            onClick={() => handleShowRegister()}
                        >
                            Efetuar cadastro
                        </Button>
                    </StyleWrapperInput>
                </div>
            </ContainerSmall>
        </StyleWrapper>
    );
};

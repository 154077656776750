import styled from "styled-components";
import { colorToken } from "components/utils/constants";

export default styled.div`
    background-color: ${colorToken.white};
    margin-bottom: 16px;
    border-radius: 8px;
`
export const StyleAccordeonTitleToggle = styled.div`
    background-color: ${colorToken.black};
    border-radius: 8px;
    color: ${colorToken.white};
    cursor: pointer;
    padding: 8px;
    font-size: 1.7rem;
    font-weight: bold;

`
export const StyleAccordeonContent = styled.div`
    overflow: hidden;
    color: ${colorToken.black};
    font-size: 1.7rem;
    p {
      padding: 8px;  
    }
    ${({ open }) => (!open) && `
        max-height: 0;
        transition: max-height 0.15s ease-out;
        
    `}
    ${({ open }) =>  (open) && `
        max-height: 8000px;
        transition: max-height 0.25s ease-in;
    `}
`


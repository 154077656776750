import { useState, useEffect } from "react";
import StyleAccordeonWrapper, {
  StyleAccordeonTitleToggle,
  StyleAccordeonContent,
} from "./style";

export default ({ title, content, groupName, index, children }) => {
  const [accordeonState, setAccordeonState] = useState(false);
  const handleAccordeon = () => {
    window[groupName].forEach((setElementAccordeon) => {
      setElementAccordeon(false);
    });
    setAccordeonState(!accordeonState);
  };
  useEffect(() => {
    if (Boolean(window[groupName])) {
      window[groupName][index] = setAccordeonState;
    } else {
      window[groupName] = [];
      window[groupName][index] = setAccordeonState;
    }
  }, [groupName, index]);
  return (
    <StyleAccordeonWrapper>
      <StyleAccordeonTitleToggle onClick={() => handleAccordeon()}>
        {index + 1} - {title}
      </StyleAccordeonTitleToggle>
      <StyleAccordeonContent open={accordeonState}>
          <p>
            {children}
          </p>
        </StyleAccordeonContent>
    </StyleAccordeonWrapper>
  );
};

import { useState } from "react";
import api_publica from '../../utils/api-publica';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import {
    StyleWrapperInput,
    ContainerMedium,
    ContainerSmall
} from "globalStyles";
import StyleWrapper, {
    StyleSuccessLoginWrapper,
    StyleTitleModal,
    StyleLegalText,
} from "./style";
import Button from "components/button";

export default ({ showSuccessLogin }) => {

    return (
        <StyleWrapper open={showSuccessLogin}>
            <ContainerSmall>
                <StyleTitleModal>
                    Para concluir a sua participação você precisa ter o produto adquirido em mãos
                </StyleTitleModal>
                <StyleSuccessLoginWrapper>
                    <div>
                        <Button as="a" href="/cadastrar-produto" theme="theme2">Sim, já tenho</Button>
                    </div>
                    <div>
                        <Button as="a" href="/" theme="theme2">Ainda não tenho*</Button>
                    </div>
                </StyleSuccessLoginWrapper>
                <StyleLegalText>
                    * Se ainda não adquiriu um produto, entre em contato com nossas consultoras ou faça sua compra diretamente pelo nosso site. <br />Caso já tenha adquirido, aguarde o recebimento e, em seguida, acesse o site para concluir o seu cadastro.
                </StyleLegalText>
            </ContainerSmall>
        </StyleWrapper>
    );
};

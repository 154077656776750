import Header from "components/header";
import Footer from "components/footer";
import { ContainerLarge } from "globalStyles";
import { menuList } from "components/utils/constants";
import StyleWrapperPage, {
    StyleWrapperContent,
    StyleRegulamentoWrapper,
    StyleRegulamentoContent,
    StyleTitlePage,
} from "./style";
export default () => {
    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <ContainerLarge className="homeflex">
                <StyleWrapperContent>
                    <StyleTitlePage>Regulamento</StyleTitlePage>
                    <StyleRegulamentoWrapper>
                        <StyleRegulamentoContent>
                            <div id="Regulamento1">
                                <p>
                                    <strong>
                                        REGULAMENTO/PLANO DE
                                        OPERA&Ccedil;&Atilde;O DA
                                        PROMO&Ccedil;&Atilde;O
                                    </strong>
                                </p>
                                <p>
                                    <strong>&ldquo;VIVA A MAGIA&rdquo;</strong>
                                </p>
                                <p>
                                    <strong>
                                        CERTIFICADO DE AUTORIZA&Ccedil;&Atilde;O
                                        SPA/MF N&ordm;{" "}
                                    </strong>
                                    <strong>05.036558/2024</strong>
                                </p>
                                <strong>
                                    <strong>1</strong> <strong>-</strong>
                                    <strong>
                                        EMPRESAS MANDAT&Aacute;RIAS:
                                    </strong>
                                </strong>
                                <p>
                                    <strong>1.1</strong>{" "}
                                    <strong>&ndash;</strong>
                                    <strong>Empresa Mandat&aacute;ria</strong>:
                                </p>
                                <p>
                                    Raz&atilde;o Social:{" "}
                                    <strong>
                                        NATURA BIOSPHERA FRANQUEADORA LTDA
                                    </strong>
                                </p>
                                <p>
                                    Endere&ccedil;o: Avenida Alexandre Colares,
                                    n&ordm; 1188, salas 54, 55 e 58
                                </p>
                                <p>Bairro: Parque Anhanguera</p>
                                <p>Munic&iacute;pio: S&atilde;o Paulo UF: SP</p>
                                <p>CEP: 05.106-000</p>
                                <p>CNPJ/ME n&ordm;: 15.537.286/0001-16</p>
                                <p>
                                    <strong>1.2</strong>{" "}
                                    <strong>&ndash; Aderente:</strong>
                                </p>
                                <p>
                                    Raz&atilde;o Social:{" "}
                                    <strong>
                                        NATURA COSM&Eacute;TICOS S/A
                                    </strong>
                                </p>
                                <p>
                                    Endere&ccedil;o: Avenida Alexandre Colares,
                                    n&ordm; 1188
                                </p>
                                <p>Bairro: Parque Anhanguera</p>
                                <p>Munic&iacute;pio: S&atilde;o Paulo UF: SP</p>
                                <p>
                                    <span>CEP: 05.106-000</span>
                                </p>
                                <p>
                                    <span>
                                        CNPJ/ME n&ordm;: 71.673.990/0001-77
                                    </span>
                                </p>
                                <p>
                                    <span>
                                        Raz&atilde;o Social:{" "}
                                        <strong>
                                            THE WALT DISNEY COMPANY (BRASIL)
                                            LTDA
                                        </strong>
                                    </span>
                                </p>
                                <p>
                                    Endere&ccedil;o: Avenida das
                                    Na&ccedil;&otilde;es Unidas, n&ordm;
                                    12.551/12.559 &ndash; 10&ordm; e 11&ordm;
                                    andares &ndash; conjuntos 1.103 a 1.108
                                </p>
                                <p>Bairro: Brooklin Paulista</p>
                                <p>Munic&iacute;pio: S&atilde;o Paulo UF: SP</p>
                                <p>CEP: 04.578-903</p>
                                <p>CNPJ/ME n&ordm;: 73.042.962/0001-87</p>
                                <strong>
                                    <strong>2</strong>{" "}
                                    <strong>
                                        - MODALIDADE DA PROMO&Ccedil;&Atilde;O:
                                    </strong>
                                </strong>
                                <p>Assemelhado a Vale-Brinde</p>
                                <p>&nbsp;</p>
                                <strong>
                                    <strong>3</strong>{" "}
                                    <strong>
                                        - &Aacute;REA DE ABRANG&Ecirc;NCIA:
                                    </strong>
                                </strong>
                                <p>Todo o territ&oacute;rio nacional</p>
                                <strong>
                                    <strong>4</strong> -{" "}
                                    <strong>
                                        PER&Iacute;ODO DA
                                        PROMO&Ccedil;&Atilde;O:
                                    </strong>
                                </strong>
                                <p>06/09/2024 a 30/11/2024</p>
                                <p>&nbsp;</p>
                                <strong>
                                    <strong>5</strong>{" "}
                                    <strong>
                                        - PER&Iacute;ODO DE
                                        PARTICIPA&Ccedil;&Atilde;O:
                                    </strong>
                                </strong>
                                <p>06/09/2024 a 30/11/2024</p>
                                <strong>
                                    <strong>6</strong>{" "}
                                    <strong>
                                        - CRIT&Eacute;RIO DE
                                        PARTICIPA&Ccedil;&Atilde;O:
                                    </strong>
                                </strong>
                                <p>
                                    <strong>DEFINI&Ccedil;&Otilde;ES</strong>{" "}
                                    &ndash; Para os fins desta
                                    Promo&ccedil;&atilde;o, as express&otilde;es
                                    abaixo ter&atilde;o os seguintes
                                    significados:
                                </p>
                                <p>
                                    <strong>6.1.</strong>{" "}
                                    <strong>Promo&ccedil;&atilde;o</strong>:
                                    distribui&ccedil;&atilde;o gratuita de
                                    pr&ecirc;mio prevista neste regulamento
                                    (&ldquo;<strong>Regulamento</strong>
                                    &rdquo;), na modalidade assemelhada a
                                    vale-brinde, divulgada no <em>Hotsite </em>
                                    da Promo&ccedil;&atilde;o e outros canais, a
                                    exclusivo crit&eacute;rio das{" "}
                                    <strong>PROMOTORAS</strong>.
                                </p>
                                <p>
                                    <strong>6.1.1.</strong>{" "}
                                    <strong>
                                        Promo&ccedil;&atilde;o Hom&ocirc;nima:
                                    </strong>{" "}
                                    a Promo&ccedil;&atilde;o ocorrer&aacute;
                                    concomitantemente a outra
                                    promo&ccedil;&atilde;o realizada na
                                    modalidade assemelhada a sorteio, com
                                    regulamento e regras pr&oacute;prias,
                                    devendo cada Promo&ccedil;&atilde;o ser
                                    interpretada nos termos de seu regulamento
                                    espec&iacute;fico.
                                </p>
                                <p>
                                    <strong>6.2.</strong>{" "}
                                    <strong>Cliente Eleg&iacute;vel</strong>{" "}
                                    &ndash; pessoas f&iacute;sicas maiores de 18
                                    (dezoito) anos, inscritas e com o cadastro
                                    ativo no Cadastro de Pessoas F&iacute;sicas
                                    (CPF) do Minist&eacute;rio da Fazenda, que
                                    adquirirem produtos participantes da marca
                                    da Aderente, atrav&eacute;s da
                                    edi&ccedil;&atilde;o da revista Casa &amp;
                                    Estilo da{" "}
                                    <strong>MANDAT&Aacute;RIA&nbsp;</strong>
                                    (ciclo 15 e 16.2024), durante o
                                    Per&iacute;odo de Participa&ccedil;&atilde;o
                                    na Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.3.</strong>{" "}
                                    <strong>Cliente Participante</strong>{" "}
                                    &ndash; S&atilde;o os Clientes
                                    Eleg&iacute;veis que, ap&oacute;s o cadastro
                                    pessoal e do Produto Participante adquirido,
                                    cumprirem com todas as
                                    condi&ccedil;&otilde;es fixadas neste
                                    Regulamento.
                                </p>
                                <p>
                                    <strong>6.4.</strong>{" "}
                                    <strong>Produto Participante&nbsp;</strong>
                                    &ndash; Produtos da marca da{" "}
                                    <strong>Aderente</strong> disponibilizados
                                    atrav&eacute;s da revista Casa &amp; Estilo
                                    da <strong>MANDAT&Aacute;RIA</strong>
                                    ,descritos no ANEXO I deste Regulamento e
                                    dispon&iacute;veis no <em>Hotsite</em>&nbsp;
                                    <a href="http://www.avon.com.br">
                                        www.avon.com.br
                                    </a>
                                    .
                                </p>
                                <p>
                                    <strong>6.5.</strong>{" "}
                                    <strong>Chance</strong> &ndash;
                                    atribu&iacute;da ao Cliente Participante em
                                    raz&atilde;o do cadastro pessoal e do
                                    Produto Participante no <em>Hotsite</em> da
                                    Promo&ccedil;&atilde;o para acionamento de
                                    sua participa&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.6.</strong>{" "}
                                    <strong>Acionamento</strong> &ndash;
                                    &eacute; a efetiva&ccedil;&atilde;o da
                                    participa&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o, ap&oacute;s a
                                    realiza&ccedil;&atilde;o do cadastro pessoal
                                    e do Produto Participante adquirido,
                                    disponibilizadana &aacute;rea logada, em que
                                    o Cliente Participante ir&aacute; clicar
                                    para concorrer aos Brindes
                                    distribu&iacute;dos nessa
                                    promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.7.</strong>{" "}
                                    <em>
                                        <strong>Opt-In</strong>
                                    </em>{" "}
                                    &ndash; &eacute; o aceite e
                                    concord&acirc;ncia ao Regulamento desta
                                    promo&ccedil;&atilde;o e aos Termos de Uso
                                    da <strong>MANDAT&Aacute;RIA</strong>.
                                </p>
                                <p>
                                    <strong>6.8.</strong>{" "}
                                    <em>
                                        <strong>Hotsite</strong>
                                    </em>
                                    &ndash; &eacute; o hotsite{" "}
                                    <a href="http://www.vivamagiacasaestilo.com.br">
                                        www.vivamagiacasaestilo.com.br
                                    </a>
                                    .
                                </p>
                                <p>
                                    <strong>6.9.</strong>{" "}
                                    <strong>
                                        Per&iacute;odo da Promo&ccedil;&atilde;o
                                    </strong>{" "}
                                    &ndash; &eacute; o per&iacute;odo de
                                    participa&ccedil;&atilde;o total desta
                                    Promo&ccedil;&atilde;o, compreendido entre
                                    as 00h00 de <strong>06/09/2024</strong>{" "}
                                    &agrave;s 23h59 <strong>30/11/2024</strong>,
                                    contemplando o Per&iacute;odo de Compras e o
                                    Per&iacute;odo de Cadastro.&nbsp;
                                </p>
                                <p>
                                    <strong>6.10.</strong>{" "}
                                    <strong>
                                        Per&iacute;odo de Compras&nbsp;
                                    </strong>
                                    &ndash; &eacute; o per&iacute;odo em que os
                                    Clientes Participantes poder&atilde;o
                                    realizar suas compras, compreendido entre as
                                    00h00 de <strong>06/09/2024</strong>{" "}
                                    &agrave;s 23h59 <strong>11/11/2024</strong>.
                                </p>
                                <p>
                                    <strong>6.11.</strong>{" "}
                                    <strong>Per&iacute;odo de Cadastro</strong>{" "}
                                    &ndash; &eacute; o per&iacute;odo em que os
                                    Clientes Participantes poder&atilde;o
                                    realizar o cadastro(&ldquo;Cadastro&rdquo;)
                                    de seus dados pessoais e do Produto
                                    Participante e realizar o acionamento para
                                    concorrer aos Brindes desta
                                    Promo&ccedil;&atilde;o, compreendido entre
                                    as 00h00 de <strong>06/09/2024</strong>{" "}
                                    &agrave;s 23h59 <strong>30/11/2024</strong>,
                                    e poder&atilde;o acessar o <em>Hotsite</em>{" "}
                                    da Promo&ccedil;&atilde;o para realizar.
                                </p>
                                <p>
                                    <strong>6.12.</strong>{" "}
                                    <strong>Brinde</strong> &ndash; &eacute; o
                                    pr&ecirc;mio objeto desta
                                    Promo&ccedil;&atilde;o conforme definido e
                                    especificado no item 7 abaixo.
                                </p>
                                <p>
                                    <strong>
                                        COMO PARTICIPAR E CONCORRER AOS BRINDES
                                    </strong>
                                </p>
                                <p>
                                    Para participar desta
                                    promo&ccedil;&atilde;o, os Clientes
                                    Eleg&iacute;veis, dever&atilde;o, durante o
                                    Per&iacute;odo de Participa&ccedil;&atilde;o
                                    na A&ccedil;&atilde;o:&nbsp;
                                </p>
                                <p>
                                    <strong>(a)</strong> Realizar a compra de ao
                                    menos 1 (um) Produto Participante indicado
                                    no ANEXO I;
                                </p>
                                <p>
                                    <strong>(b)</strong>Acessar o{" "}
                                    <em>Hotsite</em> da Promo&ccedil;&atilde;o
                                    no per&iacute;odo de
                                    participa&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o;&nbsp;
                                </p>
                                <p>
                                    <strong>(c)</strong>Realizar o
                                    pr&eacute;-cadastro indicando para tanto o
                                    seu endere&ccedil;o de e-mail v&aacute;lido;
                                </p>
                                <p>
                                    <strong>(d)</strong>Ap&oacute;s receber o
                                    Produto Participante que realizou a
                                    compra,efetivar seu cadastro pessoal
                                    completo para acessar a
                                    Promo&ccedil;&atilde;o, atrav&eacute;s do
                                    preenchimento de formul&aacute;rio, com as
                                    seguintes informa&ccedil;&otilde;es: (i)
                                    Nome Completo; (ii) CPF/MF; (iii) Data de
                                    nascimento; (iv) Telefone celular; (v)
                                    Endere&ccedil;o completo. No mesmo momento,
                                    criar seu <em>login</em> e senha e realizar
                                    o <em>Opt-In</em>;&nbsp;
                                </p>
                                <p>
                                    <strong>(e)</strong>&nbsp;Cadastrar o
                                    c&oacute;digo de barra do Produto
                                    Participante adquirido; e&nbsp;
                                </p>
                                <p>
                                    <strong>(f)</strong> Realizar o
                                    Acionamentorelativo &agrave; Chance recebida
                                    para concorrer aos Brindes dessa
                                    promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.13.2.</strong> Fica desde
                                    j&aacute; estabelecido que a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    poder&aacute; solicitar aos interessados
                                    outras informa&ccedil;&otilde;es no momento
                                    do cadastramento, a seu exclusivo
                                    crit&eacute;rio, sendo estas de
                                    car&aacute;ter obrigat&oacute;rio, quando
                                    assim sinalizado, e fundamentado.
                                </p>
                                <p>
                                    <strong>6.13.3.</strong> Ap&oacute;s a
                                    leitura do Regulamento da
                                    Promo&ccedil;&atilde;o pelos interessados e
                                    manifesta&ccedil;&atilde;o do seu respectivo{" "}
                                    <em>Opt-In</em>, estes estar&atilde;o
                                    declarando e garantindo: (i) que s&atilde;o
                                    eleg&iacute;veis &agrave;
                                    participa&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o; (ii) possuem
                                    capacidade jur&iacute;dica para tanto, bem
                                    como que todas as informa&ccedil;&otilde;es
                                    prestadas s&atilde;o verdadeiras; (iii) que
                                    est&atilde;o de acordo com todos os termos
                                    do Regulamento da
                                    Promo&ccedil;&atilde;o;&nbsp;(iv) que
                                    est&atilde;o cientes de que os dados,
                                    fornecidos para participa&ccedil;&atilde;o
                                    na Promo&ccedil;&atilde;o, ser&atilde;o
                                    utilizados para os fins dispostos neste
                                    Regulamento, nos termos da Lei Geral de
                                    Prote&ccedil;&atilde;o de Dados; e (v)
                                    enviar informa&ccedil;&otilde;es da
                                    promo&ccedil;&atilde;o e/ou outras
                                    comunica&ccedil;&otilde;es relacionadas
                                    &agrave; promo&ccedil;&atilde;o
                                    atrav&eacute;s dos seguintes canais: e-mail,
                                    SMS e/ou, <em>WhatsApp</em>.
                                </p>
                                <p>
                                    <strong>6.13.4.</strong> O <em>e-mail</em>{" "}
                                    fornecido no momento do pr&eacute;-cadastro
                                    ser&aacute; utilizado para o
                                    compartilhamento das
                                    informa&ccedil;&otilde;es da
                                    Promo&ccedil;&atilde;o, inclusive, do{" "}
                                    <em>Hotsite</em> desta
                                    Promo&ccedil;&atilde;o, para que o Cliente
                                    Participante finalize o seu cadastro
                                    completo.
                                </p>
                                <p>
                                    <strong>6.13.5.</strong> O cadastro de
                                    Produto n&atilde;o participante ou em
                                    desconformidade com os crit&eacute;rios de
                                    participa&ccedil;&atilde;o, n&atilde;o
                                    ser&aacute; considerado para v&aacute;lido
                                    para fins de libera&ccedil;&atilde;o de
                                    Chance e para participar do Acionamento.
                                </p>
                                <p>
                                    <strong>6.13.6.</strong> O Cliente
                                    Eleg&iacute;vel somente ser&aacute;
                                    considerado &ldquo;Participante&rdquo; da
                                    Promo&ccedil;&atilde;o ap&oacute;s a
                                    realiza&ccedil;&atilde;o de <em>Opt-In</em>{" "}
                                    e cadastro pessoal completo e do Produto
                                    Participante adquirido, quando ent&atilde;o
                                    ser&aacute; direcionado para a p&aacute;gina
                                    do acionamento para concorrer aos
                                    Brindes.&nbsp;
                                </p>
                                <p>
                                    <strong>6.13.7.</strong> O Acionamento
                                    consiste em um clique no bot&atilde;o
                                    &ldquo;Participe&rdquo;, para visualizar se
                                    a sua participa&ccedil;&atilde;o est&aacute;
                                    ou n&atilde;o premiada com 01 (um) dos
                                    Brindes dispon&iacute;veis para esta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.13.7.1.</strong> Ser&atilde;o
                                    premiadas as participa&ccedil;&otilde;es
                                    conforme regras fixadas na Ordem Secreta,
                                    devidamente encaminhada &agrave; SPA/MF.
                                </p>
                                <p>
                                    <strong>6.13.7.2.</strong> Nas
                                    participa&ccedil;&otilde;es que estiverem
                                    potencialmente contempladas, conforme regras
                                    deste Regulamento, o Participante
                                    visualizar&aacute; a seguinte mensagem na
                                    tela: &ldquo;
                                    <strong>
                                        PARAB&Eacute;NS! Voc&ecirc; ganhou
                                        xxxxxx Clique aqui para finalizar as
                                        informa&ccedil;&otilde;es e receber o
                                        seu pr&ecirc;mio!
                                    </strong>
                                    &rdquo;. Ap&oacute;s este momento, o
                                    potencial contemplado ser&aacute;
                                    direcionado a uma tela no <em>Hotsite</em>{" "}
                                    onde ser&aacute; informado que &eacute;
                                    potencialmente contemplado e, com
                                    instru&ccedil;&otilde;es para preenchimento
                                    e envio dos documentos solicitados, bem como
                                    que com o detalhamento do pr&ecirc;mio e
                                    instru&ccedil;&otilde;es para
                                    recebimento/uso.&nbsp;
                                </p>
                                <p>
                                    <strong>6.13.7.2.1.</strong> Ser&atilde;o
                                    solicitados, para fins de
                                    valida&ccedil;&atilde;o de sua efetiva
                                    contempla&ccedil;&atilde;o, os seguintes
                                    dados do Participante potencialmente
                                    contemplado, que s&atilde;o de preenchimento
                                    obrigat&oacute;rio: (i) c&oacute;pia do RG e
                                    CPF; e (ii) comprovante de endere&ccedil;o.
                                </p>
                                <p>
                                    <strong>6.13.7.2.2.</strong> Ap&oacute;s o
                                    envio dos dados acima pelo Participante e
                                    conclus&atilde;o desta etapa, as{" "}
                                    <strong>PROMOTORAS</strong> validar&atilde;o
                                    a participa&ccedil;&atilde;o e
                                    confirmar&atilde;o se tal Participante
                                    &eacute; um dos contemplados da
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.13.7.2.3.</strong> Caso a
                                    contempla&ccedil;&atilde;o se confirme, a{" "}
                                    <strong>MANDAT&Aacute;RIA&nbsp;</strong>
                                    proceder&aacute; com as
                                    informa&ccedil;&otilde;es e
                                    orienta&ccedil;&otilde;es ao contemplado
                                    para o resgate e/ou envio do Brinde.
                                </p>
                                <p>
                                    <strong>6.13.7.2.4.</strong> Caso a
                                    contempla&ccedil;&atilde;o n&atilde;o se
                                    confirme, seja por infra&ccedil;&atilde;o ao
                                    regulamento ou pelo Participante n&atilde;o
                                    fazer jus ao recebimento, este ser&aacute;
                                    desclassificado tornando o Brinde
                                    imediatamente dispon&iacute;vel para nova
                                    distribui&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o, seguindo as regras
                                    previstas no item 6.12.3. 1. Caso a
                                    Promo&ccedil;&atilde;o esteja encerrada, o
                                    valor do pr&ecirc;mio ser&aacute; recolhido
                                    como renda &agrave; Uni&atilde;o.
                                </p>
                                <p>
                                    <strong>6.13.7.3.</strong> Por sua vez, as
                                    participa&ccedil;&otilde;es que n&atilde;o
                                    estiverem contempladas, o Participante
                                    visualizar&aacute; a seguinte mensagem na
                                    tela: &ldquo;
                                    <strong>
                                        N&atilde;o foi dessa vez!
                                        Mas&nbsp;continue aproveitando os
                                        produtos Casa &amp; Estilo e fique
                                        ligado em nossas
                                        promo&ccedil;&otilde;es!
                                    </strong>
                                    &rdquo;.&nbsp;
                                </p>
                                <p>
                                    <strong>6.13.7.4.</strong> Fica estabelecido
                                    que para esta Promo&ccedil;&atilde;o o
                                    Cliente Eleg&iacute;vel far&aacute; jus ao
                                    recebimento de 1 (uma) Chance a cada Produto
                                    Participante que ele adquirir e, somente
                                    poder&aacute; ser contemplado 1 (uma)
                                    &uacute;nica vez nesta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.13.7.4.1.</strong>{" "}
                                    Independentemente da quantidade de Produtos
                                    Participantes adquiridos, o Participante
                                    poder&aacute; fazer jus ao recebimento de,
                                    no m&aacute;ximo, 3 (tr&ecirc;s) Chances
                                    nesta Promo&ccedil;&atilde;o.&nbsp;
                                </p>
                                <p>
                                    <strong>6.13.8.</strong> Uma vez
                                    disponibilizada a Chance, tal chance
                                    poder&aacute; ser utilizada pelo
                                    Participante, a qualquer momento, desde que
                                    dentro do Per&iacute;odo desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.13.9.</strong> Toda e qualquer
                                    participa&ccedil;&atilde;o realizada que
                                    n&atilde;o preencher todas as
                                    condi&ccedil;&otilde;es estabelecidas neste
                                    Regulamento n&atilde;o ser&aacute;
                                    considerada v&aacute;lida para os efeitos
                                    desta Promo&ccedil;&atilde;o, podendo o
                                    participante ser desclassificado da
                                    Promo&ccedil;&atilde;o, a &uacute;nico e
                                    exclusivo crit&eacute;rio da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>.
                                </p>
                                <p>
                                    <strong>6.13.10.</strong> Caso o
                                    Participante n&atilde;o consiga acessar, se
                                    cadastrar e/ou participar, por quaisquer
                                    motivos, nos Canal de
                                    Participa&ccedil;&atilde;o da
                                    Promo&ccedil;&atilde;o, dever&aacute; entrar
                                    em contato com o SAC da
                                    Promo&ccedil;&atilde;o, por meio da Central
                                    SNAC no telefone 0800 708 28 66 de segunda a
                                    sexta das 08h &agrave;s 21h ou aos
                                    s&aacute;bados das 09h &agrave;s 15h, ou
                                    atrav&eacute;s do e-mail&nbsp;
                                    <a href="mailto:snac@natura.net">
                                        snac@natura.net
                                    </a>
                                    , para que o problema seja solucionado. A
                                    resposta visando auxiliar o Participante
                                    ser&aacute; realizada em at&eacute; 24
                                    (vinte e quatro) horas, contados da data de
                                    contato com o SAC. Os Participantes ficam
                                    cientes de que as liga&ccedil;&otilde;es
                                    realizadas para o esclarecimento de
                                    d&uacute;vidas poder&atilde;o ser gravadas
                                    pela <strong>MANDAT&Aacute;RIA</strong>,
                                    aseu &uacute;nico e exclusivo
                                    crit&eacute;rio.
                                </p>
                                <p>
                                    <strong>6.13.11.</strong> &Eacute; de
                                    inteira responsabilidade do Participante a
                                    indica&ccedil;&atilde;o correta dos dados
                                    cadastrais e informa&ccedil;&otilde;es
                                    solicitadas pela{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>.
                                </p>
                                <p>
                                    <strong>6.14.</strong>{" "}
                                    <strong>APURA&Ccedil;&Atilde;O</strong>
                                </p>
                                <p>
                                    <strong>6.14.1.</strong> Os brindes desta
                                    Promo&ccedil;&atilde;o ser&atilde;o emitidos
                                    em 01 (uma) s&eacute;rie e ter&atilde;o
                                    numera&ccedil;&atilde;o sequencial de 1 a
                                    10.000.
                                </p>
                                <p>
                                    <strong>6.14.2.</strong> A
                                    distribui&ccedil;&atilde;o dos brindes
                                    dispon&iacute;veis ser&aacute; realizada de
                                    forma aleat&oacute;ria, de&nbsp;06/09/2024 a
                                    30/11/2024, de acordo com a ordem de
                                    participa&ccedil;&otilde;es nos Acionamentos
                                    efetuados pelos Clientes Participantes,
                                    conforme ordem secreta de acionamentos
                                    contemplados (&ldquo;Ordem Secreta&rdquo;),
                                    relacionada ao Regulamento desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.14.3.</strong> A Ordem de
                                    Contempla&ccedil;&atilde;o est&aacute;
                                    definida na Ordem Secreta vinculada a este
                                    Regulamento.&nbsp;
                                </p>
                                <p>
                                    <strong>6.14.3.1.</strong> Em caso de
                                    eventuais desclassifica&ccedil;&otilde;es
                                    nesta Promo&ccedil;&atilde;o, os brindes
                                    tornar-se-&atilde;o imediatamente
                                    dispon&iacute;veis para nova
                                    distribui&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o. Neste caso,
                                    ser&aacute; observado o fluxo de
                                    distribui&ccedil;&atilde;o dos brindes
                                    previstos na Ordem Secreta, de acordo com o
                                    dia que tal Brinde for novamente
                                    disponibilizado. Caso n&atilde;o haja tempo
                                    h&aacute;bil para tal procedimento,
                                    ap&oacute;s a prescri&ccedil;&atilde;o, o
                                    valor do brinde n&atilde;o
                                    distribu&iacute;do ser&aacute; recolhido
                                    como renda &agrave; Uni&atilde;o.
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>6.14.4.</strong> Encerrado o
                                    &uacute;ltimo dia de
                                    realiza&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o, o valor dos brindes
                                    remanescentes, que eventualmente n&atilde;o
                                    tiverem sido distribu&iacute;dos nesta
                                    Promo&ccedil;&atilde;o, ser&aacute;
                                    recolhido ao Tesouro Nacional, como renda da
                                    Uni&atilde;o.
                                </p>
                                <p>
                                    <strong>6.14.5.</strong> Caso todos os
                                    brindes sejam distribu&iacute;dos antes do
                                    t&eacute;rmino do Per&iacute;odo
                                    Participa&ccedil;&atilde;o, esta
                                    Promo&ccedil;&atilde;o ser&aacute;
                                    encerrada, sendo esta condi&ccedil;&atilde;o
                                    informada aos interessados.
                                </p>
                                <p>
                                    <strong>6.14.6.</strong> A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> esclarece
                                    que, caso ao longo da Promo&ccedil;&atilde;o
                                    verifique que o fluxo de acionamentos
                                    n&atilde;o estiver proporcional a quantidade
                                    de participa&ccedil;&atilde;o na
                                    Promo&ccedil;&atilde;o, entrar&aacute; em
                                    contato com Secretaria de Pr&ecirc;mios e
                                    Apostas - SPA/MF para aditar a presente
                                    Promo&ccedil;&atilde;o para
                                    altera&ccedil;&atilde;o do fluxo de oferta
                                    de brindes de modo a viabilizar a
                                    distribui&ccedil;&atilde;o de todos os
                                    brindes na Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.14.7.</strong> A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> esclarece
                                    que a quantidade de
                                    Pr&ecirc;mios/N&uacute;meros de Elementos de
                                    Participa&ccedil;&atilde;o indicadas no item
                                    7 &eacute; uma mera estimativa e
                                    m&eacute;dia esperada de
                                    participa&ccedil;&otilde;es, sendo os
                                    Brindes efetivamente distribu&iacute;dos
                                    conforme a Ordem Secreta aprovada pela
                                    SPA&nbsp;
                                </p>
                                <strong>
                                    <strong>7</strong>{" "}
                                    <strong>BRINDES&nbsp;</strong>
                                </strong>
                                <p>
                                    Per&iacute;odo de
                                    Participa&ccedil;&atilde;o: 06/09/2024 00:00
                                    a 30/11/2024 23:59.&nbsp;
                                </p>
                                <p>
                                    Quantidade de Elementos de
                                    Participa&ccedil;&atilde;o da
                                    Apura&ccedil;&atilde;o: 1.000.000
                                </p>
                                <p>
                                    Quantidade de Pr&ecirc;mios/N&uacute;mero de
                                    Elementos de Participa&ccedil;&atilde;o: 1
                                    /100
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            VALOR R$
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            VALOR&nbsp;
                                                        </strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            TOTAL R$
                                                        </strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>600</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Organizador
                                                        Clutch Branca de Neve
                                                        (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 3,46</p>
                                                </td>
                                                <td>
                                                    <p>R$ 2.076,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>701</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Organizador
                                                        Clutch Tiana (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 3,46</p>
                                                </td>
                                                <td>
                                                    <p>R$ 2.425,46</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1000</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Garrafa C
                                                        Compart 360ml Fem
                                                        Children (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 4,73</p>
                                                </td>
                                                <td>
                                                    <p>R$ 4.730,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>500</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Organizador
                                                        Clutch Ariel (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 3,46</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.730,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>999</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Escova De Dente
                                                        Ventosa Mickey Children
                                                        (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 3,14</p>
                                                </td>
                                                <td>
                                                    <p>R$ 3.136,86</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>501</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Pote Com Tampa
                                                        Minnie (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$3,80</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.903,80,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>500</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Garrafa Foguete
                                                        Lightyear (Disney Pixar)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 4,55</p>
                                                </td>
                                                <td>
                                                    <p>R$ 2.275,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>500</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Toalha Avengers
                                                        (Marvel)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 15,68</p>
                                                </td>
                                                <td>
                                                    <p>R$ 7.840,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>501</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Garrafa 3d
                                                        Donald (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 6,43</p>
                                                </td>
                                                <td>
                                                    <p>R$ 3.221,43</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>500</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Copo Simples Fem
                                                        Disney Moana (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 2,14</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.070,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>999</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Copo Canudo
                                                        Quadrado Cinderela
                                                        (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 4,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 4.895,10</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>500</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Pote Quebra
                                                        Cabeca Spider Children
                                                        Pink (Marvel)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 3,38</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.690,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>500</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Caneca Disney
                                                        Ariel (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 3,20</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.600,00</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>999</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Kit 2 Potes Com
                                                        Orelhas Minnie (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 4,60</p>
                                                </td>
                                                <td>
                                                    <p>R$ 4.595,40</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>700</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Marmita Com
                                                        Travas Pequena Sereia
                                                        2023 (Disney)
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 5,50</p>
                                                </td>
                                                <td>
                                                    <p>R$ 3.850,00</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <strong>
                                    <strong>8</strong>{" "}
                                    <strong>
                                        PREMIA&Ccedil;&Atilde;O TOTAL:&nbsp;
                                    </strong>
                                </strong>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade Total de
                                                            Pr&ecirc;mios
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Valor total da
                                                            Promo&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>10.000</p>
                                                </td>
                                                <td>
                                                    <p>R$ 47.039,05</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <strong>
                                    <strong>9</strong>{" "}
                                    <strong>
                                        CRIT&Eacute;RIOS DE
                                        DESCLASSIFICA&Ccedil;&Atilde;O E
                                        ISEN&Ccedil;&Atilde;O DE
                                        RESPONSABILIDADE:
                                    </strong>
                                </strong>
                                <p>
                                    <strong>9.1</strong> Todos os produtos
                                    cadastrados, conforme cadastro do
                                    c&oacute;digo de barras realizado pelos
                                    Participantes, ser&atilde;o validados pela{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> a
                                    qualquer tempo at&eacute; o momento da
                                    apura&ccedil;&atilde;o do resultado para
                                    verificar: <strong>(i)</strong> se o
                                    interessado &eacute; eleg&iacute;vel
                                    &agrave; participa&ccedil;&atilde;o;{" "}
                                    <strong>(ii)</strong> se os produtos
                                    cadastrados s&atilde;o regulares e respeitam
                                    as regras deste regulamento; e{" "}
                                    <strong>(iii)</strong> se as
                                    informa&ccedil;&otilde;es cadastradas
                                    s&atilde;o corretas e verdadeiras.
                                </p>
                                <p>
                                    <strong>9.2</strong> Caso seja constatado
                                    que o Participante realizou o cadastro:{" "}
                                    <strong>(i)</strong> de forma incorreta,{" "}
                                    <strong>(ii)</strong> de produtos que sejam
                                    considerados inv&aacute;lidos ou seja
                                    constatado que o presente regulamento
                                    n&atilde;o foi integralmente respeitado, o
                                    participante ser&aacute; desclassificado e
                                    n&atilde;o far&aacute; jus ao recebimento
                                    dos pr&ecirc;mios desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>9.3</strong> Os Participantes
                                    ser&atilde;o exclu&iacute;dos e
                                    desclassificados automaticamente da
                                    Promo&ccedil;&atilde;o, a qualquer momento,
                                    em caso de ind&iacute;cio de fraude, a
                                    crit&eacute;rio exclusivo da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>,
                                    tentativa ou fraude comprovada,
                                    manipula&ccedil;&atilde;o de resultados ou
                                    n&atilde;o preenchimento dos requisitos e
                                    das disposi&ccedil;&otilde;es previstas
                                    neste Regulamento e/ou em decorr&ecirc;ncia
                                    de informa&ccedil;&otilde;es incorretas ou
                                    equivocadas, de acordo com as regras deste
                                    Regulamento, podendo ainda responder por
                                    crime de falsidade ideol&oacute;gica ou
                                    documental.&nbsp;
                                </p>
                                <p>
                                    <strong>9.4</strong> Ser&atilde;o
                                    considerados nulos e ser&atilde;o
                                    imediatamente desclassificados e impedidos
                                    de concorrer/receber aos Brindes, os
                                    cadastros e atividades que possam ser
                                    caracterizadas como infra&ccedil;&atilde;o,
                                    fraude, tentativa de fraude, abuso ou,
                                    ainda, para a qual tenham sido utilizados
                                    quaisquer meios tecnol&oacute;gicos, sejam
                                    eles eletr&ocirc;nicos, inform&aacute;ticos,
                                    digital, rob&oacute;tico, repetitivo,
                                    autom&aacute;tico, mec&acirc;nico e/ou
                                    an&aacute;logo com intuito de
                                    reprodu&ccedil;&atilde;o autom&aacute;tica
                                    e/ou repetitiva de cadastros,
                                    id&ecirc;nticos ou n&atilde;o, o que
                                    importar&aacute; na nulidade tamb&eacute;m
                                    de todos os cadastros efetuados pelo
                                    Participante que tenha se utilizado de um
                                    dos referidos meios ou com um dos referidos
                                    fins, ainda que nem todos os cadastros
                                    tenham resultado do uso de tais meios e/ ou
                                    sido realizadas com tal finalidade.&nbsp;
                                </p>
                                <p>
                                    <strong>9.5</strong> Nos casos de
                                    ind&iacute;cio de fraude, tentativa ou
                                    fraude comprovada na
                                    participa&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o,
                                    manipula&ccedil;&atilde;o de resultados, ou
                                    n&atilde;o preenchimento das
                                    disposi&ccedil;&otilde;es previstas neste
                                    Regulamento, o Participante contemplado
                                    ser&aacute; automaticamente desclassificado
                                    e n&atilde;o ter&aacute; direito ao
                                    recebimento do Pr&ecirc;mio, sendo
                                    devidamente informados sobre tal
                                    desclassifica&ccedil;&atilde;o, tornando-se
                                    o Brinde imediatamente dispon&iacute;vel
                                    para nova distribui&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o conforme regra de
                                    distribui&ccedil;&atilde;o dos Brindes desta
                                    Promo&ccedil;&atilde;o. Caso a
                                    Promo&ccedil;&atilde;o esteja encerrada, o
                                    valor do Brinde ser&aacute; recolhido como
                                    renda &agrave; Uni&atilde;o, no prazo de 10
                                    (dez) dias da prescri&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>9.6</strong> Caso a
                                    constata&ccedil;&atilde;o do ind&iacute;cio
                                    tentativa ou fraude comprovada ou n&atilde;o
                                    preenchimento dos requisitos previstos neste
                                    Regulamento seja identificada no momento da
                                    apura&ccedil;&atilde;o, o Participante
                                    ser&aacute; desclassificado, tornando-se o
                                    Brinde imediatamente dispon&iacute;vel para
                                    nova distribui&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o conforme regra de
                                    distribui&ccedil;&atilde;o dos Brindes desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>9.7</strong> Considerando as
                                    caracter&iacute;sticas inerentes ao ambiente
                                    da Internet, a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    n&atilde;o se responsabiliza por
                                    interrup&ccedil;&otilde;es ou
                                    suspens&otilde;es de conex&atilde;o
                                    ocasionadas por caso fortuito ou de
                                    for&ccedil;a maior, e/ou a&ccedil;&otilde;es
                                    de terceiros, dos quais n&atilde;o possam
                                    exercer qualquer controle, bem como por
                                    cadastros perdidos, atrasados, incompletos,
                                    inv&aacute;lidos, extraviados ou
                                    corrompidos, os quais ser&atilde;o
                                    desconsiderados.
                                </p>
                                <p>
                                    <strong>9.8</strong> O Participante
                                    contemplado isenta a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> de
                                    qualquer responsabilidade e/ou dano
                                    patrimonial e moral, ou incidentes causados,
                                    decorrente do uso indevido do pr&ecirc;mio,
                                    ou de quaisquer danos verificados, por
                                    motivo de caso fortuito ou for&ccedil;a
                                    maior, que possam impossibilitar o ganhador
                                    de usufruir seu Brinde.
                                </p>
                                <p>
                                    <strong>9.9</strong> O Participante &eacute;
                                    o &uacute;nico respons&aacute;vel pelas
                                    informa&ccedil;&otilde;es e dados pessoais
                                    informados quando da
                                    realiza&ccedil;&atilde;o do cadastro pessoal
                                    junto &agrave;{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>,
                                    responsabilidade que abrange, tamb&eacute;m,
                                    a precis&atilde;o e a veracidade de tais
                                    informa&ccedil;&otilde;es e dados. Para fins
                                    de participa&ccedil;&atilde;o e entrega da
                                    premia&ccedil;&atilde;o ser&atilde;o
                                    considerados apenas os dados constantes no
                                    cadastro realizado junto &agrave;{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>. A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    est&aacute; isenta de quaisquer
                                    responsabilidades em caso de
                                    incorre&ccedil;&atilde;o,
                                    desatualiza&ccedil;&atilde;o ou n&atilde;o
                                    veracidade das informa&ccedil;&otilde;es e
                                    dados informados pelo Participante, que
                                    poder&aacute; ser desclassificado.
                                </p>
                                <p>
                                    <strong>9.10</strong>A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> bem como
                                    quaisquer das pessoas e/ou empresas por elas
                                    contratadas e que estejam, direta ou
                                    indiretamente, envolvidas em qualquer
                                    aspecto da Promo&ccedil;&atilde;o n&atilde;o
                                    se responsabilizar&atilde;o por eventuais
                                    preju&iacute;zos que os Participantes venham
                                    a sofrer em decorr&ecirc;ncia da
                                    Promo&ccedil;&atilde;o, oriundos de fatos
                                    alheios &agrave;{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> e/ou das
                                    pessoas e/ou empresas envolvidas na
                                    Promo&ccedil;&atilde;o. A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    n&atilde;o ser&aacute; responsabilizada por
                                    inscri&ccedil;&otilde;es e/ou cadastros
                                    n&atilde;o efetivados por problemas na
                                    transmiss&atilde;o de dados no servidor do
                                    Participante, tais como: problemas de acesso
                                    &agrave; rede de Internet,
                                    interven&ccedil;&atilde;o de hackers,
                                    v&iacute;rus, manuten&ccedil;&atilde;o,
                                    queda de energia, falhas de software ou
                                    hardware, problemas operacionais com as
                                    empresas de telefonia que possam, direta ou
                                    indiretamente, afetar o acesso &agrave;
                                    Internet e, consequentemente, a
                                    participa&ccedil;&atilde;o na presente
                                    Promo&ccedil;&atilde;o, bem como por casos
                                    fortuitos ou de for&ccedil;a maior, em
                                    provedores de acessos dos Participantes ou
                                    ainda por falta de energia el&eacute;trica.
                                </p>
                                <p>
                                    <strong>9.11.1.</strong>A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> e/ou as
                                    pessoas e empresas envolvidas na
                                    Promo&ccedil;&atilde;o n&atilde;o
                                    ser&atilde;o respons&aacute;veis, ainda, no
                                    caso de vazamento/dissemina&ccedil;&atilde;o
                                    das imagens, nome, voz, textos e
                                    v&iacute;deos captados por terceiros, bem
                                    como fica isenta de responsabilidade pela
                                    dissemina&ccedil;&atilde;o dos
                                    v&iacute;deos, imagens, textos e todo e
                                    qualquer conte&uacute;do em qualquer canal
                                    de internet e m&iacute;dia digital e/ou
                                    social, tais como, mas n&atilde;o se
                                    limitando a <em>Youtube</em>,{" "}
                                    <em>Facebook</em>, <em>Instagram</em>, entre
                                    outros, que forem feitos por terceiros
                                    n&atilde;o autorizados durante ou
                                    ap&oacute;s o t&eacute;rmino da
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>9.11</strong> A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> e/ou as
                                    pessoas e empresas envolvidas na
                                    Promo&ccedil;&atilde;o
                                    tamb&eacute;mn&atilde;o se
                                    responsabilizar&atilde;o por eventuais e/ou
                                    quaisquer preju&iacute;zos diretos ou
                                    indiretos que os consumidores participantes
                                    possam ter, oriundos da
                                    participa&ccedil;&atilde;o na
                                    Promo&ccedil;&atilde;o, da
                                    aceita&ccedil;&atilde;o do pr&ecirc;mio, ou
                                    ainda de situa&ccedil;&otilde;es que estejam
                                    fora do controle da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> e/ou das
                                    pessoas e empresas envolvidas na
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>9.12</strong> Caso em raz&atilde;o
                                    do fornecimento de dados incompletos,
                                    incorretos ou desatualizados, a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    ficar&aacute; impossibilitada de contatar o
                                    Participante Potencial Contemplado, esta
                                    proceder&aacute; &agrave; imediata
                                    desclassifica&ccedil;&atilde;o do mesmo por
                                    inobserv&acirc;ncia das regras de
                                    participa&ccedil;&atilde;o estabelecidas
                                    neste Regulamento.
                                </p>
                                <p>
                                    <strong>9.13</strong> Fica, desde j&aacute;,
                                    estabelecido, que a partir do momento da
                                    entrega do Brinde, a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    n&atilde;o se responsabilizar&aacute; pela
                                    n&atilde;o utiliza&ccedil;&atilde;o do
                                    pr&ecirc;mio por parte do contemplado seja
                                    qual for o motivo.
                                </p>
                                <p>
                                    <strong>9.14</strong> A responsabilidade da{" "}
                                    <strong>MANDAT&Aacute;RIA&nbsp;</strong>com
                                    os contemplados encerra-se no momento da
                                    entrega dos pr&ecirc;mios, os quais
                                    destinam-se aos contemplados e ser&atilde;o
                                    entregues em nome deles, livres e
                                    desembara&ccedil;ados de quaisquer
                                    &ocirc;nus, sendo vedada sua
                                    transfer&ecirc;ncia.&nbsp;
                                </p>
                                <p>
                                    <strong>9.15</strong> Caso algum contemplado
                                    n&atilde;o seja localizado por meio dos
                                    dados por ele cadastrados nesta
                                    Promo&ccedil;&atilde;o, em raz&atilde;o de
                                    estarem tais dados incorretos, imprecisos,
                                    desatualizados ou equivocados, sua
                                    participa&ccedil;&atilde;o ser&aacute;
                                    desconsiderada a qualquer tempo, a
                                    crit&eacute;rio da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>,
                                    tornando-se o Brinde imediatamente
                                    dispon&iacute;vel para nova
                                    distribui&ccedil;&atilde;o.
                                </p>
                                <strong>
                                    <strong>10</strong>
                                    <strong>
                                        -&nbsp;ENTREGA DOS PR&Ecirc;MIOS:
                                    </strong>
                                </strong>
                                <p>
                                    <strong>10.1</strong> Os pr&ecirc;mios
                                    s&atilde;o pessoais e intransfer&iacute;veis
                                    e&nbsp;ser&atilde;o entregues em nome dos
                                    Participantes contemplados em at&eacute; 30
                                    (trinta) dias, no endere&ccedil;o de
                                    cadastro informado pelo Contemplado, no caso
                                    de pr&ecirc;mio f&iacute;sico, j&aacute; no
                                    caso de pr&ecirc;mio digital, este
                                    ser&aacute; entregue atrav&eacute;s do
                                    e-mail informado no momento do cadastro
                                    pessoal, contados da data da efetiva
                                    contempla&ccedil;&atilde;o, sem qualquer
                                    &ocirc;nus ao contemplado.
                                </p>
                                <p>
                                    <strong>10.2</strong> Para o recebimento do
                                    pr&ecirc;mio o Contemplado, dever&aacute;
                                    apresentar, se solicitado:{" "}
                                    <strong>(i)</strong> c&oacute;pia
                                    leg&iacute;vel do seu RG;{" "}
                                    <strong>(ii)</strong> c&oacute;pia
                                    leg&iacute;vel do CPF/MF; e{" "}
                                    <strong>(iii)</strong> c&oacute;pia
                                    leg&iacute;vel de seu comprovante de
                                    resid&ecirc;ncia, com data de no
                                    m&aacute;ximo 180 (cento e oitenta) dias
                                    anteriores &agrave; data da
                                    divulga&ccedil;&atilde;o dos resultados, em
                                    seu nome ou em nome de algum parente ou
                                    c&ocirc;njuge, acompanhado de outros
                                    documentos que comprovem seu grau de
                                    parentesco ou rela&ccedil;&atilde;o,
                                    consoante as instru&ccedil;&otilde;es que
                                    ser&atilde;o fornecidas pela{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> por meio
                                    de correspond&ecirc;ncia eletr&ocirc;nica
                                    e/ou via telefone. Neste ato, dever&aacute;
                                    ainda, se solicitado,&nbsp;assinar o recibo
                                    de entrega de pr&ecirc;mio que, de posse da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>,
                                    constituir&aacute; prova de entrega do
                                    pr&ecirc;mio.
                                </p>
                                <p>
                                    <strong>10.3</strong> A
                                    documenta&ccedil;&atilde;o listada no item
                                    10.2, dever&aacute; ser apresentada no prazo
                                    de 3 (tr&ecirc;s) dias &uacute;teis,
                                    contados do processo de
                                    valida&ccedil;&atilde;o no <em>Hotsite</em>{" "}
                                    da Promo&ccedil;&atilde;o, sob pena de
                                    desclassifica&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>10.3.1.</strong>A n&atilde;o
                                    apresenta&ccedil;&atilde;o dos documentos
                                    aqui indicados ou a
                                    apresenta&ccedil;&atilde;o irregular, ou de
                                    forma divergente de seu cadastro, no prazo
                                    de 3 (tr&ecirc;s) dias &uacute;teis,
                                    contados da solicita&ccedil;&atilde;o (se
                                    realizada), ensejar&aacute; a
                                    desclassifica&ccedil;&atilde;o do
                                    Participante desta Promo&ccedil;&atilde;o,
                                    sendo invalidados igualmente a Chance
                                    distribu&iacute;da, e o Brinde
                                    correspondente ser&aacute; destinado a nova
                                    distribui&ccedil;&atilde;o, na
                                    pr&oacute;xima apura&ccedil;&atilde;o,
                                    passando o novo participante potencialmente
                                    contemplado pelos procedimentos descritos no
                                    item 10.2.
                                </p>
                                <p>
                                    <strong>10.3.2.</strong>A{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    poder&aacute; recusar c&oacute;pias ou
                                    arquivos que n&atilde;o sejam capazes de
                                    demonstrar a veracidade, legalidade e
                                    conformidade dos documentos, n&atilde;o
                                    cabendo qualquer direito de
                                    reclama&ccedil;&atilde;o ou
                                    reivindica&ccedil;&atilde;o, salvo se
                                    efetivamente for apresentada
                                    documenta&ccedil;&atilde;o completa e
                                    leg&iacute;tima, conforme exigido neste
                                    Regulamento. Neste caso, a
                                    comprova&ccedil;&atilde;o dever&aacute; ser
                                    realizada no prazo de 3 (tr&ecirc;s) dias
                                    &uacute;teis, contados do efetivo contato
                                    das <strong>PROMOTORAS.</strong>
                                </p>
                                <strong>
                                    <strong>11</strong>
                                    <strong>
                                        -&nbsp;DISPOSI&Ccedil;&Otilde;ES GERAIS
                                    </strong>
                                </strong>
                                <p>
                                    <strong>11.1</strong>
                                    <strong>TRATAMENTO DE DADOS</strong>
                                </p>
                                <p>
                                    <strong>11.1.1.</strong> Ao realizar o
                                    Aceite, o Cliente Eleg&iacute;vel
                                    estar&aacute; automaticamente sujeito aos
                                    Termos de Uso e Pol&iacute;tica de
                                    Privacidade da Natura (dispon&iacute;vel
                                    em&nbsp;
                                    <a href="https://www.natura.com.br/politica-de-privacidade">
                                        https://www.natura.com.br/politica-de-privacidade
                                    </a>
                                    ), estando ciente, inclusive, que as{" "}
                                    <strong>PROMOTORAS</strong>, por si ou por
                                    meio de terceiros contratados, ter&atilde;o
                                    acesso aos dados cadastrados e dados
                                    relativos aos pedidos, para identificar se
                                    este est&aacute; regular e apto a concorrer
                                    aos pr&ecirc;mios ofertados nesta
                                    Promo&ccedil;&atilde;o.&nbsp;
                                </p>
                                <p>
                                    <strong>11.1.2.</strong> Realizado o Aceite,
                                    o Cliente Eleg&iacute;vel concorda com o
                                    compartilhamento de seus dados, inclusive
                                    pessoais, entre as{" "}
                                    <strong>PROMOTORAS</strong> e outras
                                    empresas envolvidas na
                                    realiza&ccedil;&atilde;o da presente
                                    Promo&ccedil;&atilde;o, para as finalidades
                                    de tratamento previstas neste Regulamento e,
                                    principalmente, para fins de
                                    operacionaliza&ccedil;&atilde;o e
                                    execu&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.1.3.</strong> Caso o Cliente
                                    Eleg&iacute;vel decida n&atilde;o mais
                                    participar desta Promo&ccedil;&atilde;o,
                                    dever&aacute; acionar o Canal de Atendimento
                                    Natura (&ldquo;SNAC&rdquo;), atrav&eacute;s
                                    do telefone 0800 762 88 72, ou pelo
                                    e-mail:&nbsp;
                                    <a href="mailto:snac@natura.net">
                                        snac@natura.net
                                    </a>{" "}
                                    e abrir um chamado sobre a sua
                                    inten&ccedil;&atilde;o de &ldquo;n&atilde;o
                                    participa&ccedil;&atilde;o&rdquo; (
                                    <em>opt-out</em>).
                                </p>
                                <p>
                                    <strong>11.1.4.</strong> O Cliente
                                    Eleg&iacute;vel est&aacute; ciente que, ao
                                    realizar o Cadastro e Aceite, alguns dos
                                    dados pessoais informados ser&atilde;o
                                    tratados pelas <strong>PROMOTORAS</strong>,
                                    incluindo o compartilhamento com parceiros
                                    envolvidos no desenvolvimento e
                                    execu&ccedil;&atilde;o da
                                    Promo&ccedil;&atilde;o, para as finalidades
                                    de operacionaliza&ccedil;&atilde;o,
                                    execu&ccedil;&atilde;o,
                                    divulga&ccedil;&atilde;o da pr&oacute;pria
                                    Promo&ccedil;&atilde;o e do Contemplado,
                                    entrega do pr&ecirc;mio, procedimentos de
                                    auditoria e, ainda, de
                                    fiscaliza&ccedil;&atilde;o, nos termos do
                                    item 15.12, e presta&ccedil;&atilde;o de
                                    contas junto &agrave; Secretaria de
                                    Pr&ecirc;mios e Apostas - SPA/MF, nos
                                    limites da legisla&ccedil;&atilde;o
                                    aplic&aacute;vel.
                                </p>
                                <p>
                                    <strong>11.1.5.</strong> As{" "}
                                    <strong>PROMOTORAS</strong> expressamente
                                    declaram que os dados obtidos em
                                    raz&atilde;o desta Promo&ccedil;&atilde;o
                                    n&atilde;o ser&atilde;o de qualquer forma
                                    comercializados ou cedidos, neste caso com
                                    finalidades distintas do aqui indicado, a
                                    terceiros, ainda que a t&iacute;tulo
                                    gratuito.
                                </p>
                                <p>
                                    <strong>11.1.6.</strong> O Cliente
                                    Eleg&iacute;vel que n&atilde;o concordar com
                                    o tratamento dos seus dados pessoais para as
                                    finalidades descritas neste Regulamento ou
                                    optarem por revogar a sua
                                    concord&acirc;ncia, n&atilde;o dar seu
                                    aceite ou dever&atilde;o deixar de
                                    participar desta Promo&ccedil;&atilde;o,
                                    conforme previsto no item 11.1.3.
                                </p>
                                <p>
                                    <strong>11.1.7.</strong> Caso o Cliente
                                    Eleg&iacute;vel possua alguma
                                    reclama&ccedil;&atilde;o, pedido ou outra
                                    solicita&ccedil;&atilde;o relacionada ao
                                    tratamento de seus dados pessoais, a
                                    qualquer momento poder&aacute; entrar em
                                    contato com a Natura, por meio da plataforma
                                    acess&iacute;vel por meio do seguinte&nbsp;
                                    <a href="https://privacyportal-br.onetrust.com/webform/00181faa-85e7-4785-848b-f12d02b3f614/6f7e1250-be9f-4b2c-8610-98afc44fb2c0">
                                        link
                                    </a>
                                    .
                                </p>
                                <p>
                                    <strong>11.2</strong> N&atilde;o podem
                                    participar esta Promo&ccedil;&atilde;o:
                                </p>
                                <p>
                                    <strong>a)</strong> Pessoas
                                    Jur&iacute;dicas;
                                </p>
                                <p>
                                    <strong>b)</strong> Pessoas f&iacute;sicas
                                    que n&atilde;o atenderem aos requisitos
                                    exigidos neste Regulamento;
                                </p>
                                <p>
                                    <strong>c)</strong> Os membros do corpo
                                    diretivo, conforme respectivos documentos
                                    societ&aacute;rios, e todos os empregados e
                                    colaboradores da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>;
                                </p>
                                <p>
                                    <strong>d)</strong> Todos os empregados e
                                    colaboradores da Ag&ecirc;ncia The Group e
                                    da assessoria jur&iacute;dica Focaccia,
                                    Amaral e Lamonica Sociedade de Advogados;
                                </p>
                                <p>
                                    <strong>e)</strong> Qualquer
                                    indiv&iacute;duo que n&atilde;o goze de sua
                                    capacidade legal.
                                </p>
                                <p>
                                    <strong>11.2.1.</strong> A
                                    verifica&ccedil;&atilde;o do disposto no
                                    item acima &eacute; de responsabilidade da{" "}
                                    <strong>PROMOTORA</strong>. Caso, por
                                    qualquer motivo, a despeito dos
                                    esfor&ccedil;os das{" "}
                                    <strong>PROMOTORA</strong> para impedir a
                                    participa&ccedil;&atilde;o de pessoas
                                    impedidas de participar da
                                    Promo&ccedil;&atilde;o, verifique-se que
                                    algum dos contemplados estava impedido de
                                    participar desta Promo&ccedil;&atilde;o ou
                                    ainda, verifique-se que o potencial
                                    contemplado n&atilde;o cumpriu com os
                                    requisitos e regras previstos neste
                                    Regulamento, este ser&aacute;
                                    desclassificado, sendo devidamente informado
                                    sobre tal desclassifica&ccedil;&atilde;o,
                                    perdendo direito ao pr&ecirc;mio
                                    eventualmente distribu&iacute;do, o qual
                                    tornar-se-&aacute; imediatamente
                                    dispon&iacute;vel para nova
                                    distribui&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o, seguindo as regras
                                    de distribui&ccedil;&atilde;o dos brindes
                                    desta Promo&ccedil;&atilde;o. Caso a
                                    Promo&ccedil;&atilde;o esteja encerrada, o
                                    valor do pr&ecirc;mio ser&aacute; recolhido
                                    como renda &agrave; Uni&atilde;o.
                                </p>
                                <p>
                                    <strong>11.3</strong> Uma vez entregue o
                                    pr&ecirc;mio, o Participante dever&aacute;
                                    observar as regras de sua
                                    utiliza&ccedil;&atilde;o, as quais
                                    s&atilde;o estabelecidas por seu fornecedor,
                                    sem qualquer inger&ecirc;ncia da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>.&nbsp;
                                </p>
                                <p>
                                    <strong>11.4</strong> Caso o Contemplado
                                    n&atilde;o esteja presente para recebimento
                                    do pr&ecirc;mio, dever&aacute; outorgar
                                    poderes a procuradores, constitu&iacute;dos
                                    por procura&ccedil;&atilde;o p&uacute;blica
                                    ou particular com reconhecimento de firma em
                                    Cart&oacute;rio, autorizando-os a receber o
                                    pr&ecirc;mio e a dar recibo da
                                    quita&ccedil;&atilde;o da
                                    obriga&ccedil;&atilde;o da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> e entrega
                                    de pr&ecirc;mio.
                                </p>
                                <p>
                                    <strong>11.5</strong> A
                                    distribui&ccedil;&atilde;o dos brindes
                                    &eacute; gratuita, sem &ocirc;nus aos
                                    Contemplados. Os pr&ecirc;mios n&atilde;o
                                    poder&atilde;o ser distribu&iacute;dos ou
                                    convertidos, total ou parcialmente em
                                    dinheiro de acordo com o art. 15, &sect;
                                    5&deg; do Decreto n&deg;70.951/72. Ademais,
                                    o pr&ecirc;mio n&atilde;o poder&aacute; ser
                                    trocado, alterado, complementado ou
                                    substitu&iacute;do por outro pr&ecirc;mio.
                                </p>
                                <p>
                                    <strong>11.6</strong> O recibo da
                                    quita&ccedil;&atilde;o da
                                    obriga&ccedil;&atilde;o das{" "}
                                    <strong>PROMOTORAS</strong> e entrega de
                                    pr&ecirc;mio constituir&aacute; prova de
                                    entrega do pr&ecirc;mio e ser&aacute;
                                    mantido sob guarda da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong> pelo
                                    prazo de 05 (cinco) anos, contados do
                                    t&eacute;rmino da Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.7</strong> A simples
                                    participa&ccedil;&atilde;o na presente
                                    Promo&ccedil;&atilde;o com o aceite aos
                                    termos deste Regulamento configura a
                                    aceita&ccedil;&atilde;o de todas as
                                    disposi&ccedil;&otilde;es do presente do
                                    Regulamento e configura
                                    autoriza&ccedil;&atilde;o para o recebimento
                                    de informa&ccedil;&otilde;es e
                                    comunica&ccedil;&otilde;es a respeito desta
                                    Promo&ccedil;&atilde;o, n&atilde;o cabendo
                                    ao Participante contemplado e/ou seus
                                    respons&aacute;veis/representantes legais
                                    discutir ou redefinir as
                                    condi&ccedil;&otilde;es e premissas da
                                    Promo&ccedil;&atilde;o ou do pr&ecirc;mio.
                                </p>
                                <p>
                                    <strong>11.8</strong> Caso o Contemplado
                                    n&atilde;o tenha interesse em receber a sua
                                    premia&ccedil;&atilde;o, este dever&aacute;
                                    renunciar ao seu pr&ecirc;mio, devendo,
                                    neste caso, formalizar tal ren&uacute;ncia
                                    por meio da assinatura de documento formal,
                                    disponibilizado pela{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>.
                                </p>
                                <p>
                                    <strong>11.8.1.</strong>Em caso de
                                    ren&uacute;ncia formal do Cliente
                                    Participante inicialmente contemplado,
                                    poder&aacute; a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>, caso
                                    haja tempo h&aacute;bil, distribuir tal
                                    pr&ecirc;mio em nova apura&ccedil;&atilde;o
                                    para identificar novo contemplado para o
                                    recebimento do Brinde remanescente.
                                </p>
                                <p>
                                    <strong>11.9</strong> No Hotsite da
                                    Promo&ccedil;&atilde;o os interessados
                                    dever&atilde;o: (i) consultar este
                                    Regulamento e o certificado de
                                    autoriza&ccedil;&atilde;o emitido pela SPA;
                                    e (ii) esclarecer d&uacute;vidas e obter
                                    informa&ccedil;&otilde;es sobre esta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.10</strong> A
                                    Promo&ccedil;&atilde;o&nbsp;ser&aacute;
                                    divulgada pela
                                    <strong> MANDAT&Aacute;RIA</strong> por meio
                                    dos Canais Digitais, bem como entre outros
                                    canais a exclusivo crit&eacute;rio destas,
                                    tais como canais propriet&aacute;rios da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>, canais
                                    segmentados (<em>SMS</em>, <em>Push</em>,{" "}
                                    <em>E-mail Marketing, RCS</em> e{" "}
                                    <em>Smartmessage</em>), Digital (redes
                                    sociais, sites das Promotoras, dentro
                                    outros), e demais meios e m&iacute;dias de
                                    interesse da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>.
                                </p>
                                <p>
                                    <strong>11.11</strong> O regulamento da
                                    Promo&ccedil;&atilde;o ser&aacute;
                                    disponibilizado no <em>Hotsite</em> da
                                    Promo&ccedil;&atilde;o, e a
                                    participa&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o caracteriza a
                                    aceita&ccedil;&atilde;o total e
                                    irrevog&aacute;vel pelo Participante de
                                    todos os termos e condi&ccedil;&otilde;es
                                    descritos neste Regulamento.
                                </p>
                                <p>
                                    <strong>11.12</strong> Caso o contemplado se
                                    encontre relativamente incapaz, nos termos
                                    do inciso II, III e IV do artigo 4&ordm; do
                                    C&oacute;digo Civil (Lei 10.406/2002),
                                    dever&aacute; ser representado por seus
                                    respons&aacute;veis legais para efetivo
                                    recebimento do pr&ecirc;mio, o qual,
                                    n&atilde;o obstante, ser&aacute; emitido em
                                    nome do relativamente incapaz, conforme o
                                    caso.
                                </p>
                                <p>
                                    <strong>11.13</strong> Os Participantes
                                    contemplados e/ou seus
                                    respons&aacute;veis/representantes legais
                                    concordam em autorizar gratuitamente neste
                                    ato, o uso de seu nome, sua imagem, nome e
                                    som de voz, sem qualquer &ocirc;nus para a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>, para uso
                                    exclusivo na divulga&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o, por meio de
                                    cartazes, filmes e/ou spots, jingles e/ou
                                    vinhetas, bem como em qualquer tipo de
                                    m&iacute;dia e/ou pe&ccedil;as promocionais,
                                    impressas ou digitais, incluindo mas
                                    n&atilde;o se limitando a televis&atilde;o,
                                    r&aacute;dio, jornal, cartazes, materiais de
                                    comunica&ccedil;&atilde;o em pontos de
                                    venda, faixas, <em>outdoors</em>,
                                    mala-direta e na Internet, durante o
                                    per&iacute;odo de realiza&ccedil;&atilde;o
                                    desta Promo&ccedil;&atilde;o e pelo
                                    per&iacute;odo de 12 (doze) meses, contados
                                    da data da data de t&eacute;rmino desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.13.1.</strong> Os Participantes
                                    contemplados garantem que n&atilde;o
                                    h&aacute; terceiros detentores de direitos
                                    exclusivos sobre a exibi&ccedil;&atilde;o,
                                    publica&ccedil;&atilde;o,
                                    distribui&ccedil;&atilde;o,
                                    reprodu&ccedil;&atilde;o e/ou
                                    coloca&ccedil;&atilde;o em neg&oacute;cios
                                    de sua imagem, nome e voz. Consequentemente,
                                    os participantes contemplados
                                    manter&atilde;o a
                                    <strong> MANDAT&Aacute;RIA&nbsp;</strong>
                                    e/ou suas ag&ecirc;ncias de publicidade
                                    protegidas contra quaisquer queixas de
                                    responsabilidade levantadas por terceiros em
                                    rela&ccedil;&atilde;o a esta
                                    divulga&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.14</strong> As
                                    autoriza&ccedil;&otilde;es descritas no item
                                    11.13 acima n&atilde;o implicam em qualquer
                                    obriga&ccedil;&atilde;o de
                                    divulga&ccedil;&atilde;o ou de pagamento de
                                    qualquer quantia adicional por parte da{" "}
                                    <strong>MANDAT&Aacute;RIA&nbsp;</strong>
                                    desde que estas n&atilde;o se utilizem de
                                    direitos dos ganhadores ap&oacute;s a
                                    expira&ccedil;&atilde;o do prazo mencionado.
                                    Caso desejarem, a{" "}
                                    <strong>MANDAT&Aacute;RIA </strong>e os
                                    contemplados, poder&atilde;o, de
                                    m&uacute;tuo acordo, firmar contratos
                                    espec&iacute;ficos para licen&ccedil;a de
                                    tais direitos por prazo superior a 12 (doze)
                                    meses contados da data de t&eacute;rmino da
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.15</strong> Os Participantes
                                    contemplados concordam que dever&atilde;o se
                                    abster de utilizar as marcas da{" "}
                                    <strong>MANDAT&Aacute;RIA&nbsp;</strong>sem
                                    a sua pr&eacute;via e expressa
                                    autoriza&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.16</strong> O N&uacute;mero do
                                    Certificado de autoriza&ccedil;&atilde;o
                                    desta Promo&ccedil;&atilde;o constar&aacute;
                                    sempre neste regulamento e nos Canais
                                    Digitais, estando a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    dispensada da aposi&ccedil;&atilde;o em
                                    todos os materiais de
                                    comunica&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.17</strong> Presume-se que o
                                    Contemplado n&atilde;o tenha qualquer
                                    embara&ccedil;o fiscal, legal ou outro que o
                                    impe&ccedil;a de receber e/ou usufruir do
                                    pr&ecirc;mio distribu&iacute;do. Na
                                    eventualidade de quaisquer impedimentos, o
                                    Contemplado concorda em receber o
                                    pr&ecirc;mio por meio de carta compromisso,
                                    comprometendo-se com todas as
                                    regulariza&ccedil;&otilde;es
                                    necess&aacute;rias sinalizadas pela{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>. Neste
                                    caso, ap&oacute;s o recebimento da carta
                                    compromisso, o Contemplado compromete-se
                                    tamb&eacute;m a assinar o recibo de entrega
                                    de premia&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>11.18</strong> Nos termos da
                                    legisla&ccedil;&atilde;o, n&atilde;o
                                    h&aacute; incid&ecirc;ncia de Imposto de
                                    Renda na Fonte sobre a
                                    premia&ccedil;&atilde;o, haja vista a
                                    modalidade de realiza&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o.&nbsp;
                                </p>
                                <p>
                                    <strong>11.19</strong> Fica, desde
                                    j&aacute;, eleito o foro do domic&iacute;lio
                                    do participante para a solu&ccedil;&atilde;o
                                    de quaisquer quest&otilde;es referentes ao
                                    Regulamento desta Promo&ccedil;&atilde;o.
                                </p>
                                <strong>
                                    <strong>12</strong>
                                    <strong>TERMO DE RESPONSABILIDADE</strong>
                                </strong>
                                <p>
                                    Poder&aacute; participar da
                                    promo&ccedil;&atilde;o qualquer consumidor
                                    que preencha os requisitos estipulados no
                                    regulamento da campanha autorizada;
                                </p>
                                <p>
                                    Os pr&ecirc;mios n&atilde;o poder&atilde;o
                                    ser convertidos em dinheiro; &Eacute; vedada
                                    a apura&ccedil;&atilde;o por meio
                                    eletr&ocirc;nico;
                                </p>
                                <p>
                                    Os pr&ecirc;mios ser&atilde;o
                                    entregues&nbsp;em at&eacute; 30 dias da data
                                    da apura&ccedil;&atilde;o/sorteio, sem
                                    qualquer &ocirc;nus aos contemplados
                                </p>
                                <p>
                                    Quando o pr&ecirc;mio sorteado, ganho em
                                    concurso ou conferido mediante vale-brinde,
                                    n&atilde;o for reclamado no prazo de cento e
                                    oitenta (180) dias, contados,
                                    respectivamente, da data do sorteio, da
                                    apura&ccedil;&atilde;o do resultado do
                                    concurso ou do t&eacute;rmino do prazo da
                                    promo&ccedil;&atilde;o, caducar&aacute; o
                                    direito do respectivo titular e o valor
                                    correspondente ser&aacute; recolhido pela
                                    empresa autorizada ao Tesouro Nacional como
                                    renda da Uni&atilde;o, no prazo de quarenta
                                    e cinco (45) dias;
                                </p>
                                <p>
                                    Em caso de promo&ccedil;&otilde;es com
                                    participa&ccedil;&atilde;o de menor de
                                    idade, sendo este contemplado,
                                    dever&aacute;, no ato da entrega do
                                    pr&ecirc;mio, ser representado por seu
                                    respons&aacute;vel legal, &agrave;
                                    exce&ccedil;&atilde;o das
                                    promo&ccedil;&otilde;es comerciais
                                    realizadas por concession&aacute;ria ou
                                    permission&aacute;ria de servi&ccedil;o de
                                    radiodifus&atilde;o, nos termos do artigo
                                    1&ordm;-A, &sect; 3&ordm;, da Lei 5.768, de
                                    20 de dezembro de 1971;
                                </p>
                                <p>
                                    A divulga&ccedil;&atilde;o da imagem dos
                                    contemplados poder&aacute; ser feita
                                    at&eacute; um ano ap&oacute;s a
                                    apura&ccedil;&atilde;o da
                                    promo&ccedil;&atilde;o comercial;
                                </p>
                                <p>
                                    As d&uacute;vidas e controv&eacute;rsias
                                    oriundas de reclama&ccedil;&otilde;es dos
                                    participantes ser&atilde;o, primeiramente,
                                    dirimidas pelas promotoras, persistindo-as,
                                    estas dever&atilde;o ser submetidas &agrave;
                                    SPA/MF;
                                </p>
                                <p>
                                    Os &oacute;rg&atilde;os locais de defesa do
                                    consumidor receber&atilde;o as
                                    reclama&ccedil;&otilde;es devidamente
                                    fundamentadas;
                                </p>
                                <p>
                                    A presta&ccedil;&atilde;o de contas
                                    dever&aacute; ser realizada no prazo
                                    m&aacute;ximo de (trinta) dias ap&oacute;s a
                                    data de prescri&ccedil;&atilde;o dos
                                    pr&ecirc;mios sob pena de descumprimento do
                                    plano de distribui&ccedil;&atilde;o de
                                    pr&ecirc;mios.
                                </p>
                                <p>
                                    O regulamento dever&aacute; ser afixado em
                                    lugar de ampla visibilidade e se apresentar
                                    em tamanho e em grafia que viabilizem a
                                    compreens&atilde;o e
                                    visualiza&ccedil;&atilde;o por parte do
                                    consumidor participante da
                                    promo&ccedil;&atilde;o comercial;
                                </p>
                                <p>
                                    Al&eacute;m dos termos acima, a
                                    promo&ccedil;&atilde;o comercial
                                    dever&aacute; obedecer &agrave;s
                                    condi&ccedil;&otilde;es previstas na Lei
                                    n&ordm; 5.768, de 1971, no Decreto n&ordm;
                                    70.951, de 1972, Portaria SEAE n&ordm;
                                    7.638, de 2022, Portaria MF n&ordm; 67, de
                                    2017, Portaria MF n&ordm; 422 de 2013,
                                    Portaria SECAP 20.749 de 2000, e em atos que
                                    as complementarem.
                                </p>
                                <p>
                                    Para as modalidades "Sorteio" e "Assemelhada
                                    a Sorteio" a empresa dever&aacute; anexar a
                                    Lista de Participantes na aba
                                    "Apura&ccedil;&otilde;es", contendo nomes e
                                    n&uacute;meros da sorte distribu&iacute;dos,
                                    ap&oacute;s o t&eacute;rmino de cada
                                    per&iacute;odo de participa&ccedil;&atilde;o
                                    e antes da extra&ccedil;&atilde;o da
                                    Loteria. O arquivo dever&aacute; ser no
                                    formato .csv, .xls ou .zip e cada arquivo
                                    poder&aacute; ter at&eacute; 250MB.
                                </p>
                                <p>
                                    A infring&ecirc;ncia &agrave;s
                                    cl&aacute;usulas do Termo de
                                    Responsabilidade e do Regulamento constituem
                                    descumprimento do plano de
                                    opera&ccedil;&atilde;o e ensejam as
                                    penalidades previstas no artigo 13 da Lei
                                    n&ordm;. 5.768, de 1971.
                                </p>
                                <p>DA PRESTA&Ccedil;&Atilde;O DE CONTAS</p>
                                <p>
                                    A presta&ccedil;&atilde;o de contas
                                    dever&aacute; ser realizada at&eacute; a
                                    data constante&nbsp;no cabe&ccedil;alho da
                                    promo&ccedil;&atilde;o no SCPC, conforme as
                                    regras estabelecidas na Portaria SEAE
                                    n&ordm; 7.638, de 18 de outubro de 2022. O
                                    vencimento do prazo para a
                                    presta&ccedil;&atilde;o de contas constitui
                                    em mora &agrave;s empresas promotoras. A
                                    n&atilde;o realiza&ccedil;&atilde;o da
                                    presta&ccedil;&atilde;o de contas at&eacute;
                                    a data de seu vencimento ensejar&aacute; a
                                    aplica&ccedil;&atilde;o de multa de 100%
                                    (cem por cento) incidente sobre a soma dos
                                    valores dos bens prometidos a t&iacute;tulo
                                    de premia&ccedil;&atilde;o e a
                                    proibi&ccedil;&atilde;o de realizar as
                                    opera&ccedil;&otilde;es de
                                    distribui&ccedil;&atilde;o gratuita de
                                    pr&ecirc;mios a t&iacute;tulo de propaganda,
                                    durante o prazo de 2 (dois) anos, contados
                                    da data limite da presta&ccedil;&atilde;o de
                                    contas, nos termos do art. 13 da Lei n&ordm;
                                    5.768, de 20 de dezembro de 1971. A
                                    fixa&ccedil;&atilde;o da multa poder&aacute;
                                    ser revista em grau de recurso
                                    administrativo, a ser apresentado conforme o
                                    art. 56 da Lei n&ordm; 9.784, de 29 de
                                    janeiro de 1999.
                                </p>
                                <p>
                                    <strong>ANEXO I</strong>
                                </p>
                                <p>
                                    <strong>
                                        LISTA DE PRODUTOS PARTICIPANTES
                                    </strong>
                                </p>
                                <div>
                                    <figure>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <strong>CV</strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <strong>
                                                                DESCRI&Ccedil;&Atilde;O
                                                            </strong>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136198</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                AVON DISNEY
                                                                ENCANTO COLOGNE
                                                                150ML
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136199</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                AVON DISNEY
                                                                PRINCESSS
                                                                JASMINE COLOGNE
                                                                150ML
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136202</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                MICKEY MOUSE BAR
                                                                SOAP LOCAL PROJ
                                                                BRA
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136207</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MINNIE MOUSE COLOGNE
                                                            LATAM
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136208</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MINNIE BAR SOAPS
                                                            RELABEL 21 LATAM
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136210</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MICKEY COLONIA
                                                            REDESENHO 2020
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136216</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO CURLY HAIR
                                                            MIRABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136217</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO FROZEN
                                                            BRILHO
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136218</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO HIDRATACAO
                                                            ARIEL BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136219</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO SPIDER 2 EM
                                                            1 750ML BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136220</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CONDICIONADOR FROZEN
                                                            BRILHO BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136221</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CONDICIONADOR
                                                            HIDRATACAO ARIEL
                                                            BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136225</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CREME PENTEAR
                                                            MIRABEL 150G
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136226</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            FROZEN HAIR GEL
                                                            RELABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136227</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON SPIDERMAN
                                                            COLOGNE 150ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136228</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON AVENGERS
                                                            COLOGNE 150ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136230</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COLONIA ARIEL 150ML
                                                            REDESENHO 2020
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136231</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COLONIA BRANCA DE
                                                            NEVE 150ML REDESENHO
                                                            2020
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136232</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CONDICIONADOR CACHOS
                                                            MIRABEL 200ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136286</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                KIT 2 POTES
                                                                EMPILHA AVENGERS
                                                                MULTI COLOR
                                                                GROUP CAPITAO
                                                                AMERICA CHILDREN
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136287</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                KIT 2 POTES
                                                                EMPILHA AVENGERS
                                                                MULTI COLOR
                                                                GROUP 2 PIECE(S)
                                                                HOMEM DE FERRO
                                                                CHILDREN
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136288</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                KIT 2 POTES
                                                                EMPILHA AVENGERS
                                                                MULTI COLOR
                                                                GROUP HULK
                                                                CHILDREN
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136330</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ESCOVA DE CABELO
                                                            DISNEY FEM
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136334</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ESCOVA DE CABELO
                                                            MARVEL MASC CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136364</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                CANECA CUTE
                                                                MICKEY E AMIGOS
                                                                MICKEY CHILDREN
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136389</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE EMPILHAVEL
                                                            MINNIE CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136410</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE EMPILHAVEL
                                                            MICKEY CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139120</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            TOALHA MICKEY DE
                                                            BANHO CHILDREN 2023
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139121</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            TOALHA MINNIE DE
                                                            BANHO 2023 CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139127</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM CANUDO
                                                            MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139129</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM CANUDO
                                                            MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148307</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            TOALHA DE BANHO
                                                            PERSONAGENS CHILDREN
                                                            SPIDERMAN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148310</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA SNACK FROZEN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148314</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                POTE C COLHER
                                                                MULTI COLOR
                                                                GROUP CHILDREN 1
                                                                PIECE(S) DISNEY
                                                                100
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148315</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE C COLHER TEENS
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148371</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                KIT 2 POTES
                                                                MICKEY. MULTI
                                                                COLOR GROUP
                                                                CHILDREN 1
                                                                PIECE(S) MICKEY
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148376</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                CANECA CUTE
                                                                MICKEY 2023
                                                                MULTI COLOR
                                                                GROUP CHILDREN 1
                                                                PIECE(S) MICKEY
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>152149</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA STITCH</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155428</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA REDONDA 2
                                                            ANDARES FROZEN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155429</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA REDONDA 2
                                                            ANDARES SPIDERMAN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155430</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA SPIDERMAN</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155431</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA FROZEN</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155437</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SABONETEIRA MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155438</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SABONETEIRA MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155441</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA ESCOV DE
                                                            DENTES MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155442</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA ESCOV DE
                                                            DENTES MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163412</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            GARRAFA
                                                            CAPIT&Atilde;O
                                                            AM&Eacute;RICA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163413</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            GARRAFA HOMEM DE
                                                            FERRO
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163414</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA HULK</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163417</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA MIRABEL</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163420</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA REDONDA
                                                            MIRABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163422</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA SNACK MIRABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163430</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CANECA SPIDERMAN
                                                            2024
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168360</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE PIPOCA C COPO
                                                            MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168364</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ALMOFADA DE
                                                            PESCO&Ccedil;O
                                                            MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168625</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE PIPOCA C COPO
                                                            MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168628</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ALMOFADA DE
                                                            PESCO&Ccedil;O
                                                            MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168756</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT PIPOCA +
                                                            ALMOFADA MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168757</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT PIPOCA +
                                                            ALMOFADA MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169923</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ORGANIZADOR MULTIUSO
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169924</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 3 POTES REDONDOS
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169925</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 3 POTRES
                                                            QUADRADOS
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169926</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CANECA DIVERTIDA
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170776</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT CAIXA
                                                            ORGANIZADORA STITCH
                                                            TIE DIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170777</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                KIT 2
                                                                ORGANIZADORES
                                                                MULT STITCH Tie
                                                                Dye
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170779</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>
                                                                KIT 3 POTES
                                                                REDONDOS STITCH
                                                                TIE DYE
                                                            </span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170784</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA COROA
                                                            BRANCA DE NEVE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170785</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA COROA
                                                            ARIEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170787</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA
                                                            CAPIT&Atilde;O
                                                            AM&Eacute;RICA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170788</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA
                                                            PANTERA NEGRA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170789</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            BRANCA DE NEVE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170790</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            ARIEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170791</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            CAPIT&Atilde;O
                                                            AM&Eacute;RICA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170793</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            PANTERA NEGRA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172511</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT BCA NEVE
                                                            COPO COROA POTE
                                                            TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172512</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT ARIEL COPO
                                                            COROA POTE TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172513</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT CAP AMERICA
                                                            COPO POTE TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172514</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT PANTERA
                                                            NEGRA COPO POTE
                                                            TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </figure>
                                </div>
                            </div>
                            <div id="Regulamento2">
                                <p>
                                    <strong>
                                        REGULAMENTO/PLANO DE
                                        OPERA&Ccedil;&Atilde;O DA
                                        PROMO&Ccedil;&Atilde;O
                                    </strong>
                                </p>
                                <p>
                                    <strong>&ldquo;VIVA A MAGIA&rdquo;</strong>
                                </p>
                                <p>
                                    <strong>
                                        CERTIFICADO DE AUTORIZA&Ccedil;&Atilde;O
                                        SPA/MF N&ordm;{" "}
                                    </strong>
                                    <strong>04.036552</strong>
                                    <strong>/2024</strong>
                                </p>
                                <p>
                                    <strong>1.</strong>{" "}
                                    <strong>
                                        EMPRESAS MANDAT&Aacute;RIAS:
                                    </strong>
                                </p>
                                <p>
                                    <strong>1.1</strong>{" "}
                                    <strong>&ndash;</strong>{" "}
                                    <strong>Empresa Mandat&aacute;ria</strong>:
                                </p>
                                <p>
                                    Raz&atilde;o Social:{" "}
                                    <strong>
                                        NATURA BIOSPHERA FRANQUEADORA LTDA
                                    </strong>
                                </p>
                                <p>
                                    Endere&ccedil;o: Avenida Alexandre Colares,
                                    n&ordm; 1188, salas 54, 55 e 58
                                </p>
                                <p>Bairro: Parque Anhanguera</p>
                                <p>Munic&iacute;pio: S&atilde;o Paulo UF: SP</p>
                                <p>CEP: 05.106-000</p>
                                <p>CNPJ/ME n&ordm;: 15.537.286/0001-16</p>
                                <p>
                                    <strong>1.2</strong>{" "}
                                    <strong>&ndash; Aderentes:</strong>
                                </p>
                                <p>
                                    Raz&atilde;o Social:{" "}
                                    <strong>
                                        NATURA COSM&Eacute;TICOS S/A
                                    </strong>
                                </p>
                                <p>
                                    Endere&ccedil;o: Avenida Alexandre Colares,
                                    n&ordm; 1188
                                </p>
                                <p>Bairro: Parque Anhanguera</p>
                                <p>Munic&iacute;pio: S&atilde;o Paulo UF: SP</p>
                                <p>CEP: 05.106-000</p>
                                <p>CNPJ/ME n&ordm;: 71.673.990/0001-77</p>
                                <p>
                                    Raz&atilde;o Social:{" "}
                                    <strong>
                                        THE WALT DISNEY COMPANY (BRASIL) LTDA
                                    </strong>
                                </p>
                                <p>
                                    Endere&ccedil;o: Avenida das
                                    Na&ccedil;&otilde;es Unidas, n&ordm;
                                    12.551/12.559 &ndash; 10&ordm; e 11&ordm;
                                    andares &ndash; conjuntos 1.103 a 1.108
                                </p>
                                <p>Bairro: Brooklin Paulista</p>
                                <p>Munic&iacute;pio: S&atilde;o Paulo UF: SP</p>
                                <p>CEP: 04.578-903</p>
                                <p>CNPJ/ME n&ordm;: 73.042.962/0001-87</p>
                                <p>
                                    <strong>2.</strong>{" "}
                                    <strong>
                                        MODALIDADE DA PROMO&Ccedil;&Atilde;O:
                                    </strong>
                                </p>
                                <p>Assemelhada a Sorteio</p>
                                <p>
                                    <strong>3.</strong>{" "}
                                    <strong>
                                        &Aacute;REA DE ABRANG&Ecirc;NCIA:
                                    </strong>
                                </p>
                                <p>Todo o territ&oacute;rio nacional</p>
                                <p>
                                    <strong>4.</strong>{" "}
                                    <strong>
                                        PER&Iacute;ODO DA
                                        PROMO&Ccedil;&Atilde;O:
                                    </strong>
                                </p>
                                <p>06/09/2024 a 07/01/2025</p>
                                <p>
                                    <strong>5.</strong>{" "}
                                    <strong>
                                        PER&Iacute;ODO DE
                                        PARTICIPA&Ccedil;&Atilde;O:
                                    </strong>
                                </p>
                                <p>06/09/2024 a 30/11/2024</p>
                                <p>
                                    <strong>6.</strong>{" "}
                                    <strong>
                                        CRIT&Eacute;RIO DE
                                        PARTICIPA&Ccedil;&Atilde;O:
                                    </strong>
                                </p>
                                <p>
                                    <strong>DEFINI&Ccedil;&Otilde;ES</strong>:
                                    Todos os termos aqui definidos no singular
                                    dever&atilde;o ser considerados,
                                    tamb&eacute;m, no plural.
                                </p>
                                <p>
                                    <strong>6.1.</strong>
                                    <strong>Promo&ccedil;&atilde;o</strong>:
                                    distribui&ccedil;&atilde;o gratuita de
                                    pr&ecirc;mio prevista neste regulamento
                                    (&ldquo;<strong>Regulamento</strong>
                                    &rdquo;), na modalidade assemelhada a
                                    sorteio, divulgada no <em>Hotsite&nbsp;</em>
                                    da Promo&ccedil;&atilde;o e outros canais, a
                                    exclusivo crit&eacute;rio das{" "}
                                    <strong>PROMOTORAS</strong>.
                                </p>
                                <p>
                                    <strong>6.1.1.</strong>{" "}
                                    <strong>
                                        Promo&ccedil;&atilde;o Hom&ocirc;nima:
                                    </strong>{" "}
                                    a Promo&ccedil;&atilde;o ocorrer&aacute;
                                    concomitantemente a outra
                                    promo&ccedil;&atilde;o realizada na
                                    modalidade assemelhada a vale-brinde, com
                                    regulamento e regras pr&oacute;prias,
                                    devendo cada Promo&ccedil;&atilde;o ser
                                    interpretada nos termos de seu regulamento
                                    espec&iacute;fico.
                                </p>
                                <p>
                                    <strong>6.2.</strong>
                                    <strong>
                                        Cliente Eleg&iacute;vel
                                    </strong>{" "}
                                    &ndash; pessoas f&iacute;sicas maiores de 18
                                    (dezoito) anos, inscritas e com o cadastro
                                    ativo no Cadastro de Pessoas F&iacute;sicas
                                    (CPF) do Minist&eacute;rio da Fazenda, que
                                    adquirirem Produtos Participantes da marca
                                    da Aderente, atrav&eacute;s da
                                    edi&ccedil;&atilde;o da revista Casa &amp;
                                    Estilo da <strong>MANDAT&Aacute;RIA</strong>
                                    , durante o Per&iacute;odo de
                                    Participa&ccedil;&atilde;o na
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.3.</strong>{" "}
                                    <strong> Cliente Participante</strong>{" "}
                                    &ndash; S&atilde;o os Clientes
                                    Eleg&iacute;veis que, ap&oacute;s o cadastro
                                    pessoal e do Produto Participante adquirido,
                                    cumprirem com todas as
                                    condi&ccedil;&otilde;es fixadas neste
                                    Regulamento.
                                </p>
                                <p>&nbsp;</p>
                                <p>
                                    <strong>6.4.</strong>
                                    <strong>Produto Participante&nbsp;</strong>
                                    &ndash; Produtos da marca da{" "}
                                    <strong>Aderente</strong> disponibilizados
                                    atrav&eacute;s da revista Casa &amp; Estilo
                                    da <strong>MANDAT&Aacute;RIA</strong>
                                    ,descritos no ANEXO I deste Regulamento e
                                    dispon&iacute;veis no <em>Hotsite</em>&nbsp;
                                    <a href="http://www.avon.com.br">
                                        www.avon.com.br
                                    </a>
                                    .
                                </p>
                                <p>
                                    <strong>6.5.</strong>
                                    <em>
                                        <strong>Opt-In</strong>
                                    </em>{" "}
                                    &ndash; &eacute; o aceite e
                                    concord&acirc;ncia ao Regulamento desta
                                    promo&ccedil;&atilde;o e aos Termos de Uso
                                    da <strong>MANDAT&Aacute;RIA.</strong>
                                </p>
                                <p>
                                    <strong>6.6.</strong>{" "}
                                    <em>
                                        <strong>Hotsite</strong>
                                    </em>
                                    &ndash; &eacute; o hotsite{" "}
                                    <a href="http://www.vivamagiacasaestilo.com.br">
                                        www.vivamagiacasaestilo.com.br
                                    </a>
                                    .
                                </p>
                                <p>
                                    <strong>6.7.</strong>{" "}
                                    <strong>
                                        Per&iacute;odo da Promo&ccedil;&atilde;o
                                    </strong>
                                    : per&iacute;odo de realiza&ccedil;&atilde;o
                                    total desta Promo&ccedil;&atilde;o,
                                    incluindo o Per&iacute;odo de Compras e o
                                    Per&iacute;odo de Cadastro at&eacute; a data
                                    da apura&ccedil;&atilde;o. A
                                    Promo&ccedil;&atilde;o ser&aacute; iniciada
                                    no dia 06/09/2024, &agrave;s 00h00.
                                </p>
                                <p>
                                    <strong>6.8.</strong>
                                    <strong> Per&iacute;odo de Compras</strong>:
                                    &eacute; o per&iacute;odo em que os Clientes
                                    Participantes poder&atilde;o realizar suas
                                    compras, compreendido entre as 00h00 de{" "}
                                    <strong>06/09/2024</strong> &agrave;s 23h59{" "}
                                    <strong>11/11/2024.</strong>
                                </p>
                                <p>
                                    <strong>
                                        6.9. Per&iacute;odo de Cadastro
                                    </strong>
                                    : &eacute; o per&iacute;odo em que os
                                    Clientes Participantes poder&atilde;o
                                    realizar o cadastro(&ldquo;Cadastro&rdquo;)
                                    de seus dados pessoais e do Produto
                                    Participante e fazer jus ao recebimento de
                                    N&uacute;meros da Sorte nesta
                                    Promo&ccedil;&atilde;o, compreendido entre
                                    as 00h00 de <strong>06/09/2024</strong>{" "}
                                    &agrave;s 23h59 <strong>30/11/2024.</strong>
                                </p>
                                <p>
                                    <strong>6.10.</strong>{" "}
                                    <strong>
                                        COMO PARTICIPAR E RECEBER N&Uacute;MEROS
                                        DA SORTE
                                    </strong>
                                </p>
                                <p>
                                    <strong>6.10.1.</strong> Para participar
                                    desta promo&ccedil;&atilde;o,&nbsp;os
                                    Clientes Eleg&iacute;veis, dever&atilde;o,
                                    durante o Per&iacute;odo de
                                    Participa&ccedil;&atilde;o na
                                    A&ccedil;&atilde;o:&nbsp;
                                </p>
                                <p>
                                    <strong>(a)</strong> Realizar a compra de ao
                                    menos 1 (um) Produto Participante indicado
                                    no ANEXO I;
                                </p>
                                <p>
                                    <strong>(b)</strong>Acessar o{" "}
                                    <em>Hotsite</em> da Promo&ccedil;&atilde;o
                                    no per&iacute;odo de
                                    participa&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o;&nbsp;
                                </p>
                                <p>
                                    <strong>(c)</strong>Realizar o
                                    pr&eacute;-cadastro indicando, para tanto, o
                                    seu endere&ccedil;o de <em>e-mail</em>{" "}
                                    v&aacute;lido;
                                </p>
                                <p>
                                    <strong>(d)</strong> Ap&oacute;s receber o
                                    Produto Participante que realizou a
                                    compra,efetivar seu cadastro pessoal
                                    completo para acessar a
                                    Promo&ccedil;&atilde;o, atrav&eacute;s do
                                    preenchimento de formul&aacute;rio, com as
                                    seguintes informa&ccedil;&otilde;es: (i)
                                    Nome Completo; (ii) CPF/MF; (iii) Data de
                                    nascimento; (iv) Telefone celular; (v)
                                    Endere&ccedil;o completo. No mesmo momento,
                                    criar seu <em>login</em> e senha e realizar
                                    o <em>Opt-In</em>; e&nbsp;
                                </p>
                                <p>
                                    <strong>(e)</strong> Cadastrar o
                                    c&oacute;digo de barra do Produto
                                    Participante adquirido.&nbsp;
                                </p>
                                <p>
                                    <strong>6.10.2.</strong> Fica desde
                                    j&aacute; estabelecido que a{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>{" "}
                                    poder&aacute; solicitar aos interessados
                                    outras informa&ccedil;&otilde;es no momento
                                    do cadastramento, a seu exclusivo
                                    crit&eacute;rio, sendo estas de
                                    car&aacute;ter obrigat&oacute;rio, quando
                                    assim sinalizado, e fundamentado.
                                </p>
                                <p>
                                    <strong>6.10.3.</strong> Ap&oacute;s a
                                    leitura do Regulamento da
                                    Promo&ccedil;&atilde;o pelos interessados e
                                    manifesta&ccedil;&atilde;o do seu respectivo{" "}
                                    <em>Opt-In</em>, estes estar&atilde;o
                                    declarando e garantindo: (i) que s&atilde;o
                                    eleg&iacute;veis &agrave;
                                    participa&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o; (ii) possuem
                                    capacidade jur&iacute;dica para tanto, bem
                                    como que todas as informa&ccedil;&otilde;es
                                    prestadas s&atilde;o verdadeiras; (iii) que
                                    est&atilde;o de acordo com todos os termos
                                    do Regulamento da Promo&ccedil;&atilde;o;
                                    (iv) que est&atilde;o cientes de que os
                                    dados, fornecidos para
                                    participa&ccedil;&atilde;o na
                                    Promo&ccedil;&atilde;o, ser&atilde;o
                                    utilizados para os fins dispostos neste
                                    Regulamento, nos termos da Lei Geral de
                                    Prote&ccedil;&atilde;o de Dados; e (v)
                                    enviar informa&ccedil;&otilde;es da
                                    promo&ccedil;&atilde;o e/ou outras
                                    comunica&ccedil;&otilde;es relacionadas
                                    &agrave; promo&ccedil;&atilde;o
                                    atrav&eacute;s dos seguintes canais: e-mail,
                                    SMS e/ou, <em>WhatsApp</em>.
                                </p>
                                <p>
                                    <strong>6.10.4.</strong> O <em>e-mail</em>{" "}
                                    fornecido no momento do pr&eacute;-cadastro
                                    ser&aacute; utilizado para o
                                    compartilhamento das
                                    informa&ccedil;&otilde;es da
                                    Promo&ccedil;&atilde;o, inclusive, do{" "}
                                    <em>Hotsite</em> desta
                                    Promo&ccedil;&atilde;o, para que o Cliente
                                    Participante finalize o seu cadastro
                                    completo.
                                </p>
                                <p>
                                    <strong>6.10.5.</strong> O cadastro de
                                    Produto n&atilde;o participante ou em
                                    desconformidade com os crit&eacute;rios de
                                    participa&ccedil;&atilde;o, n&atilde;o
                                    ser&aacute; considerado para v&aacute;lido
                                    para fins de libera&ccedil;&atilde;o de
                                    Chance e para participar do Acionamento.
                                </p>
                                <p>
                                    <strong>6.10.6.</strong> O Cliente
                                    Eleg&iacute;vel somente ser&aacute;
                                    considerado &ldquo;Participante&rdquo; da
                                    Promo&ccedil;&atilde;o ap&oacute;s a
                                    realiza&ccedil;&atilde;o de <em>Opt-In</em>{" "}
                                    e cadastro pessoal completo e do Produto
                                    Participante adquirido.&nbsp;
                                </p>
                                <p>
                                    <strong>6.10.7.</strong> Ap&oacute;s o
                                    cadastro dos Produtos Participantes
                                    adquiridos, a distribui&ccedil;&atilde;o dos
                                    N&uacute;meros da Sorte ao que o Cliente
                                    Participante far&aacute; jus, ser&aacute;
                                    realizada automaticamente, de acordo com a
                                    quantidade de Produtos Participantes
                                    adquiridos.{" "}
                                    <strong>
                                        A cada Produto Participante adquiridos,
                                        o Participante far&aacute; jus ao
                                        recebimento 1 (um) N&uacute;mero da
                                        Sorte
                                    </strong>
                                    .
                                </p>
                                <p>
                                    <strong>6.10.7.1.</strong> Independentemente
                                    da quantidade de Produtos Participantes
                                    adquiridos, o Participante poder&aacute;
                                    fazer jus ao recebimento de, no
                                    m&aacute;ximo, 3 (tr&ecirc;s) N&uacute;meros
                                    da Sorte nesta Promo&ccedil;&atilde;o, e
                                    somente poder&aacute; ser contemplado 1
                                    (uma) &uacute;nica vez nesta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.10.8.</strong> Os N&uacute;meros
                                    da Sorte ser&atilde;o distribu&iacute;dos de
                                    acordo com as s&eacute;ries definidas no
                                    item 9, observada a quantidade total de
                                    N&uacute;meros da Sorte dispon&iacute;vel
                                    nesta Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>6.10.9.</strong> O Participante
                                    poder&aacute; concorrer com quantos
                                    N&uacute;meros da Sorte conquistar,
                                    observado o Limite de N&uacute;meros da
                                    Sorte.
                                </p>
                                <p>
                                    <strong>6.10.10.</strong> O Participante
                                    poder&aacute; consultar o seu respectivo
                                    N&uacute;mero da Sorte no <em>Hotsite</em>,
                                    com acesso por meio de login e senha.
                                </p>
                                <p>
                                    <strong>7.</strong>{" "}
                                    <strong>
                                        QUANTIDADE DE S&Eacute;RIES:
                                    </strong>
                                </p>
                                <p>10</p>
                                <p>
                                    <strong>8.</strong>{" "}
                                    <strong>
                                        QUANTIDADE DE ELEMENTOS
                                        SORTE&Aacute;VEIS POR S&Eacute;RIE:
                                    </strong>
                                </p>
                                <p>100.000</p>
                                <p>
                                    <strong>9.</strong>{" "}
                                    <strong>
                                        APURA&Ccedil;&Atilde;O E
                                        DESCRI&Ccedil;&Atilde;O DE
                                        PR&Ecirc;MIOS:
                                    </strong>
                                </p>
                                <p>DATA: 14/10/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 12/09/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    14/09/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 2.100,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 539,91</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 435,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 629,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 21/10/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 19/09/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    21/09/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 2.100,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 539,91</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 435,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 629,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 28/10/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 26/09/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    28/09/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 539,91</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 435,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 629,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 05/11/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 03/10/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    05/10/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 539,91</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 435,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 629,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 16/11/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 10/10/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    16/10/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 435,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 19/11/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 17/10/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    19/10/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 435,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 26/11/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 24/10/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    26/10/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 290,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 06/12/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 31/10/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    06/11/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 290,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 09/12/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 07/11/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    09/11/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 290,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 16/12/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 14/11/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    16/11/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (um) Tablet Multi
                                                        Mickey 4GB RAM + 64GB
                                                        Armazenamento + Tela 7
                                                        pol + Case + Wi-fi +
                                                        Android 13 (Go edition)
                                                        + Processador Quad Core
                                                        - NB413
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 440,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 290,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>5</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 23/12/2024, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 21/11/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    23/11/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 290,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>DATA: 07/01/2025, 14h</p>
                                <p>
                                    PER&Iacute;ODO DE PARTICIPA&Ccedil;&Atilde;O
                                    DA APURA&Ccedil;&Atilde;O: 06/09/2024
                                    &agrave;s 00h00 a 30/11/2024 &agrave;s 23h59
                                </p>
                                <p>
                                    DATA DO SORTEIO DA LOTERIA FEDERAL:
                                    07/12/2024
                                </p>
                                <p>
                                    ENDERE&Ccedil;O DA APURA&Ccedil;&Atilde;O:
                                    Av. Engenheiro Luis Carlos Berrini
                                    N&Uacute;MERO: 1700 COMPLEMENTO 17&ordm;
                                    andar BAIRRO: Cidade Mon&ccedil;&otilde;es
                                    MUNIC&Iacute;PIO: S&atilde;o Paulo UF: SP
                                    CEP: 04571-935&nbsp;
                                </p>
                                <p>
                                    LOCAL DA APURA&Ccedil;&Atilde;O: na Empresa
                                    The Group (de forma remota)
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Descri&ccedil;&atilde;o
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>
                                                            Individual (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Valor</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Total</strong>
                                                    </p>
                                                    <p>
                                                        <strong>(R$)</strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Inicial
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            S&eacute;rie Final
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>Ordem</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Mala De Viagem
                                                        Stitch Disney Tamanho
                                                        Pequena R&iacute;gida Em
                                                        Policarbonato Com
                                                        Cadeado E Rodinhas 360 E
                                                        Forro Personalizado -
                                                        54x33x20cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 420,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 1.680,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>1</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pel&uacute;cia
                                                        Stitch Lilo Disney 20 Cm
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 59,99</p>
                                                </td>
                                                <td>
                                                    <p>R$ 479,92</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>2</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Pipoqueira
                                                        El&eacute;trica Mallory
                                                        Disney Mickey -Pipocas
                                                        sequinhas, livres de
                                                        &Oacute;leo- 127V
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 145,00</p>
                                                </td>
                                                <td>
                                                    <p>R$ 290,00</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>3</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>8</p>
                                                </td>
                                                <td>
                                                    <p>
                                                        1 (uma) Maleta Para
                                                        Colorir Disney Mickey
                                                        Retangular - Molin
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>R$ 69,90</p>
                                                </td>
                                                <td>
                                                    <p>R$ 559,20</p>
                                                </td>
                                                <td>
                                                    <p>0</p>
                                                </td>
                                                <td>
                                                    <p>9</p>
                                                </td>
                                                <td>
                                                    <p>4</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>
                                    <strong>10.</strong>{" "}
                                    <strong>
                                        PREMIA&Ccedil;&Atilde;O TOTAL:
                                    </strong>
                                </p>
                                <figure>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Quantidade Total de
                                                            Pr&ecirc;mios
                                                        </strong>
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        <strong>
                                                            Valor Total da
                                                            Promo&ccedil;&atilde;o
                                                            (R$)
                                                        </strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p>290</p>
                                                </td>
                                                <td>
                                                    <p>R$ 42.739,00</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </figure>
                                <p>
                                    <strong>11.</strong>{" "}
                                    <strong>
                                        FORMA DE APURA&Ccedil;&Atilde;O:
                                    </strong>
                                </p>
                                <p>
                                    <strong>
                                        Regra de Forma&ccedil;&atilde;o dos
                                        Elementos Sorte&aacute;veis:
                                    </strong>
                                </p>
                                <p>
                                    ● <strong>N&Uacute;MERO DA SORTE:</strong>{" "}
                                    conjunto de algarismos gerados e
                                    distribu&iacute;dos de forma equitativa e
                                    aleat&oacute;ria ao Participante, que
                                    permite a sua participa&ccedil;&atilde;o na
                                    apura&ccedil;&atilde;o.&Eacute; formado por{" "}
                                    <strong>6</strong> algarismos, sendo que o{" "}
                                    <strong>1</strong> primeiro algarismo
                                    corresponde &agrave; s&eacute;rie e os{" "}
                                    <strong>5</strong> &uacute;ltimos algarismos
                                    ao Elemento Sorte&aacute;vel.&nbsp;
                                </p>
                                <p>
                                    ● <strong>S&Eacute;RIE:</strong> cada
                                    agrupamento de at&eacute; 100.000 Elementos
                                    Sorte&aacute;veis representada pelo{" "}
                                    <strong>1&ordm;</strong> primeiro algarismo
                                    do N&uacute;mero da Sorte.&nbsp;
                                </p>
                                <p>
                                    ●{" "}
                                    <strong>
                                        ELEMENTO SORTE&Aacute;VEL:&nbsp;
                                    </strong>
                                    conjunto de<strong> 5</strong> algarismos
                                    que poder&aacute; abarcar o intervalo
                                    entre00.000 (incluso este) e 99.999 (incluso
                                    este) e que, atrelado a uma s&eacute;rie,
                                    comp&otilde;e o N&uacute;mero da Sorte.
                                </p>
                                <p>
                                    <strong>
                                        <u>1.23456</u>
                                    </strong>
                                    : n&uacute;mero da sorte = <u>1</u>{" "}
                                    (s&eacute;rie); <u>23456</u> (elemento
                                    sorte&aacute;vel)
                                </p>
                                <p>
                                    <strong>
                                        Data do Sorteio da Loteria Federal
                                    </strong>
                                    : Datas indicadas na Apura&ccedil;&atilde;o
                                    conforme item 9. Caso a
                                    Extra&ccedil;&atilde;o da Loteria Federal
                                    n&atilde;o venha a ocorrer na data prevista,
                                    ser&aacute; considerada a data da
                                    Extra&ccedil;&atilde;o da Loteria Federal
                                    imediatamente subsequente, sendo a
                                    defini&ccedil;&atilde;o do contemplado
                                    realizada em at&eacute; 30 (trinta) dias
                                    contados desta data.
                                </p>
                                <p>
                                    <strong>
                                        Regra de Apura&ccedil;&atilde;o da
                                        S&eacute;rie:&nbsp;
                                    </strong>
                                    se dar&aacute; a partir dos pr&ecirc;mios da
                                    Extra&ccedil;&atilde;o da Loteria Federal,
                                    lidos de cima para baixo, por meio da
                                    combina&ccedil;&atilde;o das dezenas simples
                                    do <strong>primeiro pr&ecirc;mio</strong>.
                                </p>
                                <p>
                                    Por exemplo, caso o resultado de um sorteio
                                    da Loteria Federal seja:
                                </p>
                                <p>
                                    1&ordm; pr&ecirc;mio 13.3<strong>7</strong>1
                                </p>
                                <p>2&ordm; pr&ecirc;mio 06.444</p>
                                <p>3&ordm; pr&ecirc;mio 16.539</p>
                                <p>4&ordm; pr&ecirc;mio 04.323</p>
                                <p>5&ordm; pr&ecirc;mio 18.464</p>
                                <p>
                                    Para a Apura&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o, que possui{" "}
                                    <strong>10</strong> (dez) s&eacute;ries
                                    numeradas de <strong>0</strong> a{" "}
                                    <strong>9</strong>, ser&aacute; identificada
                                    a combina&ccedil;&atilde;o das dezenas
                                    simples do{" "}
                                    <strong>primeiro pr&ecirc;mio</strong>, que
                                    no exemplo acima &eacute; o n&uacute;mero{" "}
                                    <strong>7</strong>. Assim, a s&eacute;rie
                                    seria a{" "}
                                    <strong>
                                        <u>7</u>
                                    </strong>
                                    , conforme exemplo acima.
                                </p>
                                <p>
                                    <strong>
                                        Regra de Apura&ccedil;&atilde;o do
                                        Elemento Sorte&aacute;vel:&nbsp;
                                    </strong>
                                    se dar&aacute; a partir dos pr&ecirc;mios da
                                    Extra&ccedil;&atilde;o da Loteria Federal,
                                    lidos de cima para baixo, por meio da
                                    combina&ccedil;&atilde;o das unidades
                                    simples do{" "}
                                    <strong>
                                        primeiro ao quinto pr&ecirc;mio
                                    </strong>
                                    .
                                </p>
                                <strong>
                                    <br />
                                    <strong>
                                        N&uacute;mero da Sorte
                                        Contemplado:&nbsp;
                                    </strong>
                                    utilizando-se o exemplo acima, para o
                                    Sorteio desta Promo&ccedil;&atilde;o,
                                    ter&iacute;amos o N&uacute;mero da Sorte{" "}
                                    <strong>
                                        <u>7.14934</u>
                                    </strong>
                                    , aonde <u>7</u> &eacute; a S&eacute;rie e{" "}
                                    <u>14934</u> &eacute; o Elemento
                                    Sorte&aacute;vel apurado.
                                </strong>
                                <strong>
                                    <strong>
                                        Regra de Apura&ccedil;&atilde;o dos
                                        Contemplado:{" "}
                                    </strong>
                                    Para determina&ccedil;&atilde;o do
                                    Contemplado, este deve possuir o
                                    N&uacute;mero da Sorte que coincida
                                    exatamente com o &ldquo;N&uacute;mero da
                                    Sorte Contemplado&rdquo;, ou o mais
                                    pr&oacute;ximo ao N&uacute;mero da Sorte
                                    Contemplado, conforme Regra de
                                    Aproxima&ccedil;&atilde;o abaixo, e atender
                                    aos crit&eacute;rios de
                                    participa&ccedil;&atilde;o.
                                </strong>
                                <strong>
                                    <strong>
                                        Aproxima&ccedil;&atilde;o:&nbsp;
                                    </strong>
                                    No caso de n&atilde;o ter sido
                                    distribu&iacute;do o &ldquo;N&uacute;mero da
                                    Sorte&rdquo; apurado, ou caso seu titular
                                    n&atilde;o atenda aos crit&eacute;rios de
                                    participa&ccedil;&atilde;o (ou seja, deva
                                    ser desclassificado), <u>alternadamente</u>,
                                    entregar-se-&aacute; o(s) pr&ecirc;mio(s) ao
                                    &ldquo;Elemento Sorte&aacute;vel&rdquo;{" "}
                                    <u>imediatamente superior</u>, efetivamente
                                    distribu&iacute;do dentro da mesma
                                    s&eacute;rie, e, na falta deste, ao
                                    &ldquo;Elemento Sorte&aacute;vel&rdquo;{" "}
                                    <u>imediatamente inferior</u>, repetindo-se
                                    tal procedimento at&eacute; que se encontre
                                    um &ldquo;Elemento Sorte&aacute;vel&rdquo;
                                    distribu&iacute;do na s&eacute;rie apurada.
                                </strong>
                                <p>
                                    No caso de se alcan&ccedil;ar o Elemento
                                    Sorte&aacute;vel final da s&eacute;rie
                                    (99.999), fica estabelecido que o Elemento
                                    Sorte&aacute;vel imediatamente superior
                                    &eacute; o 00.000. Da mesma forma, caso o
                                    Elemento Sorte&aacute;vel inicial da
                                    s&eacute;rie seja alcan&ccedil;ado (00.000),
                                    fica estabelecido que o Elemento
                                    Sorte&aacute;vel imediatamente inferior
                                    &eacute; o 99.999, mantendo-se a ordem de
                                    superiores, sendo mantida sempre a regra
                                    aqui descrita para a
                                    identifica&ccedil;&atilde;o do Elemento
                                    Sort&eacute;vel contemplado - alternadamente
                                    para o Elemento Sorte&aacute;vel
                                    imediatamente superior, ou, na
                                    impossibilidade desta, para a imediatamente
                                    inferior.
                                </p>
                                <p>
                                    Caso n&atilde;o tenha sido
                                    distribu&iacute;do nenhum &ldquo;Elemento
                                    Sorte&aacute;vel&rdquo; na s&eacute;rie
                                    apurada ou n&atilde;o tenha sido
                                    distribu&iacute;da quantidade suficiente de
                                    &ldquo;Elementos Sorte&aacute;veis&rdquo;
                                    para identificar o contemplado na{" "}
                                    <u>S&eacute;rie</u> apurada, deve-se repetir
                                    o procedimento descrito no par&aacute;grafo
                                    anterior para todas as s&eacute;ries que
                                    comp&otilde;em a apura&ccedil;&atilde;o,{" "}
                                    <u>alternadamente</u> para a s&eacute;rie{" "}
                                    <u>imediatamente superior</u>, ou, na
                                    impossibilidade desta, para a{" "}
                                    <u>imediatamente inferior</u>, verificando a
                                    eventual distribui&ccedil;&atilde;o do
                                    &ldquo;Elemento Sorte&aacute;vel
                                    apurado&rdquo;, em tal s&eacute;rie, e, se
                                    necess&aacute;rio, o procedimento de
                                    aproxima&ccedil;&atilde;o descrito no
                                    par&aacute;grafo anterior.
                                </p>
                                <p>
                                    No caso de se alcan&ccedil;ar a s&eacute;rie
                                    final dispon&iacute;vel para&nbsp;a
                                    apura&ccedil;&atilde;o, fica estabelecido
                                    que a s&eacute;rie imediatamente superior
                                    ser&aacute; a primeira s&eacute;rie
                                    dispon&iacute;vel da apura&ccedil;&atilde;o,
                                    da mesma forma, caso a s&eacute;rie inicial
                                    seja alcan&ccedil;ada, fica estabelecido que
                                    a s&eacute;rie imediatamente inferior
                                    ser&aacute; a &uacute;ltima s&eacute;rie
                                    dispon&iacute;vel da apura&ccedil;&atilde;o
                                    (exemplo para a Apura&ccedil;&atilde;o:
                                    aplicando-se a regra aqui descrita, caso se
                                    chegue &agrave; s&eacute;rie final (
                                    <strong>
                                        <u>99</u>
                                    </strong>
                                    ), a s&eacute;rie imediatamente superior
                                    ser&aacute; a{" "}
                                    <strong>
                                        <u>00</u>
                                    </strong>
                                    . Por outro lado, caso se chegue &agrave;
                                    s&eacute;rie inicial (
                                    <strong>
                                        <u>00</u>
                                    </strong>
                                    ), a s&eacute;rie imediatamente inferior
                                    ser&aacute; a{" "}
                                    <strong>
                                        <u>99</u>
                                    </strong>
                                    , sendo mantida sempre a regra aqui descrita
                                    para a identifica&ccedil;&atilde;o da
                                    s&eacute;rie contemplada - alternadamente
                                    para a s&eacute;rie imediatamente superior,
                                    ou, na impossibilidade desta, para a
                                    imediatamente inferior).
                                </p>
                                <p>
                                    <strong>12.</strong>{" "}
                                    <strong>
                                        CRIT&Eacute;RIOS DE
                                        DESCLASSIFICA&Ccedil;&Atilde;O E
                                        ISEN&Ccedil;&Atilde;O DE
                                        RESPONSABILIDADE:
                                    </strong>
                                </p>
                                <p>
                                    <strong>12.1.</strong> Todos os produtos
                                    cadastrados, conforme cadastro do
                                    c&oacute;digo de barras realizado pelos
                                    Participantes, ser&atilde;o validados pelas{" "}
                                    <strong>PROMOTORAS</strong> a qualquer tempo
                                    at&eacute; o momento da
                                    apura&ccedil;&atilde;o do resultado para
                                    verificar: <strong>(i)</strong> se o
                                    interessado &eacute; eleg&iacute;vel
                                    &agrave; participa&ccedil;&atilde;o;{" "}
                                    <strong>(ii)</strong> se os produtos
                                    cadastrados s&atilde;o regulares e respeitam
                                    as regras deste regulamento; e{" "}
                                    <strong>(iii)</strong> se as
                                    informa&ccedil;&otilde;es cadastradas
                                    s&atilde;o corretas e verdadeiras.
                                </p>
                                <p>
                                    <strong>12.2.</strong> Caso seja constatado
                                    que o Participante realizou o cadastro:{" "}
                                    <strong>(i)</strong> de forma incorreta,{" "}
                                    <strong>(ii)</strong> de produtos que sejam
                                    considerados inv&aacute;lidos ou seja
                                    constatado que o presente regulamento
                                    n&atilde;o foi integralmente respeitado, o
                                    participante ser&aacute; desclassificado e
                                    n&atilde;o far&aacute; jus ao recebimento
                                    dos pr&ecirc;mios desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>12.3.</strong> Os Participantes
                                    ser&atilde;o exclu&iacute;dos e
                                    desclassificados automaticamente da
                                    Promo&ccedil;&atilde;o, a qualquer momento,
                                    em caso de ind&iacute;cio de fraude, a
                                    crit&eacute;rio exclusivo das{" "}
                                    <strong>PROMOTORAS</strong>, tentativa ou
                                    fraude comprovada, manipula&ccedil;&atilde;o
                                    de resultados ou n&atilde;o preenchimento
                                    dos requisitos e das
                                    disposi&ccedil;&otilde;es previstas neste
                                    Regulamento e/ou em decorr&ecirc;ncia de
                                    informa&ccedil;&otilde;es incorretas ou
                                    equivocadas, de acordo com as regras deste
                                    Regulamento, podendo ainda responder por
                                    crime de falsidade ideol&oacute;gica ou
                                    documental.&nbsp;
                                </p>
                                <p>
                                    <strong>12.4.</strong> Ser&atilde;o
                                    considerados nulos e ser&atilde;o
                                    imediatamente desclassificados e impedidos
                                    de concorrer/receber aos pr&ecirc;mios desta
                                    Promo&ccedil;&atilde;o, os cadastros e
                                    atividades que possam ser caracterizadas
                                    como infra&ccedil;&atilde;o, fraude,
                                    tentativa de fraude, abuso ou, ainda, para a
                                    qual tenham sido utilizados quaisquer meios
                                    tecnol&oacute;gicos, sejam eles
                                    eletr&ocirc;nicos, inform&aacute;ticos,
                                    digital, rob&oacute;tico, repetitivo,
                                    autom&aacute;tico, mec&acirc;nico e/ou
                                    an&aacute;logo com intuito de
                                    reprodu&ccedil;&atilde;o autom&aacute;tica
                                    e/ou repetitiva de cadastros,
                                    id&ecirc;nticos ou n&atilde;o, o que
                                    importar&aacute; na nulidade tamb&eacute;m
                                    de todos os cadastros efetuados pelo
                                    Participante que tenha se utilizado de um
                                    dos referidos meios ou com um dos referidos
                                    fins, ainda que nem todos os cadastros
                                    tenham resultado do uso de tais meios e/ ou
                                    sido realizadas com tal finalidade.
                                </p>
                                <p>
                                    <strong>12.5.</strong> Nos casos de
                                    ind&iacute;cio de fraude, tentativa ou
                                    fraude comprovada na
                                    participa&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o,
                                    manipula&ccedil;&atilde;o de resultados, ou
                                    n&atilde;o preenchimento das
                                    disposi&ccedil;&otilde;es previstas neste
                                    Regulamento, o Participante contemplado
                                    ser&aacute; automaticamente desclassificado
                                    e n&atilde;o ter&aacute; direito ao
                                    recebimento do Pr&ecirc;mio podendo as{" "}
                                    <strong>PROMOTORAS,</strong> identificar um
                                    novo potencial contemplado, se at&eacute; o
                                    momento da divulga&ccedil;&atilde;o do
                                    contemplado, conforme regra de
                                    aproxima&ccedil;&atilde;o (item 11) desta
                                    Promo&ccedil;&atilde;o. Caso a
                                    Promo&ccedil;&atilde;o esteja encerrada, o
                                    valor do pr&ecirc;mio ser&aacute; recolhido
                                    como renda &agrave; Uni&atilde;o, no prazo
                                    de 10 (dez) dias da
                                    prescri&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>12.6.</strong> Considerando as
                                    caracter&iacute;sticas inerentes ao ambiente
                                    da Internet, as <strong>PROMOTORAS</strong>{" "}
                                    n&atilde;o se responsabilizam por
                                    interrup&ccedil;&otilde;es ou
                                    suspens&otilde;es de conex&atilde;o
                                    ocasionadas por caso fortuito ou de
                                    for&ccedil;a maior, e/ou a&ccedil;&otilde;es
                                    de terceiros, dos quais n&atilde;o possam
                                    exercer qualquer controle, bem como por
                                    cadastros perdidos, atrasados, incompletos,
                                    inv&aacute;lidos, extraviados ou
                                    corrompidos, os quais ser&atilde;o
                                    desconsiderados.
                                </p>
                                <p>
                                    <strong>12.7.</strong> O Participante
                                    contemplado isenta as{" "}
                                    <strong>PROMOTORAS</strong> de qualquer
                                    responsabilidade e/ou dano patrimonial e
                                    moral, ou incidentes causados, decorrente do
                                    uso indevido do pr&ecirc;mio, ou de
                                    quaisquer danos verificados, por motivo de
                                    caso fortuito ou for&ccedil;a maior, que
                                    possam impossibilitar o ganhador de usufruir
                                    seu Brinde.
                                </p>
                                <p>
                                    <strong>12.8.</strong> O Participante
                                    &eacute; o &uacute;nico respons&aacute;vel
                                    pelas informa&ccedil;&otilde;es e dados
                                    pessoais informados quando da
                                    realiza&ccedil;&atilde;o do cadastro pessoal
                                    junto &agrave;s <strong>PROMOTORAS</strong>,
                                    responsabilidade que abrange, tamb&eacute;m,
                                    a precis&atilde;o e a veracidade de tais
                                    informa&ccedil;&otilde;es e dados. Para fins
                                    de participa&ccedil;&atilde;o e entrega da
                                    premia&ccedil;&atilde;o ser&atilde;o
                                    considerados apenas os dados constantes no
                                    cadastro realizado junto &agrave;s{" "}
                                    <strong>PROMOTORAS</strong>. As{" "}
                                    <strong>PROMOTORAS</strong> est&atilde;o
                                    isentas de quaisquer responsabilidades em
                                    caso de incorre&ccedil;&atilde;o,
                                    desatualiza&ccedil;&atilde;o ou n&atilde;o
                                    veracidade das informa&ccedil;&otilde;es e
                                    dados informados pelo Participante, que
                                    poder&aacute; ser desclassificado.
                                </p>
                                <p>
                                    <strong>12.9.</strong> As{" "}
                                    <strong>PROMOTORAS</strong> bem como
                                    quaisquer das pessoas e/ou empresas por elas
                                    contratadas e que estejam, direta ou
                                    indiretamente, envolvidas em qualquer
                                    aspecto da Promo&ccedil;&atilde;o n&atilde;o
                                    se responsabilizar&atilde;o por eventuais
                                    preju&iacute;zos que os Participantes venham
                                    a sofrer em decorr&ecirc;ncia da
                                    Promo&ccedil;&atilde;o, oriundos de fatos
                                    alheios &agrave;s{" "}
                                    <strong>PROMOTORAS</strong> e/ou das pessoas
                                    e/ou empresas envolvidas na
                                    Promo&ccedil;&atilde;o. As{" "}
                                    <strong>PROMOTORAS</strong> n&atilde;o
                                    ser&atilde;o responsabilizada por
                                    inscri&ccedil;&otilde;es e/ou cadastros
                                    n&atilde;o efetivados por problemas na
                                    transmiss&atilde;o de dados no servidor do
                                    Participante, tais como: problemas de acesso
                                    &agrave; rede de Internet,
                                    interven&ccedil;&atilde;o de hackers,
                                    v&iacute;rus, manuten&ccedil;&atilde;o,
                                    queda de energia, falhas de software ou
                                    hardware, problemas operacionais com as
                                    empresas de telefonia que possam, direta ou
                                    indiretamente, afetar o acesso &agrave;
                                    Internet e, consequentemente, a
                                    participa&ccedil;&atilde;o na presente
                                    Promo&ccedil;&atilde;o, bem como por casos
                                    fortuitos ou de for&ccedil;a maior, em
                                    provedores de acessos dos Participantes ou
                                    ainda por falta de energia el&eacute;trica.
                                </p>
                                <p>
                                    <strong>12.9.1.</strong> As&nbsp;
                                    <strong>PROMOTORAS</strong> e/ou as pessoas
                                    e empresas envolvidas na
                                    Promo&ccedil;&atilde;o&nbsp;n&atilde;o
                                    ser&atilde;o respons&aacute;veis, ainda, no
                                    caso de vazamento/dissemina&ccedil;&atilde;o
                                    das imagens, nome, voz, textos e
                                    v&iacute;deos captados por terceiros, bem
                                    como fica isenta de responsabilidade pela
                                    dissemina&ccedil;&atilde;o dos
                                    v&iacute;deos, imagens, textos e todo e
                                    qualquer conte&uacute;do em qualquer canal
                                    de internet e m&iacute;dia digital e/ou
                                    social, tais como, mas n&atilde;o se
                                    limitando a <em>Youtube</em>,{" "}
                                    <em>Facebook</em>, <em>Instagram</em>, entre
                                    outros, que forem feitos por terceiros
                                    n&atilde;o autorizados durante ou
                                    ap&oacute;s o t&eacute;rmino da
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>12.10.</strong> As&nbsp;
                                    <strong>PROMOTORAS</strong> e/ou as pessoas
                                    e empresas envolvidas na
                                    Promo&ccedil;&atilde;o
                                    tamb&eacute;mn&atilde;o se
                                    responsabilizar&atilde;o por eventuais e/ou
                                    quaisquer preju&iacute;zos diretos ou
                                    indiretos que os consumidores participantes
                                    possam ter, oriundos da
                                    participa&ccedil;&atilde;o na
                                    Promo&ccedil;&atilde;o, da
                                    aceita&ccedil;&atilde;o do pr&ecirc;mio, ou
                                    ainda de situa&ccedil;&otilde;es que estejam
                                    fora do controle das&nbsp;
                                    <strong>PROMOTORAS</strong> e/ou das pessoas
                                    e empresas envolvidas na
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>12.11.</strong> Caso em raz&atilde;o
                                    do fornecimento de dados incompletos,
                                    incorretos ou desatualizados, as{" "}
                                    <strong>PROMOTORAS</strong> ficar&atilde;o
                                    impossibilitadas de contatar o Participante
                                    Potencial Contemplado, esta proceder&aacute;
                                    &agrave; imediata
                                    desclassifica&ccedil;&atilde;o do mesmo por
                                    inobserv&acirc;ncia das regras de
                                    participa&ccedil;&atilde;o estabelecidas
                                    neste Regulamento.
                                </p>
                                <p>
                                    <strong>12.12.</strong> Fica, desde
                                    j&aacute;, estabelecido, que a partir do
                                    momento da entrega do pr&ecirc;mio, as{" "}
                                    <strong>PROMOTORAS</strong> n&atilde;o se
                                    responsabilizar&atilde;o pela n&atilde;o
                                    utiliza&ccedil;&atilde;o do pr&ecirc;mio por
                                    parte do contemplado seja qual for o motivo.
                                </p>
                                <p>
                                    <strong>12.13.</strong> A responsabilidade
                                    da <strong>MANDAT&Aacute;RIA&nbsp;</strong>
                                    com os contemplados encerra-se no momento da
                                    entrega dos pr&ecirc;mios,&nbsp;os quais
                                    destinam-se aos contemplados e ser&atilde;o
                                    entregues em nome deles, livres e
                                    desembara&ccedil;ados de quaisquer
                                    &ocirc;nus, sendo vedada sua
                                    transfer&ecirc;ncia.&nbsp;
                                </p>
                                <p>
                                    <strong>12.14.</strong> Caso algum
                                    contemplado n&atilde;o seja localizado por
                                    meio dos dados por ele cadastrados nesta
                                    Promo&ccedil;&atilde;o, em raz&atilde;o de
                                    estarem tais dados incorretos, imprecisos,
                                    desatualizados ou equivocados, sua
                                    participa&ccedil;&atilde;o ser&aacute;
                                    desconsiderada a qualquer tempo, a
                                    crit&eacute;rio das{" "}
                                    <strong>PROMOTORAS</strong>, aplicando-se a
                                    regra de aproxima&ccedil;&atilde;o deste
                                    Regulamento (item 11).
                                </p>
                                <p>
                                    <strong>13.</strong>{" "}
                                    <strong>
                                        FORMA DE DIVULGA&Ccedil;&Atilde;O DO
                                        RESULTADO:
                                    </strong>
                                </p>
                                <p>
                                    <strong>13.1.</strong> O potencial
                                    contemplado ser&aacute; notificado por
                                    telefonema, WhatsApp ou e-mail (n&atilde;o
                                    obrigatoriamente nesta ordem)<em>,</em>{" "}
                                    conforme dados fornecidos para esta
                                    Promo&ccedil;&atilde;o
                                    (&ldquo;Notifica&ccedil;&atilde;o&rdquo;).
                                </p>
                                <p>
                                    <strong>13.1.1.</strong>Caso as{" "}
                                    <strong>PROMOTORAS</strong>, no prazo de 3
                                    (tr&ecirc;s) dias &uacute;teis contados do
                                    Efetivo Contato, n&atilde;o obtenham a
                                    resposta acerca da
                                    Notifica&ccedil;&atilde;o, o potencial
                                    contemplado poder&aacute; ser
                                    desclassificado, aplicando-se a regra de
                                    aproxima&ccedil;&atilde;o (item 11).
                                </p>
                                <p>
                                    <strong>13.1.1.1.</strong>{" "}
                                    <strong>Efetivo Contato</strong>: &eacute; a
                                    confirma&ccedil;&atilde;o de leitura do
                                    e-mail e/ou WhatsApp, bem como qualquer
                                    outra forma que comprove que o contato foi
                                    regularmente realizado pelas{" "}
                                    <strong>PROMOTORAS</strong> com o potencial
                                    contemplado, sendo responsabilidade deste
                                    acessar a caixa de spam, de modo a verificar
                                    eventuais e-mails enviados pelas{" "}
                                    <strong>PROMOTORAS</strong>, e verificar
                                    suas liga&ccedil;&otilde;es e mensagens
                                    regularmente.
                                </p>
                                <p>
                                    <strong>13.2.</strong> A
                                    divulga&ccedil;&atilde;o do efetivo
                                    Contemplado juntamente com o respectivo
                                    n&uacute;mero da sorte, se far&aacute; por
                                    meio do <em>Hotsite</em>, e em outros meios
                                    (incluindo, radiodifus&atilde;o e
                                    televis&atilde;o) definidos pelas{" "}
                                    <strong>PROMOTORAS,&nbsp;</strong>conforme
                                    seu exclusivo crit&eacute;rio,at&eacute; 1
                                    (um) ap&oacute;s cada
                                    apura&ccedil;&atilde;o, permanecendo tais
                                    informa&ccedil;&otilde;es dispon&iacute;veis
                                    por, pelo menos, 30 (trinta) dias, contados
                                    da data da divulga&ccedil;&atilde;o do
                                    Contemplado (apura&ccedil;&atilde;o).
                                </p>
                                <p>
                                    <strong>14.</strong>{" "}
                                    <strong>ENTREGA DOS PR&Ecirc;MIOS:</strong>
                                </p>
                                <p>
                                    <strong>14.1.</strong> Para fins de
                                    recebimento do pr&ecirc;mio, o interessado
                                    dever&aacute; confirmar e/ou apresentar a
                                    c&oacute;pia, no prazo de 3 (tr&ecirc;s)
                                    dias &uacute;teis, do CPF e RG (com
                                    &oacute;rg&atilde;o emissor e data de
                                    expedi&ccedil;&atilde;o), comprovante de
                                    resid&ecirc;ncia, com validade m&aacute;xima
                                    de 180 (cento e oitenta) dias, e-mail,
                                    telefones para contato (com DDD), documento
                                    fiscal e recibo de entrega do pr&ecirc;mio
                                    assinado.
                                </p>
                                <p>
                                    <strong>14.1.1.</strong>N&atilde;o
                                    ser&atilde;o considerados documentos e/ou
                                    informa&ccedil;&otilde;es apresentadas de
                                    forma irregular ou divergente do cadastro.
                                </p>
                                <p>
                                    <strong>14.2.</strong> Realizada a
                                    valida&ccedil;&atilde;o necess&aacute;ria, o
                                    Pr&ecirc;mio ser&aacute; entregue, no prazo
                                    de at&eacute; 30 (trinta) dias, sem qualquer
                                    &ocirc;nus ao Contemplado.&nbsp;
                                </p>
                                <p>
                                    <strong>14.3.</strong> No caso de recusa
                                    (comprovada) ou ren&uacute;ncia formal,
                                    poder&atilde;o as{" "}
                                    <strong>PROMOTORAS</strong> identificar novo
                                    potencial Contemplado do Pr&ecirc;mio em
                                    quest&atilde;o.&nbsp;
                                </p>
                                <p>
                                    <strong>15.</strong>{" "}
                                    <strong>
                                        DISPOSI&Ccedil;&Otilde;ES GERAIS:
                                    </strong>
                                </p>
                                <p>
                                    <strong>15.1.</strong>{" "}
                                    <strong>TRATAMENTO DE DADOS</strong>
                                </p>
                                <p>
                                    <strong>15.1.1.</strong>Ao realizar o
                                    Aceite, o Cliente Eleg&iacute;vel
                                    estar&aacute; automaticamente sujeito aos
                                    Termos de Uso e Pol&iacute;tica de
                                    Privacidade da Natura (dispon&iacute;vel
                                    em&nbsp;
                                    <a href="https://www.natura.com.br/politica-de-privacidade">
                                        https://www.natura.com.br/politica-de-privacidade
                                    </a>
                                    ), estando ciente, inclusive, que as{" "}
                                    <strong>PROMOTORAS</strong>, por si ou por
                                    meio de terceiros contratados, ter&atilde;o
                                    acesso aos dados cadastrados e dados
                                    relativos aos pedidos, para identificar se
                                    este est&aacute; regular e apto a concorrer
                                    aos pr&ecirc;mios ofertados nesta
                                    Promo&ccedil;&atilde;o.&nbsp;
                                </p>
                                <p>
                                    <strong>15.1.2.</strong>Realizado o Aceite,
                                    o Cliente Eleg&iacute;vel concorda com o
                                    compartilhamento de seus dados, inclusive
                                    pessoais, entre as{" "}
                                    <strong>PROMOTORAS</strong> e outras
                                    empresas envolvidas na
                                    realiza&ccedil;&atilde;o da presente
                                    Promo&ccedil;&atilde;o, para as finalidades
                                    de tratamento previstas neste Regulamento e,
                                    principalmente, para fins de
                                    operacionaliza&ccedil;&atilde;o e
                                    execu&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.1.3.</strong>Caso o Cliente
                                    Eleg&iacute;vel decida n&atilde;o mais
                                    participar desta Promo&ccedil;&atilde;o,
                                    dever&aacute; acionar o Canal de Atendimento
                                    Natura (&ldquo;SNAC&rdquo;), atrav&eacute;s
                                    do telefone 0800 762 88 72 ou pelo
                                    e-mail:&nbsp;
                                    <a href="mailto:snac@natura.net">
                                        snac@natura.net
                                    </a>{" "}
                                    e abrir um chamado sobre a sua
                                    inten&ccedil;&atilde;o de &ldquo;n&atilde;o
                                    participa&ccedil;&atilde;o&rdquo; (
                                    <em>opt-out</em>).&nbsp;
                                </p>
                                <p>
                                    <strong>15.1.4.</strong>O Cliente
                                    Eleg&iacute;vel est&aacute; ciente que, ao
                                    realizar o Cadastro e Aceite, alguns dos
                                    dados pessoais informados ser&atilde;o
                                    tratados pelas <strong>PROMOTORAS</strong>,
                                    incluindo o compartilhamento com parceiros
                                    envolvidos no desenvolvimento e
                                    execu&ccedil;&atilde;o da
                                    Promo&ccedil;&atilde;o, para as finalidades
                                    de operacionaliza&ccedil;&atilde;o,
                                    execu&ccedil;&atilde;o,
                                    divulga&ccedil;&atilde;o da pr&oacute;pria
                                    Promo&ccedil;&atilde;o e do Contemplado,
                                    entrega do pr&ecirc;mio, procedimentos de
                                    auditoria e, ainda, de
                                    fiscaliza&ccedil;&atilde;o, nos termos do
                                    item 15.12, e presta&ccedil;&atilde;o de
                                    contas junto &agrave; Secretaria de
                                    Pr&ecirc;mios e Apostas - SPA/MF, nos
                                    limites da legisla&ccedil;&atilde;o
                                    aplic&aacute;vel.
                                </p>
                                <p>
                                    <strong>15.1.5.</strong>As{" "}
                                    <strong>PROMOTORAS</strong> expressamente
                                    declaram que os dados obtidos em
                                    raz&atilde;o desta Promo&ccedil;&atilde;o
                                    n&atilde;o ser&atilde;o de qualquer forma
                                    comercializados ou cedidos, neste caso com
                                    finalidades distintas do aqui indicado, a
                                    terceiros, ainda que a t&iacute;tulo
                                    gratuito.
                                </p>
                                <p>
                                    <strong>15.1.6.</strong>O Cliente
                                    Eleg&iacute;vel que n&atilde;o concordar com
                                    o tratamento dos seus dados pessoais para as
                                    finalidades descritas neste Regulamento ou
                                    optarem por revogar a sua
                                    concord&acirc;ncia, n&atilde;o dar seu
                                    aceite ou dever&atilde;o deixar de
                                    participar desta Promo&ccedil;&atilde;o,
                                    conforme previsto no item 11.1.3.
                                </p>
                                <p>
                                    <strong>15.1.7.</strong>Caso o Cliente
                                    Eleg&iacute;vel possua alguma
                                    reclama&ccedil;&atilde;o, pedido ou outra
                                    solicita&ccedil;&atilde;o relacionada ao
                                    tratamento de seus dados pessoais, a
                                    qualquer momento poder&aacute; entrar em
                                    contato com a Natura, por meio da plataforma
                                    acess&iacute;vel por meio do seguinte&nbsp;
                                    <a href="https://privacyportal-br.onetrust.com/webform/00181faa-85e7-4785-848b-f12d02b3f614/6f7e1250-be9f-4b2c-8610-98afc44fb2c0">
                                        link
                                    </a>
                                    .&nbsp;<u>.</u>
                                </p>
                                <p>
                                    <strong>15.2.</strong>{" "}
                                    <strong>
                                        <u>N&atilde;o</u>
                                    </strong>{" "}
                                    podem participar esta
                                    Promo&ccedil;&atilde;o:
                                </p>
                                <p>
                                    <strong>a)</strong> Pessoas
                                    Jur&iacute;dicas;
                                </p>
                                <p>
                                    <strong>b)</strong> Pessoas f&iacute;sicas
                                    que n&atilde;o atenderem aos requisitos
                                    exigidos neste Regulamento;
                                </p>
                                <p>
                                    <strong>c)</strong> Os membros do corpo
                                    diretivo, conforme respectivos documentos
                                    societ&aacute;rios, e todos os empregados e
                                    colaboradores da{" "}
                                    <strong>MANDAT&Aacute;RIA</strong>;
                                </p>
                                <p>
                                    <strong>d)</strong> Todos os empregados e
                                    colaboradores da Ag&ecirc;ncia The Group e
                                    da assessoria jur&iacute;dica Focaccia,
                                    Amaral e Lamonica Sociedade de Advogados;
                                </p>
                                <p>
                                    <strong>e)</strong> Qualquer
                                    indiv&iacute;duo que n&atilde;o goze de sua
                                    capacidade legal.
                                </p>
                                <p>
                                    <strong>15.2.1.</strong>A
                                    verifica&ccedil;&atilde;o do disposto no
                                    item acima &eacute; de responsabilidade da{" "}
                                    <strong>PROMOTORA</strong>. Caso, por
                                    qualquer motivo, a despeito dos
                                    esfor&ccedil;os das{" "}
                                    <strong>PROMOTORA</strong> para impedir a
                                    participa&ccedil;&atilde;o de pessoas
                                    impedidas de participar da
                                    Promo&ccedil;&atilde;o, verifique-se que
                                    algum dos contemplados estava impedido de
                                    participar desta Promo&ccedil;&atilde;o ou
                                    ainda, verifique-se que o potencial
                                    contemplado n&atilde;o cumpriu com os
                                    requisitos e regras previstos neste
                                    Regulamento, este ser&aacute;
                                    desclassificado sendo devidamente informado
                                    sobre tal desclassifica&ccedil;&atilde;o,
                                    perdendo direito ao pr&ecirc;mio
                                    eventualmente distribu&iacute;do, o qual
                                    tornar-se-&aacute; imediatamente
                                    dispon&iacute;vel para nova
                                    distribui&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o, seguindo as regras
                                    de distribui&ccedil;&atilde;o dos brindes
                                    desta Promo&ccedil;&atilde;o. Caso a
                                    Promo&ccedil;&atilde;o esteja encerrada, o
                                    valor do pr&ecirc;mio ser&aacute; recolhido
                                    como renda &agrave; Uni&atilde;o.
                                </p>
                                <p>
                                    <strong>15.3.</strong> Caso o Participante
                                    n&atilde;o consiga acessar, se cadastrar
                                    e/ou participar, por quaisquer motivos, nos
                                    Canal de Participa&ccedil;&atilde;o da
                                    Promo&ccedil;&atilde;o, dever&aacute; entrar
                                    em contato com o SAC da
                                    Promo&ccedil;&atilde;o, por meio da Central
                                    SNAC no telefone 0800 708 28 66 de segunda a
                                    sexta das 08h &agrave;s 21h ou aos
                                    s&aacute;bados das 09h &agrave;s 15h, ou
                                    atrav&eacute;s do e-mail&nbsp;
                                    <a href="file:///C:/Users/ceuzebio/Downloads/snac@natura.net">
                                        snac@natura.net
                                    </a>
                                    , para que o problema seja solucionado. A
                                    resposta visando auxiliar o Participante
                                    ser&aacute; realizada em at&eacute; 24
                                    (vinte e quatro) horas, contados da data de
                                    contato com o SAC. Os Participantes ficam
                                    cientes de que as liga&ccedil;&otilde;es
                                    realizadas para o esclarecimento de
                                    d&uacute;vidas poder&atilde;o ser gravadas
                                    pela <strong>MANDAT&Aacute;RIA</strong>,
                                    aseu &uacute;nico e exclusivo
                                    crit&eacute;rio.&nbsp;
                                </p>
                                <p>
                                    <strong>15.4.</strong> O recibo da
                                    quita&ccedil;&atilde;o da
                                    obriga&ccedil;&atilde;o das{" "}
                                    <strong>PROMOTORAS</strong> e entrega de
                                    pr&ecirc;mio constituir&aacute; prova de
                                    entrega do pr&ecirc;mio e ser&aacute;
                                    mantido sob guarda das{" "}
                                    <strong>PROMOTORAS</strong> pelo prazo de 05
                                    (cinco) anos, contados do t&eacute;rmino da
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.5.</strong> A simples
                                    participa&ccedil;&atilde;o na presente
                                    Promo&ccedil;&atilde;o com o aceite aos
                                    termos deste Regulamento configura a
                                    aceita&ccedil;&atilde;o de todas as
                                    disposi&ccedil;&otilde;es do presente do
                                    Regulamento e configura
                                    autoriza&ccedil;&atilde;o para o recebimento
                                    de informa&ccedil;&otilde;es e
                                    comunica&ccedil;&otilde;es a respeito desta
                                    Promo&ccedil;&atilde;o, n&atilde;o cabendo
                                    ao Participante contemplado e/ou seus
                                    respons&aacute;veis/representantes legais
                                    discutir ou redefinir as
                                    condi&ccedil;&otilde;es e premissas da
                                    Promo&ccedil;&atilde;o ou do pr&ecirc;mio.
                                </p>
                                <p>
                                    <strong>15.6.</strong> Caso o Contemplado
                                    n&atilde;o tenha interesse em receber a sua
                                    premia&ccedil;&atilde;o, este dever&aacute;
                                    renunciar ao seu pr&ecirc;mio, devendo,
                                    neste caso, formalizar tal ren&uacute;ncia
                                    por meio da assinatura de documento formal,
                                    disponibilizado pelas{" "}
                                    <strong>PROMOTORAS</strong>.
                                </p>
                                <p>
                                    <strong>15.6.1.</strong>Em caso de
                                    ren&uacute;ncia formal do Cliente
                                    Participante inicialmente contemplado,
                                    poder&atilde;o as{" "}
                                    <strong>PROMOTORAS</strong>, caso haja tempo
                                    h&aacute;bil, distribuir tal pr&ecirc;mio em
                                    nova apura&ccedil;&atilde;o para identificar
                                    novo contemplado para o recebimento do
                                    Brinde remanescente.
                                </p>
                                <p>
                                    <strong>15.7.</strong> No <em>Hotsite</em>{" "}
                                    da Promo&ccedil;&atilde;o os interessados
                                    dever&atilde;o: (i) consultar este
                                    Regulamento e o certificado de
                                    autoriza&ccedil;&atilde;o emitido pela SPA;
                                    e (ii) esclarecer d&uacute;vidas e obter
                                    informa&ccedil;&otilde;es sobre esta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.8.</strong> A
                                    Promo&ccedil;&atilde;o ser&aacute; divulgada
                                    pelas<strong> PROMOTORAS </strong>por meio
                                    dos Canais Digitais, bem como entre outros
                                    canais a exclusivo crit&eacute;rio destas,
                                    tais como canais segmentados (<em>SMS</em>,{" "}
                                    <em>Push</em>,{" "}
                                    <em>E-mail Marketing, RCS</em> e{" "}
                                    <em>Smartmessage</em>), Digital (redes
                                    sociais, sites das Promotoras, dentro
                                    outros), e demais meios e m&iacute;dias de
                                    interesse das <strong>PROMOTORAS</strong>.
                                </p>
                                <p>
                                    <strong>15.9.</strong> O regulamento da
                                    Promo&ccedil;&atilde;o ser&aacute;
                                    disponibilizado no <em>Hotsite</em> da
                                    Promo&ccedil;&atilde;o, e a
                                    participa&ccedil;&atilde;o nesta
                                    Promo&ccedil;&atilde;o caracteriza a
                                    aceita&ccedil;&atilde;o total e
                                    irrevog&aacute;vel pelo Participante de
                                    todos os termos e condi&ccedil;&otilde;es
                                    descritos neste Regulamento.
                                </p>
                                <p>
                                    <strong>15.10.</strong> Caso o contemplado
                                    se encontre relativamente incapaz, nos
                                    termos do inciso II, III e IV do artigo
                                    4&ordm; do C&oacute;digo Civil (Lei
                                    10.406/2002), dever&aacute; ser representado
                                    por seus respons&aacute;veis legais para
                                    efetivo recebimento do pr&ecirc;mio, o qual,
                                    n&atilde;o obstante, ser&aacute; emitido em
                                    nome do relativamente incapaz, conforme o
                                    caso.
                                </p>
                                <p>
                                    <strong>15.11.</strong> Os Participantes
                                    contemplados e/ou seus
                                    respons&aacute;veis/representantes legais
                                    concordam em autorizar gratuitamente neste
                                    ato, o uso de seu nome, sua imagem, nome e
                                    som de voz, sem qualquer &ocirc;nus para as{" "}
                                    <strong>PROMOTORAS</strong>, para uso
                                    exclusivo na divulga&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o, por meio de
                                    cartazes, filmes e/ou spots, jingles e/ou
                                    vinhetas, bem como em qualquer tipo de
                                    m&iacute;dia e/ou pe&ccedil;as promocionais,
                                    impressas ou digitais, incluindo mas
                                    n&atilde;o se limitando a televis&atilde;o,
                                    r&aacute;dio, jornal, cartazes, materiais de
                                    comunica&ccedil;&atilde;o em pontos de
                                    venda, faixas, <em>outdoors</em>,
                                    mala-direta e na Internet, durante o
                                    per&iacute;odo de realiza&ccedil;&atilde;o
                                    desta Promo&ccedil;&atilde;o e pelo
                                    per&iacute;odo de 12 (doze) meses, contados
                                    da data da data de t&eacute;rmino desta
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.11.1.</strong> Os Participantes
                                    contemplados garantem que n&atilde;o
                                    h&aacute; terceiros detentores de direitos
                                    exclusivos sobre a exibi&ccedil;&atilde;o,
                                    publica&ccedil;&atilde;o,
                                    distribui&ccedil;&atilde;o,
                                    reprodu&ccedil;&atilde;o e/ou
                                    coloca&ccedil;&atilde;o em neg&oacute;cios
                                    de sua imagem, nome e voz. Consequentemente,
                                    os participantes contemplados
                                    manter&atilde;o as
                                    <strong> PROMOTORAS&nbsp;</strong>e/ou suas
                                    ag&ecirc;ncias de publicidade protegidas
                                    contra quaisquer queixas de responsabilidade
                                    levantadas por terceiros em
                                    rela&ccedil;&atilde;o a esta
                                    divulga&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.12.</strong> As
                                    autoriza&ccedil;&otilde;es descritas no item
                                    15.10 acima n&atilde;o implicam em qualquer
                                    obriga&ccedil;&atilde;o de
                                    divulga&ccedil;&atilde;o ou de pagamento de
                                    qualquer quantia adicional por parte das{" "}
                                    <strong>PROMOTORAS&nbsp;</strong>desde que
                                    estas n&atilde;o se utilizem de direitos dos
                                    ganhadores ap&oacute;s a
                                    expira&ccedil;&atilde;o do prazo mencionado.
                                    Caso desejarem, as{" "}
                                    <strong>PROMOTORAS&nbsp;</strong>e os
                                    contemplados, poder&atilde;o, de
                                    m&uacute;tuo acordo, firmar contratos
                                    espec&iacute;ficos para licen&ccedil;a de
                                    tais direitos por prazo superior a 12 (doze)
                                    meses contados da data de t&eacute;rmino da
                                    Promo&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.13.</strong> Os Participantes
                                    contemplados concordam que dever&atilde;o se
                                    abster de utilizar as marcas das{" "}
                                    <strong>PROMOTORAS </strong>sem a sua
                                    pr&eacute;via e expressa
                                    autoriza&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.14.</strong> O N&uacute;mero do
                                    Certificado de autoriza&ccedil;&atilde;o
                                    desta Promo&ccedil;&atilde;o constar&aacute;
                                    sempre neste regulamento e nos Canais
                                    Digitais, estando as{" "}
                                    <strong>PROMOTORAS&nbsp;</strong>dispensadas
                                    da aposi&ccedil;&atilde;o em todos os
                                    materiais de comunica&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.15.</strong> Presume-se que o
                                    Contemplado n&atilde;o tenha qualquer
                                    embara&ccedil;o fiscal, legal ou outro que o
                                    impe&ccedil;a de receber e/ou usufruir do
                                    pr&ecirc;mio distribu&iacute;do. Na
                                    eventualidade de quaisquer impedimentos, o
                                    Contemplado concorda em receber o
                                    pr&ecirc;mio por meio de carta compromisso,
                                    comprometendo-se com todas as
                                    regulariza&ccedil;&otilde;es
                                    necess&aacute;rias sinalizadas pelas{" "}
                                    <strong>PROMOTORAS</strong>. Neste caso,
                                    ap&oacute;s o recebimento da carta
                                    compromisso, o Contemplado compromete-se
                                    tamb&eacute;m a assinar o recibo de entrega
                                    de premia&ccedil;&atilde;o.
                                </p>
                                <p>
                                    <strong>15.16.</strong> Nos termos da
                                    legisla&ccedil;&atilde;o, n&atilde;o
                                    h&aacute; incid&ecirc;ncia de Imposto de
                                    Renda na Fonte sobre a
                                    premia&ccedil;&atilde;o, haja vista a
                                    modalidade de realiza&ccedil;&atilde;o desta
                                    Promo&ccedil;&atilde;o.&nbsp;
                                </p>
                                <p>
                                    <strong>15.17.</strong> Em cumprimento
                                    &agrave; determina&ccedil;&atilde;o da
                                    SPA/MF, as&nbsp;
                                    <strong>PROMOTORAS&nbsp;</strong>
                                    dever&atilde;o encaminhar ao
                                    &oacute;rg&atilde;o a lista de
                                    participantes, contendo os nomes e os
                                    respectivos N&uacute;meros da Sorte
                                    distribu&iacute;dos, ap&oacute;s o
                                    t&eacute;rmino do Per&iacute;odo de
                                    Participa&ccedil;&atilde;o e antes da
                                    extra&ccedil;&atilde;o da Loteria Federal.
                                </p>
                                <p>
                                    <strong>16.</strong>{" "}
                                    <strong>TERMO DE RESPONSABILIDADE:</strong>
                                </p>
                                <p>
                                    Poder&aacute; participar da
                                    promo&ccedil;&atilde;o qualquer consumidor
                                    que preencha os requisitos estipulados no
                                    regulamento da campanha autorizada;
                                </p>
                                <p>
                                    Os pr&ecirc;mios n&atilde;o poder&atilde;o
                                    ser convertidos em dinheiro; &Eacute; vedada
                                    a apura&ccedil;&atilde;o por meio
                                    eletr&ocirc;nico; Os pr&ecirc;mios
                                    ser&atilde;o entregues em at&eacute; 30 dias
                                    da data da apura&ccedil;&atilde;o/sorteio,
                                    sem qualquer &ocirc;nus aos contemplados
                                </p>
                                <p>
                                    Quando o pr&ecirc;mio sorteado, ganho em
                                    concurso ou conferido mediante vale-brinde,
                                    n&atilde;o for reclamado no prazo de cento e
                                    oitenta (180) dias, contados,
                                    respectivamente, da data do sorteio, da
                                    apura&ccedil;&atilde;o do resultado do
                                    concurso ou do t&eacute;rmino do prazo da
                                    promo&ccedil;&atilde;o, caducar&aacute; o
                                    direito do respectivo titular e o valor
                                    correspondente ser&aacute; recolhido, pela
                                    empresa autorizada, ao Tesouro Nacional,
                                    como renda da Uni&atilde;o, no prazo de
                                    quarenta e cinco (45) dias;
                                </p>
                                <p>
                                    Em caso de promo&ccedil;&otilde;es com
                                    participa&ccedil;&atilde;o de menor de
                                    idade, sendo este contemplado,
                                    dever&aacute;, no ato da entrega do
                                    pr&ecirc;mio, ser representado por seu
                                    respons&aacute;vel legal, &agrave;
                                    exce&ccedil;&atilde;o das
                                    promo&ccedil;&otilde;es comerciais
                                    realizadas por concession&aacute;ria ou
                                    permission&aacute;ria de servi&ccedil;o de
                                    radiodifus&atilde;o, nos termos do artigo
                                    1&ordm;-A, &sect; 3&ordm;, da Lei 5.768, de
                                    20 de dezembro de 1971;
                                </p>
                                <p>
                                    A divulga&ccedil;&atilde;o da imagem dos
                                    contemplados poder&aacute; ser feita
                                    at&eacute; um ano ap&oacute;s a
                                    apura&ccedil;&atilde;o da
                                    promo&ccedil;&atilde;o comercial;
                                </p>
                                <p>
                                    As d&uacute;vidas e controv&eacute;rsias
                                    oriundas de reclama&ccedil;&otilde;es dos
                                    participantes ser&atilde;o, primeiramente,
                                    dirimidas pela promotora, persistindo-as,
                                    estas dever&atilde;o ser submetidas &agrave;
                                    SPA/MF;
                                </p>
                                <p>
                                    Os &oacute;rg&atilde;os locais de defesa do
                                    consumidor receber&atilde;o as
                                    reclama&ccedil;&otilde;es devidamente
                                    fundamentadas;
                                </p>
                                <p>
                                    A presta&ccedil;&atilde;o de contas
                                    dever&aacute; ser realizada no prazo
                                    m&aacute;ximo de trinta dias ap&oacute;s a
                                    data de prescri&ccedil;&atilde;o dos
                                    pr&ecirc;mios sob pena de descumprimento do
                                    plano de distribui&ccedil;&atilde;o de
                                    pr&ecirc;mios;
                                </p>
                                <p>
                                    O regulamento dever&aacute; ser afixado em
                                    lugar de ampla visibilidade e se apresentar
                                    em tamanho e em grafia que viabilizem a
                                    compreens&atilde;o e
                                    visualiza&ccedil;&atilde;o por parte do
                                    consumidor participante da
                                    promo&ccedil;&atilde;o comercial;
                                </p>
                                <p>
                                    Al&eacute;m dos termos acima, a
                                    promo&ccedil;&atilde;o comercial
                                    dever&aacute; obedecer &agrave;s
                                    condi&ccedil;&otilde;es previstas na Lei
                                    n&ordm; 5.768, de 1971, no Decreto n&ordm;
                                    70.951, de 1972, Portaria SEAE n&ordm;
                                    7.638, de 2022, Portaria MF n&ordm; 67, de
                                    2017, Portaria SECAP n&ordm; 20.749 de 2000,
                                    e em atos que as complementarem.
                                </p>
                                <p>
                                    Para as modalidades "Sorteio" e "Assemelhada
                                    a Sorteio" a empresa dever&aacute; anexar a
                                    Lista de Participantes na aba
                                    "Apura&ccedil;&otilde;es", contendo nomes e
                                    n&uacute;meros da sorte distribu&iacute;dos,
                                    ap&oacute;s o t&eacute;rmino de cada
                                    per&iacute;odo de participa&ccedil;&atilde;o
                                    e antes da extra&ccedil;&atilde;o da
                                    Loteria. O arquivo dever&aacute; ser no
                                    formato .csv, .xls ou .zip e cada arquivo
                                    poder&aacute; ter at&eacute; 250 MB.
                                </p>
                                <p>
                                    A infring&ecirc;ncia &agrave;s
                                    cl&aacute;usulas do Termo de
                                    Responsabilidade e do Regulamento constituem
                                    descumprimento do plano de
                                    opera&ccedil;&atilde;o e ensejam as
                                    penalidades previstas no artigo 13 da Lei
                                    n&ordm;. 5.768, de 1971.
                                </p>
                                <p>DA PRESTA&Ccedil;&Atilde;O DE CONTAS</p>
                                <p>
                                    A presta&ccedil;&atilde;o de contas
                                    dever&aacute; ser realizada at&eacute; a
                                    data constante no cabe&ccedil;alho da
                                    promo&ccedil;&atilde;o no SCPC, conforme as
                                    regras estabelecidas na Portaria SEAE
                                    n&ordm; 7.638, de 18 de outubro de 2022. O
                                    vencimento do prazo para a
                                    presta&ccedil;&atilde;o de contas constitui
                                    em mora &agrave;s empresas promotoras. A
                                    n&atilde;o realiza&ccedil;&atilde;o da
                                    presta&ccedil;&atilde;o de contas at&eacute;
                                    a data de seu vencimento ensejar&aacute; a
                                    aplica&ccedil;&atilde;o de multa de 100%
                                    (cem por cento) incidente sobre a soma dos
                                    valores dos bens prometidos a t&iacute;tulo
                                    de premia&ccedil;&atilde;o e a
                                    proibi&ccedil;&atilde;o de realizar as
                                    opera&ccedil;&otilde;es de
                                    distribui&ccedil;&atilde;o gratuita de
                                    pr&ecirc;mios a t&iacute;tulo de propaganda,
                                    durante o prazo de 2 (dois) anos, contados
                                    da data limite da presta&ccedil;&atilde;o de
                                    contas, nos termos do art. 13 da Lei n&ordm;
                                    5.768, de 20 de dezembro de 1971. A
                                    fixa&ccedil;&atilde;o da multa poder&aacute;
                                    ser revista em grau de recurso
                                    administrativo, a ser apresentado conforme o
                                    art. 56 da Lei n&ordm; 9.784, de 29 de
                                    janeiro de 1999.
                                </p>
                                <p>
                                    <strong>ANEXO I</strong>
                                </p>
                                <p>
                                    <strong>
                                        LISTA DE PRODUTOS PARTICIPANTES
                                    </strong>
                                </p>
                                <div>
                                    <figure>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <strong>CV</strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <strong>
                                                                DESCRI&Ccedil;&Atilde;O
                                                            </strong>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136198</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON DISNEY ENCANTO
                                                            COLOGNE 150ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136199</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON DISNEY
                                                            PRINCESSS JASMINE
                                                            COLOGNE 150ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136202</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MICKEY MOUSE BAR
                                                            SOAP LOCAL PROJ BRA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136207</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MINNIE MOUSE COLOGNE
                                                            LATAM
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136208</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MINNIE BAR SOAPS
                                                            RELABEL 21 LATAM
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136210</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MICKEY COLONIA
                                                            REDESENHO 2020
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136216</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO CURLY HAIR
                                                            MIRABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136217</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO FROZEN
                                                            BRILHO
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136218</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO HIDRATACAO
                                                            ARIEL BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136219</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SHAMPOO SPIDER 2 EM
                                                            1 750ML BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136220</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CONDICIONADOR FROZEN
                                                            BRILHO BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136221</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CONDICIONADOR
                                                            HIDRATACAO ARIEL
                                                            BRAZIL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136225</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CREME PENTEAR
                                                            MIRABEL 150G
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136226</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            FROZEN HAIR GEL
                                                            RELABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136227</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON SPIDERMAN
                                                            COLOGNE 150ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136228</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON AVENGERS
                                                            COLOGNE 150ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136230</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COLONIA ARIEL 150ML
                                                            REDESENHO 2020
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136231</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COLONIA BRANCA DE
                                                            NEVE 150ML REDESENHO
                                                            2020
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136232</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CONDICIONADOR CACHOS
                                                            MIRABEL 200ML
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136286</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 2 POTES EMPILHA
                                                            AVENGERS MULTI COLOR
                                                            GROUP CAPITAO
                                                            AMERICA CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136287</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 2 POTES EMPILHA
                                                            AVENGERS MULTI COLOR
                                                            GROUP 2 PIECE(S)
                                                            HOMEM DE FERRO
                                                            CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136288</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 2 POTES EMPILHA
                                                            AVENGERS MULTI COLOR
                                                            GROUP HULK CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136330</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ESCOVA DE CABELO
                                                            DISNEY FEM
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136334</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ESCOVA DE CABELO
                                                            MARVEL MASC CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136364</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CANECA CUTE MICKEY E
                                                            AMIGOS MICKEY
                                                            CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136389</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE EMPILHAVEL
                                                            MINNIE CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>136410</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE EMPILHAVEL
                                                            MICKEY CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139120</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            TOALHA MICKEY DE
                                                            BANHO CHILDREN 2023
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139121</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            TOALHA MINNIE DE
                                                            BANHO 2023 CHILDREN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139127</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM CANUDO
                                                            MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>139129</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM CANUDO
                                                            MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148307</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            TOALHA DE BANHO
                                                            PERSONAGENS CHILDREN
                                                            SPIDERMAN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148310</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA SNACK FROZEN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148314</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE C COLHER MULTI
                                                            COLOR GROUP CHILDREN
                                                            1 PIECE(S) DISNEY
                                                            100
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148315</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE C COLHER TEENS
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148371</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 2 POTES MICKEY.
                                                            MULTI COLOR GROUP
                                                            CHILDREN 1 PIECE(S)
                                                            MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>148376</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CANECA CUTE MICKEY
                                                            2023 MULTI COLOR
                                                            GROUP CHILDREN 1
                                                            PIECE(S) MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>152149</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA STITCH</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155428</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA REDONDA 2
                                                            ANDARES FROZEN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155429</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA REDONDA 2
                                                            ANDARES SPIDERMAN
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155430</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA SPIDERMAN</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155431</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA FROZEN</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155437</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SABONETEIRA MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155438</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            SABONETEIRA MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155441</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA ESCOV DE
                                                            DENTES MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>155442</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA ESCOV DE
                                                            DENTES MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163412</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            GARRAFA
                                                            CAPIT&Atilde;O
                                                            AM&Eacute;RICA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163413</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            GARRAFA HOMEM DE
                                                            FERRO
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163414</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA HULK</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163417</p>
                                                    </td>
                                                    <td>
                                                        <p>GARRAFA MIRABEL</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163420</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA REDONDA
                                                            MIRABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163422</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            PORTA SNACK MIRABEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>163430</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CANECA SPIDERMAN
                                                            2024
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168360</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE PIPOCA C COPO
                                                            MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168364</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ALMOFADA DE
                                                            PESCO&Ccedil;O
                                                            MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168625</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            POTE PIPOCA C COPO
                                                            MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168628</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ALMOFADA DE
                                                            PESCO&Ccedil;O
                                                            MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168756</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT PIPOCA +
                                                            ALMOFADA MICKEY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>168757</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT PIPOCA +
                                                            ALMOFADA MINNIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169923</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            ORGANIZADOR MULTIUSO
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169924</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 3 POTES REDONDOS
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169925</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 3 POTRES
                                                            QUADRADOS
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>169926</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            CANECA DIVERTIDA
                                                            DIVERTIDAMENTE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170776</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT CAIXA
                                                            ORGANIZADORA STITCH
                                                            TIE DIE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170777</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 2 ORGANIZADORES
                                                            MULT STITCH Tie Dye
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170779</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            KIT 3 POTES REDONDOS
                                                            STITCH TIE DYE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170784</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA COROA
                                                            BRANCA DE NEVE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170785</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA COROA
                                                            ARIEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170787</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA
                                                            CAPIT&Atilde;O
                                                            AM&Eacute;RICA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170788</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            COPO COM TAMPA
                                                            PANTERA NEGRA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170789</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            BRANCA DE NEVE
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170790</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            ARIEL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170791</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            CAPIT&Atilde;O
                                                            AM&Eacute;RICA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>170793</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            MARMITA COM TRAVAS
                                                            PANTERA NEGRA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172511</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT BCA NEVE
                                                            COPO COROA POTE
                                                            TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172512</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT ARIEL COPO
                                                            COROA POTE TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172513</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT CAP AMERICA
                                                            COPO POTE TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>172514</p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            AVON KIT PANTERA
                                                            NEGRA COPO POTE
                                                            TRAVA
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </figure>
                                </div>
                            </div>
                        </StyleRegulamentoContent>
                    </StyleRegulamentoWrapper>
                </StyleWrapperContent>
                <Footer />
            </ContainerLarge>
        </StyleWrapperPage>
    );
};

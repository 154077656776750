import { useContext, useEffect } from "react";
import { GlobalContext } from "contexts/global-context";
import StyleModal, {
    StyleHeaderAuthModal,
    StyleTitleModal,
    StyleButtonClose,
    StyleContentModal,
    StyleContainerModal,
} from "./style";
import Form from "./form";

export default () => {
    const { openNewsletterModal, setOpenNewsletterModal } = useContext(GlobalContext);

    useEffect(() => {
        if (openNewsletterModal) {
            document.querySelector("body").classList.add("modal-open");
            localStorage.setItem("wasShowModalNewsletter", true);
        } else {
            document.querySelector("body").classList.remove("modal-open");
        }
    }, [openNewsletterModal])

    return (
        <StyleModal open={openNewsletterModal}>
            <div>
                <StyleContainerModal>
                    <StyleHeaderAuthModal>
                        <StyleButtonClose
                            onClick={() => setOpenNewsletterModal(false)}
                        >
                            <span>Fechar</span>
                            <img
                                src="/assets/img/vivamagia/icon-close.svg"
                                alt="Fechar"
                            />
                        </StyleButtonClose>
                        <StyleTitleModal>
                            Digite seu e-mail para receber informações sobre a promoção.
                        </StyleTitleModal>
                    </StyleHeaderAuthModal>
                    <StyleContentModal>
                        <Form closeModal={setOpenNewsletterModal} />
                    </StyleContentModal>
                </StyleContainerModal>
            </div>
        </StyleModal>
    );
};

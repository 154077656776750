import { useState, useEffect } from "react";
import Header from "components/header";
import api_privada from "./../../components/utils/api-privada";
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import {
    ContainerMedium,
    StyleWrapperInput,
    StyleTitlePageInternal,
    Loader,
} from "globalStyles";
import { menuList } from "components/utils/constants";
import StyleWrapperPage, {
    StyleSection,
    StyleFormWrapper,
    StyleCallbackResponse,
    StyleFormFlex,
} from "./style";
import Button from "components/button";
import Footer from "components/footer";
import { StyleSubtitleHome } from "pages/home/style";
import axios from 'axios';

export default () => {

    const searchParams = new URLSearchParams(window.location.search);
    const chanceId = searchParams.get("chanceId");

    const { status, setStatus, handleRequest } = useApiRequest();

    const [files, setFiles] = useState([null, null, null, null]);
    const [progressLoading, setProgressLoading] = useState([0, 0, 0, 0]);
    const [showLoadig, setShowLoading] = useState(false);

    useEffect(() => {
        if (!chanceId) {
            window.location.href = "/";
        }
    }, [chanceId]);

    const handleFileChange = (index, file) => {

        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);

    };

    const uploadFile = async (index, tipoDocumento) => {

        let file = files[index];

        const fileExtension = file.name.split('.').pop();

        const presignedUrlResponse = await api_privada.post(
            "/chance/presigned-url",
            { chanceId, tipoDocumento, contentType: files[index].type, extensao: fileExtension}
        );

        const uploadResponse = await axios.put(presignedUrlResponse.data.presignedUrl, files[index], {
            headers: {
                'Content-Type': files[index].type
            },
            onUploadProgress: (event) => {
                const newProgress = [...progressLoading];
                newProgress[index] = Math.round((event.loaded * 100) / event.total);
                setProgressLoading(newProgress);
            },
        });

        return uploadResponse.request.responseURL.split("?")[0];
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        setStatus({ ...status, disabled: true });

        if (files.some(file => file === null)) {
            setStatus({ ...status, disabled: false, error: true, messages: ["Por favor, selecione todos os arquivos antes de enviar."] });
            return;
        }

        const uploadPromises = [
            uploadFile(0, 0),
            uploadFile(1, 1),
            uploadFile(2, 2),
            uploadFile(3, 3),
        ];

        const [file01FullUrl, file02FullUrl, file03FullUrl, file04FullUrl] = await Promise.all(uploadPromises);

        const payload = {
            imagemDocumento: file01FullUrl,
            imagemDocumentoVerso: file02FullUrl,
            imagemComprovanteResidencia: file03FullUrl,
            imagemProduto: file04FullUrl,
        };

        handleRequest(
            () => api_privada.put(`/chance/confirmar-uploads/${chanceId}`, payload),
            null,
            "Sucesso. Seus documentos foram enviados. Aguarde a validação dos documentos para entrega do prêmio. Na aba 'Meus números' é possível acompanhar o status da validação."
        );
    };

    const renderFileInput = (index, label) => (
        <StyleWrapperInput key={index}>
            <label htmlFor={`file-${index}`}>
                {label}
            </label>
            <input
                type="file"
                id={`file-${index}`}
                placeholder="Anexar arquivo"
                onChange={(e) => handleFileChange(index, e.target.files[0])}
            />
            {showLoadig && (
                <p>Carregando: {progressLoading[index]}%</p>
            )}
        </StyleWrapperInput>
    );

    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <StyleSection>
                <ContainerMedium>
                    <StyleTitlePageInternal>
                        Envio de documentos
                    </StyleTitlePageInternal>
                    <StyleSubtitleHome>
                        Preencha o formulário abaixo para confirmar seus dados e receber o seu prêmio
                    </StyleSubtitleHome>
                    <StyleFormWrapper>
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <StyleFormFlex>
                                <div>
                                    {renderFileInput(0, "Frente do documento - RG ou CNH")}
                                    {renderFileInput(1, "Verso do documento")}
                                    {renderFileInput(2, "Comprovante de residência")}
                                    {renderFileInput(3, "Foto do produto")}
                                    <Button
                                        type="submit"
                                        theme="theme2"
                                        className="w-100 mt-3"
                                        disabled={status.disabled}
                                    >
                                        {status.disabled ? <Loader /> : "ENVIAR"}
                                    </Button>
                                </div>
                            </StyleFormFlex>
                            <StyleCallbackResponse>
                                <StatusMessage error={status.error} messages={status.messages} />
                            </StyleCallbackResponse>
                        </form>
                    </StyleFormWrapper>
                </ContainerMedium>
                <Footer />
            </StyleSection>
        </StyleWrapperPage>
    );
};

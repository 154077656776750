import { useState } from "react";
import InputMask from "react-input-mask";
import Header from "components/header";
import api_publica from "./../../components/utils/api-publica";
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import Accordeon from "components/accordeon";
import { ContainerMedium, StyleWrapperInput, Loader } from "globalStyles";
import { menuList } from "components/utils/constants";
import StyleWrapperPage, {
    StyleSection,
    StyleTitle,
    StyleAccordeonWrapper,
    StyleFormWrapper,
    StyleFormFlex,
} from "./style";
import Button from "components/button";
import Footer from "components/footer";

export default () => {
    const { status, handleRequest } = useApiRequest();

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [mensagem, setMensagem] = useState("");

    const handleSuccess = (response) => {};

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        handleRequest(
            () =>
                api_publica.post("/fale-conosco", {
                    nome,
                    email,
                    telefone,
                    mensagem,
                }),
            handleSuccess,
            "Sucesso! Sua mensagem foi enviada."
        );
    };

    const faqQuestions = [
        {
            title: "Qual o período de participação da promoção?",
            content:
                "O período de participação da promoção é de 06 de setembro de 2024 a 30 de novembro de 2024.",
        },
        {
            title: "Quem pode participar da promoção?",
            content:
                "Podem participar todas as pessoas físicas, com no mínimo 18 (dezoito) anos de idade, residentes e domiciliadas em território nacional.",
        },
        {
            title: "Como faço para participar da promoção?",
            content: (
                <>
                    <p>Participar é bem fácil! Olha só</p>
                    <p>
                        1) Compre um produto da linha infantil Disney Casa &
                        Estilo dos ciclos 15/16.2024, durante o período de 06/09
                        a 11/11/2024.
                    </p>
                    <p>
                        2) Cadastre-se na promoção pelo site
                        www.vivamagiacasaestilo.com.br
                    </p>
                    <p>3) Cadastre o código de barras do produto adquirido.</p>

                    <p>
                        Pronto! Agora é só torcer. Você tem a chance de ganhar
                        prêmios exclusivos da Disney e ainda concorrer
                        instantaneamente a 10 mil produtos Disney.
                    </p>
                </>
            ),
        },
        {
            title: "Como faço para cadastrar o produto da linha infantil Disney Casa & Estilo?",
            content:
                "Você pode cadastrar o produto adquirido pelo site www.vivamagiacasaestilo.com.br em “CADASTRAR PRODUTO”, basta digitar o código de barras de 13 dígitos que está na embalagem do produto.",
        },
        {
            title: "Quais são os produtos participantes desta Promoção?",
            content: (
                <p>
                    Todos os produtos da linha infantil Disney Casa & Estilo dos
                    ciclos 15/16.2024. <a href="/regulamento">Clique aqui</a> e
                    confira no regulamento a listagem completa dos produtos
                    participantes.
                </p>
            ),
        },
        {
            title: "Como faço para acionar o premiador instantâneo e concorrer na hora aos produtos Disney? ",
            content:
                "É fácil! Basta acessar o site da promoção, realizar o login com e-mail e senha, e clicar em “PREMIADOR INSTANTÂNEO”. Lembre-se: só é possível acionar o premiador instantâneo após concluir o cadastro do produto adquirido.",
        },
        {
            title: "Quantas chances de ganhar eu tenho ao cadastrar um produto da linha infantil Disney Casa & Estilo?",
            content:
                "Cada produto cadastrado oferece uma chance de ganhar um prêmio na hora e um número da sorte para participar do sorteio dos prêmios exclusivos. Ah! Cada participante pode cadastrar até 3 produtos para concorrer.",
        },
        {
            title: "Como sei que tenho números da sorte e chances disponíveis na premiação instantânea? ",
            content:
                "Os números da sorte e chances na premiação instantânea serão atribuídos a partir do cadastro dos produtos e estarão disponíveis para consulta em “MEUS NÚMEROS” no site da promoção.",
        },
        {
            title: "Quais são os prêmios da Promoção e como serão distribuídos?",
            content:
                "Concorra a prêmios exclusivos da Disney entregues toda semana por meio de sorteios. Além disso, aproveite a chance de ganhar instantaneamente um dos 10 mil produtos Disney disponíveis na premiação instantânea!",
        },
        {
            title: "Preciso guardar a embalagem do produto da linha Disney Casa & Estilo cadastrado?",
            content:
                "Sim. Você precisa guardar todas as embalagens dos produtos que cadastrou, porque podemos pedir esses documentos, a qualquer momento, para validar a sua participação. A não apresentação da embalagem pode causar a sua desclassificação, se for um premiado.",
        },
        {
            title: "Posso cadastrar o mesmo produto mais de uma vez?",
            content:
                "Não. Cada produto poderá ser cadastrado somente 1 (uma) única vez.",
        },
        {
            title: "Qual a data de divulgação dos premiados na premiação instantânea?",
            content:
                "A divulgação da relação dos premiados será no site em até 30 dias corridos após o término da promoção. ",
        },
        {
            title: "Fui premiado na premiação instantânea. E agora?",
            content: (
                <>
                    <p>
                        Após a conclusão da premiação, você será direcionado
                        para finalizar as informações e garantir o seu prêmio.
                        Basta confirmar o endereço cadastrado e fazer o upload
                        dos documentos necessários para validação.
                    </p>
                    <p>
                        Pronto! Assim que os documentos forem validados, o
                        prêmio será entregue no endereço cadastrado em até 30
                        dias.
                    </p>
                    <p>
                        Fique de olho! O status da validação dos seus documentos
                        será atualizado em 'MEUS NÚMEROS DA SORTE'."
                    </p>
                </>
            ),
        },
        {
            title: "Quando e como será realizado o sorteio?",
            content:
                "Os sorteios serão realizados através da Loteria Federal nas seguintes datas: 14/09/2024, 21/09/2024, 28/09/2024, 05/10/2024, 16/10/2024, 19/10/2024, 26/10/2024, 06/11/2024, 09/11/2024, 16/11/2024, 23/11/2024 e 07/12/2024.",
        },
        {
            title: "Como será feita a divulgação dos premiados do sorteio?",
            content:
                "A divulgação dos premiados ocorrerá no site www.vivamagiacasaestilo.com.br  na tela ganhadores. ",
        },
        {
            title: "Ainda tenho dúvidas sobre a Promoção. Com quem posso falar? ",
            content:
                "Você pode entrar em contato por aqui (no formulário no final da página), pela Central de Atendimento da promoção no telefone 0800 708 28 66 de segunda a sexta das 08h às 21h ou aos sábados das 09h às 15h, ou através do e-mail snac@natura.net",
        },
    ];
    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <StyleSection>
                <ContainerMedium>
                    <StyleTitle>Perguntas frequentes</StyleTitle>
                    <StyleAccordeonWrapper>
                        {faqQuestions.map((item, index) => (
                            <Accordeon
                                title={item.title}
                                groupName="faqquestion"
                                index={index}
                                key={`faqquestion${index}`}
                            >
                                {item.content}
                            </Accordeon>
                        ))}
                    </StyleAccordeonWrapper>
                    <StyleTitle>Fale conosco</StyleTitle>
                    <StyleFormWrapper>
                        <form
                            autoComplete="off"
                            onSubmit={(evt) => handleSubmit(evt)}
                        >
                            <StyleFormFlex>
                                <div>
                                    <StyleWrapperInput>
                                        <label htmlFor="name">Nome</label>
                                        <input
                                            placeholder="Insira seu nome"
                                            id="name"
                                            name="name"
                                            onChange={(e) => {
                                                setNome(e.target.value);
                                            }}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            placeholder="Insira seu e-mail"
                                            type="email"
                                            id="email"
                                            name="email"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="telefone">
                                            Celular
                                        </label>
                                        <InputMask
                                            placeholder="Somente números"
                                            id="phone"
                                            mask="(99) 99999-9999"
                                            name="telefone"
                                            onChange={(e) => {
                                                setTelefone(e.target.value);
                                            }}
                                            required
                                        />
                                    </StyleWrapperInput>
                                </div>
                                <div>
                                    <StyleWrapperInput>
                                        <label htmlFor="message">
                                            Mensagem
                                        </label>
                                        <textarea
                                            placeholder="Deixe sua mensagem"
                                            rows="3"
                                            id="message"
                                            name="message"
                                            onChange={(e) => {
                                                setMensagem(e.target.value);
                                            }}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <Button
                                        type="submit"
                                        className="w-100 mt-3"
                                        disabled={status.disabled}
                                        theme="theme2"
                                    >
                                        {(status.disabled) ? <Loader /> : "Enviar"}
                                    </Button>
                                </div>
                            </StyleFormFlex>

                            <StatusMessage
                                error={status.error}
                                messages={status.messages}
                            />
                        </form>
                    </StyleFormWrapper>
                    <Footer />
                </ContainerMedium>
            </StyleSection>
        </StyleWrapperPage>
    );
};

import styled from "styled-components";
import { colorToken } from "components/utils/constants";
import breakpoint from "components/utils/responsive";
export const ReturnTheme = (theme) => {
    const objThemes = {
        theme1: {
            background: colorToken.black,
            linkColor: colorToken.white,
            linkColorHover: colorToken.color2,
        },
        theme2: {
            background: colorToken.color1,
            linkColor: colorToken.color2,
            linkColorHover: colorToken.color3,
        },
        theme3: {
            background: colorToken.color2,
            linkColor: colorToken.white,
            linkColorHover: colorToken.color1,
        },
    };

    return objThemes[theme] || objThemes.theme1;
};

export const StyleNav = styled.nav`
    transition: all 0.2s;
    position: relative;
    z-index: 1;
    @media ${breakpoint.lg} {
        display: flex;
        height: auto;
        position: static;
        background-color: transparent;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px 16px;
        position: relative;
        z-index: 1;
        max-width: 1324;
        margin: 0 auto
    }
`;

export const StyleMenu = styled.ul`
    margin: 0;
    position: fixed;
    padding: 0;
    list-style: none;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    height: ${({ showMenu }) => (showMenu ? "100vh" : "0vh")};
    transition: height linear 0.4s;
    z-index: 2;
    @media ${breakpoint.lg} {
        display: flex;
        height: auto;
        position: static;
        background-color: transparent;
        align-items: center;
        justify-content: space-evenly;
    }
`;

export const StyleMenuItem = styled.li`
    border: 0;
    border-bottom-width: 1px;
    border-style: solid;
    margin: 0 8px;
    a, span {
        font-size: 2.2rem;
        text-decoration: none;
        line-height: 56px;
        display: block;
        text-align: center;
        font-weight: bold;
    }
    @media ${breakpoint.lg} {
        margin: 0 16px;
        a, span {
            display: flex;
            height: 69px;
            line-height: 22px;
            align-items: center;
            font-size: 1.6rem;
        }
    }
    @media ${breakpoint.xl} {
        a, span {
            font-size: 1.8rem;
        }
    }
`;

export const StyleOptionsMobile = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
    & > div:first-of-type {
        display: flex;
        justify-content: flex-start;
        margin-right: 12px;
    }
    & > div:last-of-type {
        padding-right: 8px;
        text-align: center;
        margin-left: auto;
        display: flex;
        flex: 1;
        justify-content: flex-end;
        button,
        a {
            width: auto;
            margin-left: 8px;
        }
    }

    @media ${breakpoint.lg} {
        display: none;
    }
`;

export const StyleButtonHamburger = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    width: 32px;
    margin-left: 8px;
    .line {
        fill: none;
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
            stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }
    .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
    }
    .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
    }
    &.opened .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
    }
    &.opened .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
    }
    &.opened .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
    }
`;

export const StyleHeader = styled.header`
    /* &:before {
        background: linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%);
        height: 150px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        content: " ";
        display: block;
        z-index: 1;
        pointer-events: none;
    } */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    transition: all 0.2s;

    &.is-sticky{
        background-color: rgba(0,0,0,0.8);
        ${StyleNav}{
            @media ${breakpoint.lg} {
                padding: 0 16px;
            }
        }
    }

    ${StyleOptionsMobile} {
    }
    ${StyleMenu} {
        background-color: rgba(0, 0, 0, 0.9);
        @media ${breakpoint.lg} {
            background-color: transparent;
        }
    }
    ${StyleMenuItem} {
        border-bottom-color: #666;
        a {
            color: ${colorToken.white};
        }
        @media ${breakpoint.lg} {
            height: 70px;
            border-bottom-color: transparent;
            a {
                color: ${({ theme }) => ReturnTheme(theme).linkColor};
                &:hover {
                    color: ${({ theme }) => ReturnTheme(theme).linkColorHover};
                }
            }
        }
    }
    ${StyleButtonHamburger} {
        .line {
            stroke: ${({ theme }) => ReturnTheme(theme).linkColor};
        }
    }
    @media ${breakpoint.lg} {
        &:before {
            height: 300px;
        }
    }
`;

export const StyleNavActions = styled.div`
    display: none;
    @media ${breakpoint.lg} {
        display: block;
    }
`;

export const StyleButtonActions = styled.button`
    background-color: ${colorToken.color1};
    border: 0;
    padding: 6px 12px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 1.35rem;
    display: inline-block;
    margin-left: 8px;
    text-decoration: none;
    color: ${colorToken.white};
    img {
        margin-right: 8px;
        width: 20px;
    }
    @media ${breakpoint.lg} {
        font-size: 1.6rem;
    }
`;

export const StyleLogoMobile = styled.img`
    display: flex;
    justify-content: center;
    height: 30px;
    @media ${breakpoint.lg} {
        display: none;
    }
`;

export const StyleLogoDesktop = styled.img`
    display: none;
    @media ${breakpoint.lg} {
        display: inline-block;
    }
`;
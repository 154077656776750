import styled from 'styled-components';
import breakpoint from 'components/utils/responsive';

export default styled.div`
    max-height: ${({ open }) => (open ? "auto" : "0")};
    overflow-y: hidden;
    transition: max-height linear 0.2s;

    @media ${breakpoint.md} {

    }

    @media ${breakpoint.xl} {

    }

`;

export const StyleFlexForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    & > div {
        width: 100%;
    }

    @media ${breakpoint.lg} {
        flex-direction: row;
        column-gap: 36px;
        padding: 0 32px;
        & > div {
            width: 100%;
        }
    }
`;

export const StyleWrapperInput = styled.div`

`
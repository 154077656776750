import styled from "styled-components";
import breakpoint from "components/utils/responsive";
import { colorToken } from "components/utils/constants";

export default styled.section`
    min-height: 100vh;
    padding-top: 70px;
	
    &:before{
        background-image: url("/assets/img/vivamagia/bg-internas.jpg");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0px;
        left: 0px;
        content: " ";
        display: block;
        z-index: 1;
        pointer-events: none;
    }

    @media ${breakpoint.md} {
		padding-top: 90px;
    }

    @media ${breakpoint.lg} {
        /* .homeflex {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 70px);
        } */
    }
`;

export const StyleWrapperContent = styled.div`
    padding: 36px 16px 0;
    position: relative;
    z-index: 2;
    @media ${breakpoint.lg} {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
    }
   
`;

export const StyleSubtitleHome = styled.div`
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    color: ${colorToken.white};
    margin-top: 36px;
    margin-bottom: 12px;
    @media ${breakpoint.lg} {
        font-size: 3.4rem;
        line-height: 124%;
    }
`;

export const StyleImageTitleHome = styled.img`
    max-width: 510px;
`;

export const StyleImgParticipar = styled.img`
    width: 100%;
    max-width: 400px;
`;

export const StyleKeyPhrase = styled.div`
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    color: ${colorToken.white};
    margin-bottom: 32px;
    @media ${breakpoint.lg} {
        max-width: 700px;
        font-size: 3.4rem;
        font-weight: bold;
        text-align: left;
        margin: 0;
        line-height: 124%;
    }
`;

export const StyleImageHashtag = styled.img`
    max-width: 710px;
    width: 100%;
`;

export const StyleFlexForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    & > div {
        width: 100%;
    }

    @media ${breakpoint.lg} {
        flex-direction: row;
        column-gap: 36px;
        padding: 0 32px;
        & > div {
            width: 100%;
        }
    }
`;

export const colorToken = {
    color1: "#E5004B",
    color2: "#E5004B",
    color3: "#E51B24",
    white: "white",
    black: "rgb(0,0,0)",
};

export const APIS = {
    public: 'https://k7m7c8qigb.execute-api.us-east-1.amazonaws.com/prod/api',
    private: 'https://k7m7c8qigb.execute-api.us-east-1.amazonaws.com/prod/api/private-endpoint'
}

export const settingsTheme = {
    baseUnit: "4",
};

export const menuList = [
    {
        link: "#comoparticipar",
        title: "Como Participar",
        externalLink: false,
    },
    {
        link: "#premios",
        title: "Prêmios",
        externalLink: false,
    },
    {
        link: "#ganhadores",
        title: "Ganhadores",
        externalLink: false,
    }
];
import styled from 'styled-components';
import { colorToken } from "components/utils/constants";
import breakpoint from 'components/utils/responsive';

export default styled.div`
    overflow-y: auto;
    transition: max-height linear 0.2s;
    padding: 0 12px;

    button.btn-transparent{
        color: ${colorToken.color1};
    }
    @media ${breakpoint.md} {

    }

    @media ${breakpoint.xl} {

    }


`;

export const StyleWrapperInput = styled.div`

`
import styled from 'styled-components';
import breakpoint from 'components/utils/responsive';

export default styled.div`
    max-height: ${({ open }) => (open ? "calc(100vh - 70px)" : "0")};
    overflow-y: auto;
    transition: max-height linear 0.4s;
    @media ${breakpoint.md} {

    }

    @media ${breakpoint.xl} {

    }

`;

export const StyleWrapperInput = styled.div`

`
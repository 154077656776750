import styled from 'styled-components';
import { colorToken } from "components/utils/constants";
import breakpoint from 'components/utils/responsive';
import {
    StyleWrapperInput,
    ContainerMedium,
    ContainerSmall
} from "globalStyles";

export default styled.div`
    max-height: ${({ open }) => (open ? "auto" : "0")};
    overflow-y: hidden;
    transition: max-height linear 0.2s;

    ${ContainerSmall}{
        max-width: 725px;
    }

    @media ${breakpoint.md} {

    }

    @media ${breakpoint.xl} {

    }

`;

export const StyleSuccessLoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 34px 0;
    align-items: center;
    > div:first-of-type {
            margin-bottom: 16px;
    }
    @media ${breakpoint.md} {
        flex-direction: row;
        justify-content: center;
        > div:first-of-type {
            margin-bottom: 0;
            margin-right: 32px;
        }
        > div:last-of-type {}
        margin: 64px 0;
    }
`;

export const StyleTitleModal = styled.div`
    font-size: 28px;
    color: ${colorToken.color1};
    font-weight: 900;
    @media ${breakpoint.md} {
        font-size: 48px;
        line-height: 62px;
    }
`

export const StyleLegalText = styled.div`
    font-size: 12px;
    color: ${colorToken.color1};
`
import { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Button from "components/button";
import InputMask from "react-input-mask";
import Header from "components/header";
import api_privada from './../../components/utils/api-privada';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import { GlobalContext } from "contexts/global-context";
import { menuList } from "components/utils/constants";
import {
    StyleWrapperContent,
    StyleWrapperCopyrights,
    StyleSubtitleHome,
    StyleWrapperPinkBackground,
    StyleWrapperHomePage,
    StyleTitle,
    StyleComoParticiparWrapper,
    StyleWrapperBlueBackground,
    StylePremiosCard,
    StylePremiosSlide,
    StylePremiosWrapperContent,
    StyleCard,
    StyleTitleCard,
    StyleGanhadoresWrapper,
} from "./style";
import { ContainerLarge, StyleWrapperInput, Loader } from "globalStyles";
import Text from "components/footer/text";
import api_publica from "components/utils/api-publica";
export default () => {
    const { setOpenAuthModal, setOpenNewsletterModal } = useContext(GlobalContext);
    const { status, handleRequest } = useApiRequest();
    const [documento, setDocumento] = useState("");
    const [premiado, setPremiado] = useState(false);
    const token = localStorage.getItem("accessToken") || false;
    const cacheOpenedNewsletter = Boolean(localStorage.getItem("wasShowModalNewsletter"));
    
   

    const handleSuccess = (response) => {
        const { premiado } = response.data;
        setPremiado(premiado);
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        
        handleRequest(
            () => api_publica.get(`/participante/ganhadores/${documento}`),
            handleSuccess,
            "Busca feita com sucesso!"
        );
    };

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        variableWidth: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true 
    };
    useEffect(() => {
        if(!cacheOpenedNewsletter) {
            setOpenNewsletterModal(true);
        }        
    },[setOpenNewsletterModal])
    return (
        <>
            <StyleWrapperHomePage id="start">
                <Header menuList={menuList} />
                {/* <img className="desk-kv" src="/assets/img/vivamagia/kv-home-desktop.jpg" alt="Viva a magia" /> */}
                <img
                    className="mob-kv"
                    src="/assets/img/vivamagia/kv-home-mob-tablet.jpg"
                    alt="Viva a magia"
                />
                <StyleWrapperCopyrights className="homerights">
                    * Das páginas 06 a 27 da Revista Casa & Estilo e das páginas
                    126 a 131 da Revista de Beleza. **Enquanto durarem os ciclos
                    15 e 16. C.A SPA/MF em www.vivamagiacasaestilo.com.br.
                    Imagens ilustrativas.
                </StyleWrapperCopyrights>
            </StyleWrapperHomePage>
            <StyleWrapperPinkBackground id="comoparticipar">
                <ContainerLarge id="start" className="homeflex">
                    <StyleWrapperContent>
                        <StyleTitle>Como participar</StyleTitle>
                        <StyleComoParticiparWrapper>
                            <div>
                                <img
                                    src="/assets/img/vivamagia/comoparticipar-01.png"
                                    alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                />
                            </div>
                            <div>
                                <img
                                    src="/assets/img/vivamagia/comoparticipar-02.png"
                                    alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                />
                            </div>
                            <div>
                                <img
                                    src="/assets/img/vivamagia/comoparticipar-03.png"
                                    alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                />
                            </div>
                        </StyleComoParticiparWrapper>
                        <StyleWrapperInput className="text-center mb-5">
                            {token && (
                                <>
                                    <Button
                                        as="a"
                                        href="/cadastrar-produto"
                                        theme="theme1"
                                    >
                                        CADASTRAR PRODUTO
                                    </Button>
                                </>
                            )}
                            {!token && (
                                <>
                                    <Button
                                        theme="theme1"
                                        onClick={() => setOpenAuthModal(true)}
                                    >
                                        CADASTRAR PRODUTO
                                    </Button>
                                </>
                            )}
                        </StyleWrapperInput>
                    </StyleWrapperContent>

                    <StyleWrapperCopyrights></StyleWrapperCopyrights>
                </ContainerLarge>
            </StyleWrapperPinkBackground>
            <StyleWrapperBlueBackground id="premios">
                <>
                    <StylePremiosWrapperContent>
                        <StyleTitle>Prêmios*</StyleTitle>
                        <Slider {...settings}>
                            <div>
                                <StylePremiosSlide>
                                    <div>
                                        <StylePremiosCard>
                                            <img
                                                src="/assets/img/vivamagia/premios-01.png"
                                                alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                            />
                                        </StylePremiosCard>
                                        <p>Caneca Disney Ariel</p>
                                    </div>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios-02.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Pelúcia Stitch Lilo</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios-03.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>
                                        Pipoqueira Elétrica <br />
                                        Mallory Disney Mickey
                                    </p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios-04.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Garrafa 3D Donald</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios-05.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Toalha Avengers</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/sorteio/maletacolorir.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>
                                        Maleta Para Colorir
                                        <br /> Disney Mickey
                                    </p>
                                </StylePremiosSlide>
                            </div>
                            {/* <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/sorteio/maladeviagemstitchh.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Mala De Viagem<br/>Stitch Disney</p>
                                </StylePremiosSlide>
                            </div> */}
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/sorteio/tabletMultiMickey.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Tablet Multi Mickey</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1OrganizadorClutchBrancadeNeveDisney.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>
                                        Organizador Clutch
                                        <br />
                                        Branca de Neve
                                    </p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umOrganizadorClutchTiana.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>
                                        Organizador Clutch
                                        <br />
                                        Tiana
                                    </p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umPoteComTampaMinnie.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>
                                        Pote Com Tampa
                                        <br />
                                        Minnie
                                    </p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umCopoSimplesFemDisneyMoana.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Copo Disney Moana</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umaMarmitaComTravasPequenaSereia2023.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Marmita Pequena Sereia</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umCopoCanudoQuadradoCinderela.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Copo Quadrado Cinderela</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umPoteQuebraCabecaSpiderChildrenPink.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Pote Quebra Cabeca Spider</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umaGarrafaFogueteLightyear.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Garrafa Foguete Lightyear</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umaGarrafaCCompart360mlFemChildren.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>Garrafa Minnie</p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umaEscovaDeDenteVentosaMickeyChildren.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>
                                        Escova De Dente
                                        <br />
                                        Mickey
                                    </p>
                                </StylePremiosSlide>
                            </div>
                            <div>
                                <StylePremiosSlide>
                                    <StylePremiosCard>
                                        <img
                                            src="/assets/img/vivamagia/premios/1umKit2PotesComOrelhasMinnie.png"
                                            alt="Compre Os produtos da linha Infantil Disney Casa & Estilo"
                                        />
                                    </StylePremiosCard>
                                    <p>
                                        Kit 2 Potes Com <br />
                                        Orelhas Minnie
                                    </p>
                                </StylePremiosSlide>
                            </div>
                        </Slider>
                    </StylePremiosWrapperContent>

                    <StyleWrapperCopyrights>
                        * Consulte o regulamento para obter informações
                        detalhadas acerca dos prêmios disponibilizados, tanto na
                        premiação instantânea quanto no sorteio.
                    </StyleWrapperCopyrights>
                </>
            </StyleWrapperBlueBackground>
            <StyleWrapperPinkBackground id="ganhadores">
                <ContainerLarge id="start" className="homeflex">
                    <StyleWrapperContent>
                        <StyleTitle>Ganhadores</StyleTitle>
                        <StyleSubtitleHome>
                            Confira aqui se você foi um dos ganhadores!
                        </StyleSubtitleHome>
                        <StyleGanhadoresWrapper>
                            <div>
                                <img
                                    src="/assets/img/vivamagia/estrelas-01.png"
                                    alt="Imagens ilustrativa - Brilhos"
                                />
                            </div>
                            <div>
                                <StyleCard>
                                    <StyleTitleCard>
                                        Premiação instantânea
                                    </StyleTitleCard>
                                    <form
                                        id="checkWinners"
                                        onSubmit={handleSubmit}
                                    >
                                        <StyleWrapperInput>
                                            <label htmlFor="cpf">
                                                INSIRA SEU CPF
                                            </label>
                                            <InputMask
                                                placeholder="Somente números"
                                                type="text"
                                                id="cpf"
                                                mask="999.999.999-99"
                                                name="cpf"
                                                onChange={(e) => {
                                                    setDocumento(
                                                        e.target.value
                                                    );
                                                }}
                                                required
                                            />
                                        </StyleWrapperInput>
                                        <StyleWrapperInput className="text-center mb-5">
                                            <Button
                                                className="w-100"
                                                type="submit"
                                                theme="theme2"
                                                disabled={status.disabled}
                                            >
                                                {status.disabled ? (
                                                    <Loader />
                                                ) : (
                                                    "CONFERIR"
                                                )}
                                            </Button>
                                        </StyleWrapperInput>
                                        {premiado && (
                                            <div
                                                style={{ textAlign: "center" }}
                                            >
                                                <StyleTitleCard>
                                                    Participante premiado!
                                                </StyleTitleCard>
                                                <p>
                                                    Clique no botão abaixo para
                                                    visualizar detalhes da
                                                    premiação
                                                </p>
                                                <Button
                                                    as="a"
                                                    theme="theme2"
                                                    href="/meus-produtos"
                                                    className="mt-2"
                                                >
                                                    Meus números
                                                </Button>
                                            </div>
                                        )}

                                        {!premiado &&
                                            Boolean(status.messages.length) && (
                                                <StyleTitleCard>
                                                    Participante não premiado
                                                </StyleTitleCard>
                                            )}
                                    </form>
                                </StyleCard>
                            </div>
                            <div>
                                <img
                                    src="/assets/img/vivamagia/estrelas-02.png"
                                    alt="Imagens ilustrativa - Brilhos"
                                />
                            </div>
                        </StyleGanhadoresWrapper>
                        <StyleTitle>Sorteio</StyleTitle>
                        <StyleSubtitleHome>
                            <div>
                                O próximo sorteio será realizado em 23/11/2024{" "}
                            </div>
                            <div>
                                Confira os ganhadores já validados
                                <div>
                                    <Button
                                        as="a"
                                        theme="theme1"
                                        href="/ganhadores"
                                        className="mt-2"
                                    >
                                        Ver Ganhadores
                                    </Button>
                                </div>
                            </div>
                            {/* <p>Confira aqui os ganhadores do 1º Sorteio realizado em 14/09</p> */}
                        </StyleSubtitleHome>
                    </StyleWrapperContent>

                    <StyleWrapperCopyrights>
                        <Text />
                    </StyleWrapperCopyrights>
                </ContainerLarge>
            </StyleWrapperPinkBackground>
        </>
    );
};

const size = {
    small: '425px',
    medium: '768px',
    large: '992px',
    xlarge: '1200px',
    xxlarge: '1400px',
}

const mediaQueries = {
    sm: `(min-width: ${size.small})`,
    md: `(min-width: ${size.medium})`,
    lg: `(min-width: ${size.large})`,
    xl: `(min-width: ${size.xlarge})`,
    xxl: `(min-width: ${size.xxlarge})`,
};

export default mediaQueries;
import Text from "./text";
import {
    StyleWrapperContentBottom,
    StyleWrapperCopyrights,
} from "./style";

export default () => {
    return (
        <>
            <StyleWrapperContentBottom>
                <StyleWrapperCopyrights>
                    <Text />
                </StyleWrapperCopyrights>
            </StyleWrapperContentBottom>
        </>
    );
};

import { useEffect, useState } from "react";
import Header from "components/header";
import api_privada from "./../../components/utils/api-privada";
import useApiRequest from "hooks/useApiRequest";
import {
    ContainerMedium,
    Loader,
    StyleTitlePageInternal,
} from "globalStyles";
import { menuList } from "components/utils/constants";
import StyleWrapperPage, {
    StyleSection,
    StyleFormWrapper,
    StyleFormFlex,
    StyleProduct,
    StyleTitleProduct,
    StyleBodyProduct,
    StyleFooterProduct,
    StyleFooterProductTitle,
    StyleFooterProductInfo,
    StyleEmptyWrapper,
} from "./style";
import Button from "components/button";
import Footer from "components/footer";

const handleCTAChance = (statusCode, chanceId, produtoCadastradoId) =>
    ({
        1: (
            <Button
                type="button"
                theme="theme2"
                as="a"
                href={`/premio-instantaneo?product-id=${produtoCadastradoId}`}
            >
                Acionar Premiador
            </Button>
        ),
        2: "",
        3: (
            <Button
                type="button"
                theme="theme2"
                as="a"
                href={`/confirmar-dados?chanceId=${chanceId}`}
            >
                Confirmar dados
            </Button>
        ),
        4: "",
        5: <div>carregando</div>,
    }[statusCode]);

export default () => {
    const { handleRequest } = useApiRequest();
    const [productList, setProductList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const handleLoadProductsSuccess = (result) => {
        if (result.data) {
            setProductList(result.data);
        }
        setLoaded(true);
    };

    const handleStatusChance = (item) => {
        if (!item.foiAcionado)
            return { status: 1, description: "NÃO ACIONADO" };
        if (item.foiAcionado && !item.foiPremiado)
            return { status: 2, description: "NÃO PREMIADO" };
        if (item.foiAcionado && item.foiPremiado && !item.dadosEnviados)
            return { status: 3, description: "PREMIADO - CONFIRME SEUS DADOS" };
        if (item.foiAcionado && item.foiPremiado && item.dadosEnviados && !item.premiacaoAprovada)
            return { status: 4, description: "PREMIADO - DOCUMENTOS EM ANÁLISE" };
        if (item.foiAcionado && item.foiPremiado && item.dadosEnviados && item.premiacaoAprovada)
            return { status: 4, description: "PREMIADO - APROVADO" };
    };

    useEffect(() => {
        handleRequest(
            () => api_privada.get("/produto-cadastrado"),
            handleLoadProductsSuccess,
            null
        );
    }, []);

    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <StyleSection>
                <ContainerMedium>
                    <StyleTitlePageInternal>
                        Meus números
                    </StyleTitlePageInternal>
                </ContainerMedium>
                <ContainerMedium>
                <StyleFormWrapper>
                        <StyleFormFlex>
                            <div>
                                {Boolean(productList.length) &&
                                    productList.map((item) => {
                                        return (
                                            <StyleProduct>
                                                <StyleTitleProduct>
                                                    <div>
                                                        <strong>
                                                            Número da sorte
                                                        </strong>
                                                        <span>
                                                            {item.numeroDaSorte}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <strong>
                                                            Casa & Estilo
                                                        </strong>
                                                    </div>
                                                </StyleTitleProduct>
                                                <StyleBodyProduct>
                                                    <div>
                                                        <strong>
                                                            Data de cadastro
                                                        </strong>
                                                        <span>
                                                            {new Date(
                                                                item.dataDeCadastro
                                                            ).toLocaleString(
                                                                "pt-BR",
                                                                "full"
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <strong>
                                                            Código de barras
                                                        </strong>
                                                        <span>
                                                            {item.codigo} -{" "}
                                                            {item.produto}
                                                        </span>
                                                    </div>
                                                </StyleBodyProduct>
                                                <StyleFooterProduct>
                                                    <StyleFooterProductTitle>
                                                        Premiação Instantânea
                                                    </StyleFooterProductTitle>
                                                    <StyleFooterProductInfo>
                                                        <div>
                                                            <strong>
                                                                Situação
                                                            </strong>
                                                            <span>
                                                                {
                                                                    handleStatusChance(
                                                                        item
                                                                    )
                                                                        .description
                                                                }

                                                                {item.premio && (
                                                                    <>
                                                                        <br />
                                                                        <small>
                                                                            {
                                                                                item.premio
                                                                            }
                                                                        </small>
                                                                    </>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            {handleCTAChance(
                                                                handleStatusChance(
                                                                    item
                                                                ).status,
                                                                item.chanceId,
                                                                item.produtoCadastradoId
                                                            )}
                                                        </div>
                                                    </StyleFooterProductInfo>
                                                </StyleFooterProduct>
                                            </StyleProduct>
                                        );
                                    })}
                                {!loaded && <Loader />}
                                {loaded && Boolean(!productList.length) && (
                                    <div>
                                        <h2 className="text-center">Você ainda não cadastrou nenhum produto</h2>
                                        <StyleEmptyWrapper>
                                            <div>
                                                <Button
                                                    as="a"
                                                    href="/cadastrar-produto"
                                                    theme="theme2"
                                                >
                                                    Cadastrar produto
                                                </Button>
                                            </div>
                                        </StyleEmptyWrapper>
                                    </div>
                                )}
                            </div>
                        </StyleFormFlex>
                    </StyleFormWrapper>
                </ContainerMedium>
                <Footer />
            </StyleSection>
        </StyleWrapperPage>
    );
};

import { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "components/utils";
import Home from "pages/home";
import Regulamento from "pages/regulamento";
import Faleconosco from "pages/fale-conosco";
import { GlobalContext } from "./contexts/global-context";
import Perfil from "pages/perfil";
import CadastrarProduto from "pages/cadastrar-produto";
import MeusProdutos from "pages/meus-produtos";
import PremioInstantaneo from "pages/premio-instantaneo";
import confirmarDados from "pages/confirmar-dados";
import Ganhadores from "pages/ganhadores";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );

export default () => {
    const [userName, setUserName] = useState(false);
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const [openNewsletterModal, setOpenNewsletterModal] = useState(false);
    const globalContextValue = {
        userName,
        setUserName,
        openAuthModal,
        setOpenAuthModal,
        openNewsletterModal,
        setOpenNewsletterModal
    };
    return (
        <GlobalContext.Provider value={globalContextValue}>
            <Router>
                <Switch>
                    <Route exact={true} path="/">
                        <Home />
                    </Route>
                    <Route path="/regulamento">
                        <Regulamento />
                    </Route>
                    <Route path="/fale-conosco">
                        <Faleconosco />
                    </Route>
                    <Route path="/ganhadores">
                        <Ganhadores />
                    </Route>
                    <PrivateRoute path="/cadastrar-produto" component={CadastrarProduto} />
                    <PrivateRoute path="/meus-produtos" component={MeusProdutos} />
                    <PrivateRoute path="/premio-instantaneo" component={PremioInstantaneo} />
                    <PrivateRoute path="/confirmar-dados" component={confirmarDados} />
                    <PrivateRoute path="/meu-perfil" component={Perfil} />
                </Switch>
            </Router>
        </GlobalContext.Provider>
    );
};

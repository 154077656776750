import styled from 'styled-components';
import breakpoint from 'components/utils/responsive';
import { colorToken } from 'components/utils/constants';


export const StyleWrapperContentBottom = styled.div`
    text-align: center;
    padding: 0 16px 32px;
    max-width: 95%;
    margin: 36px auto 0;
    position: relative;
    z-index: 1;
    @media ${breakpoint.lg} {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0;
    }
`;

export const StyleWrapperCopyrights = styled.div`
    font-size: 1rem;
    text-align: center;
    margin-top: 32px;
    padding: 0 16px 0;

    /* div a {
        color: ${colorToken.white};
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
    } */
    > div {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 12px;
    }
    a {
        color: ${colorToken.white};
        text-decoration: none;
        margin: 0 8px;
        display: inline-block ;
    }
    @media ${breakpoint.lg} {
        margin: 0;
        padding-right: 0;
        text-align: center;
        padding-bottom: 0;
    }
`;
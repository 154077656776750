export default () => {
    return (
        <>
            <div>
                <a href="/regulamento">REGULAMENTO</a>
                |
                <a href="/fale-conosco">FALE CONOSCO</a>
            </div>
            ©Disney e ©MARVEL. Período de participação da promoção entre 06/09/2024 a 30/11/2024. Consulte os regulamentos e todas as condições de participação.  CERTIFICADOS DE AUTORIZAÇÃO SPA/MF Nº 05.036558/2024 e SPA/MF Nº 04.036552/2024
        </>
    );
};

import styled from "styled-components";
import breakpoint from "components/utils/responsive";
import { colorToken } from "components/utils/constants";

export default styled.section`
    background-image: url("/assets/img/vivamagia/kv-home-mob-tablet.jpg");
    background-size: 100% auto ;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 20vh;
    padding-top: 0;

    @media ${breakpoint.lg} {
        background-image: url("/assets/img/vivamagia/kv-home-desktop-2.jpg");
        .homeflex {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 70px);
        }
    }
`;

export const StyleWrapperHomePage = styled.section`
    &:before {
        background: linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%);
        height: 150px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        content: " ";
        display: block;
        z-index: 1;
        pointer-events: none;
    }
    /* background-image: url(${({ backgroundImageMob }) =>
        backgroundImageMob}); */
    background-position: center;
    background-repeat: no-repeat;

    /* padding-top: 70px; */

    img {
        max-width: 100%;
    }

    > .mob-kv {
    }

    > .desk-kv {
        display: none;
    }
    @media ${breakpoint.lg} {
        background-image: url("/assets/img/vivamagia/kv-home-desktop-3.jpg");
        min-height: 100vh;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .homeflex {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 70px);
        }
        > .mob-kv {
            display: none;
        }

        > .desk-kv {
            display: block;
        }
    }
`;

export const StyleWrapperPinkBackground = styled.section`
    background-image: url("/assets/img/vivamagia/comoparticipar-bg.jpg");
    background-size: cover ;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding-top: 50px;

    @media ${breakpoint.lg} {
        .homeflex {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 70px);
        }
    }
`;

export const StyleWrapperBlueBackground = styled.section`
    background-image: url("/assets/img/vivamagia/premios-bg.jpg");
    background-size: cover ;
    background-position: center;
    background-repeat: no-repeat;
    /* min-height: 20vh; */
    padding-top: 50px;
    display: flex;
    flex-direction: column;

    @media ${breakpoint.lg} {
        .homeflex {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 70px);
        }
    }
`;

export const StyleWrapperContent = styled.div`
    padding: 36px 16px 0;
    @media ${breakpoint.lg} {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
    }
   
`;

export const StylePremiosCard = styled.div`
    background-color: ${colorToken.white};
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    box-shadow: 9px 8px 10px rgba(0, 0, 0, 0.2);
    img{
        height: 180px;
    }
    /* @media ${breakpoint.lg} {
        width: 350px;
        height: 350px;
        img{
            height: auto;
        }
    } */

    @media ${breakpoint.xxl} {
        width: 350px;
        height: 350px;
        img{
            height: auto;
        }
    }
`;

export const StyleCard = styled.div`
    background-color: ${colorToken.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    color: #ccc;

    p {
        font-size: 14px;
    }

    /* @media ${breakpoint.lg} {
        width: 350px;
        height: 350px;
        img{
            height: auto;
        }
    } */

    /* @media ${breakpoint.xxl} {
        width: 350px;
        height: 350px;
        img{
            height: auto;
        }
    } */
`;

export const StylePremiosSlide = styled.div`
    padding: 64px 32px 0;
    p {
        font-size: 20px;
        text-align: center;
        height: 87px;
        line-height: 24px;
        margin-top: 6px;
    }

    @media ${breakpoint.lg} {
        p {
            font-size: 24px;
            line-height: 30px;
        }
    }
`;
export const StylePremiosWrapperContent = styled.div`
    padding: 36px 0 0;
    min-height: calc(-70px + 100vh);

    .slick-next,
    .slick-prev {
        width: 52px;
        height: 52px;
        z-index: 1;
        transform: unset;
        top: 150px;
        opacity: 1;

        &:before {
            font-size: 52px;
            color: ${colorToken.color1};
            background-color: ${colorToken.white};
            border-radius: 50%;
            line-height: 60px;
            display: block;
            height: 52px;
            width: 52px;
            text-align: center;
            opacity: 1;
        }
    }
    .slick-next {
        right: 0%;
    }

    .slick-prev {
        left: 0%;
    }

    .slick-slide,
    .slick-slide ${StylePremiosSlide} {
        transition: 0.3s;
    }

    .slick-active ${StylePremiosSlide} {
        transform: scale(1.2);
    }

    @media ${breakpoint.xxl} {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        .slick-next,
    .slick-prev {
        top: 230px;

    }
        .slick-next {
            right: 38%;
        }

        .slick-prev {
            left: 38%;
        }
    }
`;

export const StyleWrapperCopyrights = styled.div`
    font-size: 1rem;
    text-align: center;
    margin-top: 32px;
    padding: 0 16px 16px;
    > div {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 12px;
    }
    a {
        color: ${colorToken.white};
        text-decoration: none;
        margin: 0 8px;
        display: inline-block;
    }

    &.homerights {
        margin: 0;
        background-color: #df366f;
    }

    @media ${breakpoint.lg} {
        &.homerights {
            width: 330px;
            margin-left: auto;
            font-size: 9px;
            padding: 8px 12px;
            text-align: right;
            background-color: transparent;
        }
    }
`;

export const StyleSubtitleHome = styled.div`
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: ${colorToken.white};
    margin-top: 0;
    margin-bottom: 12px;
    div{
        margin-bottom: 16px;
    }
    @media ${breakpoint.lg} {
        font-size: 24px;
        line-height: 124%;
    }
`;

export const StyleTitle = styled.h2`
    color: ${colorToken.white};
    font-weight: 900;
    text-transform: uppercase;
    font-size: 26px;
    text-align: center;
    @media ${breakpoint.lg} {
        font-size: 40px;
    }
`;

export const StyleComoParticiparWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
        max-width: 100%
    }

    @media ${breakpoint.lg} {
        flex-direction: row;
    }
`;

export const StyleGanhadoresWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 60px;

    > div:nth-child(2) {
        width: 100%;
        max-width: 600px;
    }

    > div:last-of-type,
    > div:last-of-type {
    }

    > div:last-of-type {
        margin-top: auto;
    }

    > div:first-of-type {
        margin-bottom: auto;
    }

    input:not([type="checkbox"], [type="radio"]),
    select {
        background-color: ${colorToken.white};
        text-align: center;
    }

    label {
        color: #6d6b6b;
        text-align: center;
        width: 100%;
    }

    img {
        max-width: 100%;
        width: 36px;
    }

    ${StyleCard} {
        display: flex;
        flex-direction: column;
        padding: 16px 16px 24px;
    }

    @media ${breakpoint.lg} {
        flex-direction: row;
        img {
            width: unset;
        }

        ${StyleCard} {
            padding: 48px 16px;
        }
    }
`;

export const StyleTitleCard = styled.div`
    font-size: 22px;
    color: ${colorToken.color1};
    font-weight: 700;
    text-align: center;
    @media ${breakpoint.lg} {
        font-size: 28px;
    }
`;
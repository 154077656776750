import axios from 'axios';

export const openInNewTab = (url) => {
    window.open(url);
};

export const scroll = (selector, isExternalLink) => {
    const location = window.location.pathname;
    const originUrl = window.location.origin;
    const section = document.querySelector(selector);
    if (
        location !== "/regulamento" &&
        !isExternalLink &&
        location !== "/fale-conosco" &&
        Boolean(section)
    ) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
        if (isExternalLink) {
            openInNewTab(`${originUrl}/${selector}`);
        } else {
            window.location.href = `${originUrl}/${selector}`;
        }
    }
};

export const getToken = () => {
    return localStorage.getItem("accessToken") || "";
};

export const refreshToken = async () => {
    const response = await axios.post('https://k7m7c8qigb.execute-api.us-east-1.amazonaws.com/prod/api/auth/refresh-token', {
        refreshToken: localStorage.getItem('refreshToken')
    });

    const { accessToken, refreshToken } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
};

export const isAuthenticated = () =>
    localStorage.getItem("accessToken") !== null;

export const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    window.location.reload();
};

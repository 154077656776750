import { useState, useEffect } from "react";
import Button from "components/button";
import InputMask from "react-input-mask";
import Header from "components/header";
import { menuList } from "components/utils/constants";
import api_privada from './../../components/utils/api-privada';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import StyleWrapperPage, { StyleWrapperContent, StyleFlexForm } from "./style";
import {
    ContainerLarge,
    ContainerMedium,
    ContainerSmall,
    StyleWrapperInput,
    StyleTitlePageInternal,
    Loader,
} from "globalStyles";
import Footer from "components/footer";

export default () => {
    const { status, handleRequest } = useApiRequest();

    const [formData, setFormData] = useState({
        nome: "",
        documento: "",
        email: "",
        dataDeNascimento: "",
        telefone: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: ""
    });

    const formatStringDateToDate = (date) => {
        const [dia, mes, ano] = date.split("/");
        return `${ano}-${mes}-${dia}`;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        const loadParticipanteData = async () => {
            const handleGetPerfilSuccess = (response) => {
                if (response.data) {

                    const [ano, mes, dia] = response.data.dataDeNascimento.split("T")[0].split("-");
                    const dataDeNascimentoFormatada = `${dia}/${mes}/${ano}`;

                    setFormData({
                        nome: response.data.nome || "",
                        documento: response.data.documento || "",
                        email: response.data.email || "",
                        dataDeNascimento: dataDeNascimentoFormatada || "",
                        telefone: response.data.telefone || "",
                        logradouro: response.data.logradouro || "",
                        numero: response.data.numero || "",
                        complemento: response.data.complemento || "",
                        bairro: response.data.bairro || "",
                        cidade: response.data.cidade || "",
                        estado: response.data.estado || "",
                        cep: response.data.cep || "",
                    });
                }
            };

            handleRequest(
                () => api_privada.get('/participante'),
                handleGetPerfilSuccess,
                null
            );
        };

        loadParticipanteData();
    }, []);

    const handleSubmitPerfilSuccess = (response) => {
        // const updatedUserData = { ...userData, nome: formData.nome };
        // localStorage.setItem("userData", JSON.stringify(updatedUserData));
    };

    const handleSubmitPerfil = async (evt) => {
        evt.preventDefault();

        const payload = {
            nome: formData.nome,
            dateDeNascimento: formatStringDateToDate(formData.dataDeNascimento),
            telefone: formData.telefone,
            logradouro: formData.logradouro,
            numero: formData.numero,
            complemento: formData.complemento,
            bairro: formData.bairro,
            cidade: formData.cidade,
            estado: formData.estado,
            cep: formData.cep
        };

        handleRequest(
            () => api_privada.put('/participante', payload),
            handleSubmitPerfilSuccess,
            "Perfil atualizado com sucesso."
        );
    };

    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <ContainerLarge className="homeflex">
                <StyleWrapperContent>
                    <div className="text-center">
                        <StyleTitlePageInternal>Meu perfil</StyleTitlePageInternal>
                    </div>
                    <ContainerMedium>
                        <form onSubmit={handleSubmitPerfil}>
                            <StyleFlexForm>
                                <div>
                                    <StyleWrapperInput>
                                        <label htmlFor="cpf">CPF</label>
                                        <InputMask
                                            placeholder="Somente números"
                                            type="text"
                                            id="cpf"
                                            mask="999.999.999-99"
                                            name="documento"
                                            value={formData.documento}
                                            onChange={handleInputChange}
                                            disabled={true}
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="nome">Nome</label>
                                        <input
                                            placeholder="Insira seu nome"
                                            type="text"
                                            id="nome"
                                            name="nome"
                                            value={formData.nome}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="dataDeNascimento">Data de nascimento</label>
                                        <InputMask
                                            placeholder="Insira sua data de nascimento"
                                            id="dataDeNascimento"
                                            type="text"
                                            mask="99/99/9999"
                                            name="dataDeNascimento"
                                            value={formData.dataDeNascimento}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="email">E-mail</label>
                                        <input
                                            placeholder="Insira seu e-mail"
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            disabled={true}
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="telefone">Celular</label>
                                        <InputMask
                                            placeholder="Insira seu telefone celular"
                                            mask="(99) 99999-9999"
                                            id="telefone"
                                            name="telefone"
                                            value={formData.telefone}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>

                                </div>
                                <div>
                                    <StyleFlexForm style={{padding: "0"}}>
                                    <StyleWrapperInput>
                                        <label htmlFor="cep">CEP</label>
                                        <InputMask
                                            mask="99999-999"
                                            placeholder="Insira seu CEP, somente números"
                                            type="text"
                                            id="cep"
                                            name="cep"
                                            value={formData.cep}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="logradouro">Endereço / Logradouro</label>
                                        <input
                                            placeholder="Avenida, Rua..."
                                            type="text"
                                            id="logradouro"
                                            name="logradouro"
                                            value={formData.logradouro}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    </StyleFlexForm>
                                    <StyleFlexForm style={{padding: "0"}}>
                                    <StyleWrapperInput>
                                        <label htmlFor="numero">Numero</label>
                                        <input
                                            placeholder="100"
                                            type="text"
                                            id="numero"
                                            name="numero"
                                            value={formData.numero}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="complemento">Complemento</label>
                                        <input
                                            placeholder="Casa B"
                                            type="text"
                                            id="complemento"
                                            name="complemento"
                                            value={formData.complemento}
                                            onChange={handleInputChange}
                                        />
                                    </StyleWrapperInput>
                                    </StyleFlexForm>
                                    <StyleWrapperInput>
                                        <label htmlFor="bairro">Bairro</label>
                                        <input
                                            placeholder="Centro"
                                            type="text"
                                            id="bairro"
                                            name="bairro"
                                            value={formData.bairro}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="cidade">Cidade</label>
                                        <input
                                            placeholder="Cidade"
                                            type="text"
                                            id="cidade"
                                            name="cidade"
                                            value={formData.cidade}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </StyleWrapperInput>
                                    <StyleWrapperInput>
                                        <label htmlFor="estado">Estado</label>
                                        <select
                                            id="estado"
                                            name="estado"
                                            value={formData.estado}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Selecione um estado</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </StyleWrapperInput>
                                </div>
                            </StyleFlexForm>
                            <ContainerSmall>
                                <StatusMessage error={status.error} messages={status.messages} />
                                <StyleWrapperInput className="text-center mb-5">
                                    <Button
                                        className="w-100"
                                        type="submit"
                                        theme="theme2"
                                        disabled={status.disabled}
                                    >
                                        {(status.disabled) ? <Loader /> : "Atualizar cadastro"}
                                    </Button>
                                </StyleWrapperInput>
                            </ContainerSmall>
                        </form>
                    </ContainerMedium>
                </StyleWrapperContent>
                <Footer />
            </ContainerLarge>
        </StyleWrapperPage>
    );
};

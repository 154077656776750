import styled from "styled-components";
import { colorToken } from "components/utils/constants";
import breakpoint from "components/utils/responsive";
import StyleButton, { StyleButtonContent } from "components/button/style";

export default styled.section`
    min-height: 100vh;
    padding-top: 70px;

    &:before {
        background-image: url("/assets/img/vivamagia/bg-internas.jpg");
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0px;
        left: 0px;
        content: " ";
        display: block;
        z-index: 1;
        pointer-events: none;
    }

    @media ${breakpoint.lg} {
        padding-top: 90px;
    }

    textarea {
        width: 100%;
        border: 0;
        line-height: 128%;
        padding: 16px;
        font-size: 1.6rem;
        min-height: 130px;
        border-radius: 8px;
        box-shadow: rgb(0, 0, 0) -3px 4px 11px -2px;
    }

    textarea {
        height: 100%;
    }
`;

export const StyleSection = styled.section`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);
    > div:first-of-type {
        margin: 0 auto;
        margin-bottom: auto;
    }
    > div:last-of-type {
        margin-top: auto;
    }
`;

export const StyleTitle = styled.h3`
    font-size: 2.2rem;
    color: ${colorToken.white};
    font-weight: bold;
    text-align: left;
    width: 100%;
    max-width: 898px;
    margin: 0 auto;
    padding-bottom: 16px;
    padding: 0 16px 0;
`;

export const StyleAccordeonWrapper = styled.div`
    max-width: 898px;
    padding: 16px;
    margin: 0 auto;
`;

export const StyleFormWrapper = styled.div`
    max-width: 1200px;
    padding: 0 16px 16px;
    margin: 24px auto 0;
`;

export const StyleInputGroup = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 8px;
`;

export const StyleInputIcon = styled.div`
    width: 43px;
    text-align: center;
    background-color: ${colorToken.color3};
    padding: 12px 0;
    border-radius: 8px 0 0 8px;
`;

export const StyleInput = styled.div`
    flex-grow: 1;
    input,
    
`;

export const StyleCallbackResponse = styled.div``;

export const StyleCallbackError = styled.div`
    width: 100%;
    background-color: ${colorToken.color3};
    color: ${colorToken.white};
    font-size: 1.6rem;
    font-weight: bold;
    border-radius: 8px;
    margin: 8px 0;
`;

export const StyleCallbackSuccess = styled.div`
    width: 100%;
    background-color: ${colorToken.color2};
    color: ${colorToken.color3};
    font-size: 1.6rem;
    font-weight: bold;
    border-radius: 8px;
    margin: 8px 0;
    padding: 8px 0;
`;

export const StyleFormFlex = styled.div`
    width: 100%;
    & > div:first-of-type {
        margin-bottom: 28px;
    }

    img {
        max-width: 100%;
    }
    @media ${breakpoint.lg} {
        display: flex;
        align-items: center;
        justify-content: center;
        & > div:first-of-type {
            width: 50%;
            padding-right: 24px;
        }
        & > div:last-of-type {
            width: 50%;
            padding-left: 24px;
        }
    }
`;

export const StyleProduct = styled.div`
    background-color: ${colorToken.white};
    color: #7e7e7e;
    padding: 16px;
    font-size: 12px;
    border-radius: 16px;
    margin-bottom: 30px;
    /* font-weight: 600; */
    strong,
    span {
        display: block;
    }
    strong {
        font-weight: 700;
        font-size: 14px;
    }
`;
export const StyleTitleProduct = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid ${colorToken.color1};
    margin-bottom: 10px;
    span {
        color: ${colorToken.color1};
        font-weight: 900;
        font-size: 18px;
    }
`;
export const StyleBodyProduct = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: column;
    > div:first-of-type {
        margin-right: 16px;
    }
    @media ${breakpoint.md} {
        flex-direction: row;
    }
`;
export const StyleFooterProduct = styled.div``;
export const StyleFooterProductTitle = styled.div`
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 700;
`;
export const StyleFooterProductInfo = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${StyleButton} {
        width: auto;
        text-align: center;
    }
    ${StyleButtonContent} {
        padding: 8px 16px;
        font-size: 16px;
    }
    span {
        color: ${colorToken.color1};
        font-weight: 700;
        font-size: 14px;
    }

    @media ${breakpoint.md} {
        flex-direction: row;
    }
`;

export const StyleEmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 12px 0;
    align-items: center;
    > div:first-of-type {
        margin-bottom: 16px;
    }
    @media ${breakpoint.md} {
        flex-direction: row;
        justify-content: center;
        > div:first-of-type {
            margin-bottom: 0;
            margin-right: 0;
            text-align: center;
        }
        > div:last-of-type {
        }
        margin: 12px 0;
    }
`;

import styled from 'styled-components';
import breakpoint from 'components/utils/responsive';
import { colorToken } from 'components/utils/constants';

export default styled.div`
    position: fixed;
    overflow-y:  ${({ open }) => (open ? "auto" : "hidden")};
    background-color: rgba(0,0,0,0.9);
    top: 0;
    left: 0;
    right: 0;
    z-index: 420;
    height: ${({ open }) => (open ? "100vh" : "0vh")};
    transition: all linear 0.4s;
    color: ${colorToken.color2};;
    display: flex;
    & > div{
        padding: 16px;
        display: flex;
        height: fit-content;
        margin: auto;
    }
    @media ${breakpoint.md} {

    }

    @media ${breakpoint.xl} {

    }

`;

export const StyleContainerModal = styled.div`
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    border-radius: 20px;
    max-width: 948px;
    margin: auto;
    padding-bottom: 32px;
`;

export const StyleHeaderAuthModal = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 16px;
    flex-direction: column;
`
export const StyleTitleModal = styled.h2`
    font-size: 3.2rem;
    color: ${colorToken.color2};
    font-weight: 700;
`
export const StyleButtonClose = styled.button`
    font-weight: 700;
    color: ${colorToken.white};
    border-radius: 50%;
    font-size: 2.5rem;
    text-align: center;
    line-height: 36px;
    margin-left: auto;
    background-color: transparent;
    margin-bottom: 12px;
    span{
        color: ${colorToken.color1};
        margin-right: 8px;
        font-size: 18px;
    }
`
export const StyleContentModal = styled.div``

export const StyleSubtitleModal = styled.h3`
    font-size: 2.2rem;
    color: ${colorToken.color1};
    font-weight: 700;
    text-align: center;
`;


import { useState } from "react";
import api_publica from './../../utils/api-publica';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import {
    StyleWrapperInput,
    Loader
} from "globalStyles";
import StyleWrapper from "./style";
import Button from "components/button";

export default ({ setOpenNewsletterModal }) => {
    const { status, handleRequest } = useApiRequest();

    const [email, setEmail] = useState(null);

    const handleSuccess = (response) => {
    };

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        handleRequest(
            () => api_publica.post('/newsletter', { email: email }),
            handleSuccess,
            "Sucesso! Seu email foi cadastrado."
        );
    };

    return (
        <StyleWrapper>
            <form onSubmit={(e) => handleSubmit(e)}>
                    <StyleWrapperInput>
                        <label htmlFor="email">E-mail</label>
                        <input
                            placeholder="Insira seu e-mail"
                            type="email"
                            id="email"
                            name="email"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            required
                        />
                    </StyleWrapperInput>
                    <StyleWrapperInput className="text-center">
                        <Button className="w-100" type="submit" disabled={status.disabled} theme="theme2">
                        {(status.disabled) ? <Loader /> : "ENVIAR"}
                        </Button>
                    </StyleWrapperInput>

                    <StatusMessage error={status.error} messages={status.messages} />
                </form>
        </StyleWrapper>
    );
};

import { useState } from 'react';

const useApiRequest = () => {

    const [status, setStatus] = useState({ error: false, success: false, disabled: false, messages: [] });

    const handleRequest = async (requestFn, successCallback, successMessage) => {

        setStatus({ error: false, success: false, disabled: true, messages: [] });

        try {

            const response = await requestFn();

            if (successCallback) {
                successCallback(response);
            }

            setStatus({
                error: false,
                success: true,
                disabled: false,
                messages: successMessage ? [successMessage] : []
            });

        } catch (error) {
            
            const { response } = error;

            let messages = ["Não foi possível finalizar a sua requisição. Ocorreu um erro."];

            if (error.response?.data && Array.isArray(error.response.data)) {
                messages = error.response.data.map(err => err.message);
            }

            setStatus({
                error: true,
                false: true,
                disabled: false,
                messages: messages
            });
        }
    };

    return { status, setStatus, handleRequest };
};

export default useApiRequest;

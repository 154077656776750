import { useState } from "react";
import api_publica from './../../utils/api-publica';
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import InputMask from "react-input-mask";
import Button from "components/button";
import {
    StyleWrapperInput,
    StyleWrapperInputCheckBox,
    StyleMessageBox,
    ContainerMedium,
    ContainerSmall,
    Loader
} from "globalStyles";
import { StyleSubtitleModal } from "../style";
import StyleRegisterWrapper, { StyleFlexForm } from "./style";

export default ({ setShowLogin, setShowRegister, showRegister }) => {
    const { status, handleRequest } = useApiRequest();

    const [formData, setFormData] = useState({
        nome: "",
        documento: "",
        email: "",
        confirmaEmail: "",
        dataDeNascimento: "",
        telefone: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        cep: "",
        password: "",
        confirmPassword: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleShowLogin = () => {
        setShowRegister(false);
        setTimeout(() => setShowLogin(true), 200);
    };

    const handleSuccess = (response) => {
    };

    const handleSubmitRegister = async (evt) => {
        evt.preventDefault();

        const [dia, mes, ano] = formData.dataDeNascimento.split("/");
        const dataDeNascimento = `${ano}-${mes}-${dia}`;

        const payload = {
            nome: formData.nome,
            documento: formData.documento,
            email: formData.email,
            dateDeNascimento: dataDeNascimento,
            telefone: formData.telefone,
            logradouro: formData.logradouro,
            numero: formData.numero,
            complemento: formData.complemento,
            bairro: formData.bairro,
            cidade: formData.cidade,
            estado: formData.estado,
            cep: formData.cep,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
            aceite: true,
        };

        handleRequest(
            () => api_publica.post('/participante', payload),
            handleSuccess,
            "Cadastro realizado com sucesso. Faça o login."
        );
    };

    return (
        <StyleRegisterWrapper open={showRegister}>
            <ContainerMedium>
                <form onSubmit={handleSubmitRegister}>
                    <StyleFlexForm>
                        <div>
                            <StyleWrapperInput>
                                <label htmlFor="documento">CPF</label>
                                <InputMask
                                    placeholder="Somente números"
                                    type="text"
                                    id="documento"
                                    name="documento"
                                    mask="999.999.999-99"
                                    value={formData.documento}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="nome">Nome</label>
                                <input
                                    placeholder="Insira seu nome"
                                    type="text"
                                    id="nome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="dataDeNascimento">
                                    Data de nascimento
                                </label>
                                <InputMask
                                    placeholder="Insira sua data de nascimento"
                                    id="dataDeNascimento"
                                    type="text"
                                    mask="99/99/9999"
                                    name="dataDeNascimento"
                                    value={formData.dataDeNascimento}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="email">E-mail</label>
                                <input
                                    placeholder="Insira seu e-mail"
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="confirmEmail">
                                    Confirme seu e-mail
                                </label>
                                <input
                                    placeholder="Confirme seu e-mail"
                                    type="email"
                                    id="confirmEmail"
                                    name="confirmEmail"
                                    value={formData.confirmEmail}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="telefone">Celular</label>
                                <InputMask
                                    placeholder="Insira seu celular"
                                    mask="(99) 99999-9999"
                                    id="telefone"
                                    name="telefone"
                                    value={formData.telefone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                        </div>
                        <div>
                            <StyleWrapperInput>
                                <label htmlFor="cep">CEP</label>
                                <InputMask
                                    mask="99999-999"
                                    placeholder="Insira seu CEP, somente números"
                                    type="text"
                                    id="cep"
                                    name="cep"
                                    value={formData.cep}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="logradouro">
                                    Endereço / Logradouro
                                </label>
                                <input
                                    placeholder="Ex: Avenida Endereço"
                                    type="text"
                                    id="logradouro"
                                    name="logradouro"
                                    value={formData.logradouro}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleFlexForm style={{padding: "0"}}>
                                <StyleWrapperInput>
                                    <label htmlFor="numero">Numero</label>
                                    <input
                                        placeholder="474"
                                        type="text"
                                        id="numero"
                                        name="numero"
                                        value={formData.numero}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </StyleWrapperInput>
                                <StyleWrapperInput>
                                    <label htmlFor="complemento">
                                        Complemento
                                    </label>
                                    <input
                                        placeholder="Casa B"
                                        type="text"
                                        id="complemento"
                                        name="complemento"
                                        value={formData.complemento}
                                        onChange={handleInputChange}
                                    />
                                </StyleWrapperInput>
                            </StyleFlexForm>
                            <StyleWrapperInput>
                                <label htmlFor="bairro">Bairro</label>
                                <input
                                    placeholder="Centro"
                                    type="text"
                                    id="bairro"
                                    name="bairro"
                                    value={formData.bairro}
                                    onChange={handleInputChange}
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="cidade">Cidade</label>
                                <input
                                    placeholder="Insira sua cidade"
                                    type="text"
                                    id="cidade"
                                    name="cidade"
                                    value={formData.cidade}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                            <StyleWrapperInput>
                                <label htmlFor="estado">Estado</label>
                                <select
                                    id="estado"
                                    name="estado"
                                    value={formData.estado}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">
                                        Selecione um estado
                                    </option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">
                                        Mato Grosso do Sul
                                    </option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">
                                        Rio Grande do Norte
                                    </option>
                                    <option value="RS">
                                        Rio Grande do Sul
                                    </option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </StyleWrapperInput>
                        </div>
                    </StyleFlexForm>
                    <StyleFlexForm>
                        <div>
                            <StyleWrapperInput>
                                <label htmlFor="password">Senha</label>
                                <input
                                    placeholder="Insira sua senha"
                                    id="password"
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                        </div>
                        <div>
                            <StyleWrapperInput>
                                <label htmlFor="confirmPassword">
                                    Confirme sua senha
                                </label>
                                <input
                                    placeholder="Insira sua senha novamente"
                                    id="confirmPassword"
                                    type="password"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    required
                                />
                            </StyleWrapperInput>
                        </div>
                    </StyleFlexForm>

                    <StyleWrapperInputCheckBox>
                        <input
                            id="optin1"
                            type="checkbox"
                            name="optin1"
                            required
                        />
                        <label htmlFor="optin1">
                            Declaro que as informações prestadas são verdadeiras
                            e que li e estou de acordo com o{" "}
                            <a href="/" rel="noreferrer" target="_blank">
                                regulamento
                            </a>
                            .
                        </label>
                    </StyleWrapperInputCheckBox>
                    <StyleWrapperInputCheckBox>
                        <input
                            id="optin2"
                            type="checkbox"
                            name="optin2"
                            required
                        />
                        <label htmlFor="optin1">
                            Estou de acordo com a política de privacidade para
                            Clientes Avon, disponível{" "}
                            <a
                                href="https://www.avon.com/"
                                rel="noreferrer"
                                target="_blank"
                            >
                                aqui
                            </a>
                            , e estou ciente que os meus dados também serão
                            tratados para a realização, operacionalização,
                            entrega da premiação, divulgação e coclusão da
                            promoção, incluindo o compartilhamento com o orgão
                            autorizador para fins de fiscalzação.
                        </label>
                    </StyleWrapperInputCheckBox>

                    <ContainerSmall>
                        <StatusMessage
                            error={status.error}
                            messages={status.messages}
                        />

                        {!status.success && (
                            <StyleWrapperInput className="text-center mb-5">
                                <Button
                                    className="w-100"
                                    type="submit"
                                    theme="theme2"
                                    disabled={status.disabled}
                                >
                                    {status.disabled ? (
                                        <Loader />
                                    ) : (
                                        "Efetuar cadastro"
                                    )}
                                </Button>
                            </StyleWrapperInput>
                        )}
                    </ContainerSmall>
                </form>

                <ContainerSmall>
                    <StyleSubtitleModal>Já é cadastrado?</StyleSubtitleModal>
                    <StyleWrapperInput className="text-center mb-5">
                        <Button
                            className="w-100"
                            onClick={() => handleShowLogin()}
                        >
                            Fazer login
                        </Button>
                    </StyleWrapperInput>
                </ContainerSmall>
            </ContainerMedium>
        </StyleRegisterWrapper>
    );
};

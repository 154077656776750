import { useState, useContext, useEffect } from "react";
import { scroll, logout } from "components/utils";
import {
    StyleHeader,
    StyleNav,
    StyleMenu,
    StyleMenuItem,
    StyleOptionsMobile,
    StyleButtonHamburger,
    StyleNavActions,
    StyleButtonActions,
    StyleLogoMobile,
    StyleLogoDesktop,
} from "./style";
import { ContainerLarge } from "globalStyles";
import AuthModal from "components/auth-modal";
import NewsletterModal from "components/newsletter-modal";
import { GlobalContext } from "contexts/global-context";
export default ({ theme, menuList }) => {
    const [sticky, setSticky] = useState("");
    const { openAuthModal, setOpenAuthModal, openNewsletterModal } = useContext(GlobalContext);
    const [showMenu, setShowMenu] = useState(false);
    const token = localStorage.getItem("accessToken") || false;
    
    useEffect(() => {
        console.log("hello");
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        }
    },[]);

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 40 ? "is-sticky" : "";
        setSticky(stickyClass);
        console.log(stickyClass);
    };

    return (
        <>
            {!token && <AuthModal opened={openAuthModal} />}
            <NewsletterModal opened={openNewsletterModal} />
            <StyleHeader theme={theme} className={sticky}>
                <ContainerLarge>
                    <StyleNav>
                        <StyleOptionsMobile>
                            <div>
                                <StyleButtonHamburger
                                    onClick={() => setShowMenu(!showMenu)}
                                    className={showMenu && "opened"}
                                    ariaExpanded={showMenu}
                                    aria-label="Main Menu"
                                >
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 100 100"
                                    >
                                        <path
                                            className="line line1"
                                            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                                        />
                                        <path
                                            className="line line2"
                                            d="M 20,50 H 80"
                                        />
                                        <path
                                            className="line line3"
                                            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                                        />
                                    </svg>
                                </StyleButtonHamburger>
                            </div>
                            <StyleLogoMobile
                                src="/assets/img/vivamagia/casaestilo-header.svg"
                                alt="AVON"
                            />
                            <div>
                                {token && (
                                    <>
                                        <StyleButtonActions
                                            as="a"
                                            href="/meu-perfil"
                                        >
                                            Meu Perfil
                                        </StyleButtonActions>
                                        <StyleButtonActions
                                            type="button"
                                            theme="theme3"
                                            onClick={() => logout()}
                                        >
                                            Sair
                                        </StyleButtonActions>
                                    </>
                                )}
                                {!token && (
                                    <>
                                        <StyleButtonActions
                                            type="button"
                                            theme="theme3"
                                            onClick={() =>
                                                setOpenAuthModal(true)
                                            }
                                        >
                                            <img
                                                src="/assets/img/vivamagia/icon-signin.svg"
                                                alt="Ícone de usuário"
                                            />
                                            Entrar
                                        </StyleButtonActions>
                                    </>
                                )}
                            </div>
                        </StyleOptionsMobile>
                        <StyleLogoDesktop
                            src="/assets/img/vivamagia/casaestilo-header.svg"
                            alt="Casa & Estilo"
                        />
                        <StyleMenu showMenu={showMenu}>
                            {menuList.map((item) => {
                                return (
                                    <StyleMenuItem
                                        key={item.link}
                                        className={
                                            item.img && "d-none d-md-inline"
                                        }
                                    >
                                        {item.externalLink && (
                                            <a
                                                href={item.link}
                                                rel="noreferrer"
                                                target={
                                                    item.isBlank
                                                        ? "_blank"
                                                        : "_self"
                                                }
                                                onClick={() => {
                                                    setShowMenu(false);
                                                }}
                                            >
                                                {item.img && (
                                                    <img
                                                        src={item.img}
                                                        alt={item.title}
                                                    />
                                                )}
                                                {!item.img && item.title}
                                            </a>
                                        )}
                                        {!item.externalLink && (
                                            <a
                                                href={item.link}
                                                onClick={() => {
                                                    scroll(
                                                        item.link,
                                                        item.externalLink
                                                    );
                                                    setShowMenu(false);
                                                }}
                                            >
                                                {item.img && (
                                                    <img
                                                        src={item.img}
                                                        alt={item.title}
                                                    />
                                                )}
                                                {!item.img && item.title}
                                            </a>
                                        )}
                                    </StyleMenuItem>
                                );
                            })}

                            {!token && (
                                <>
                                    <StyleMenuItem>
                                        <span
                                            rel="noreferrer"
                                            role="button"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setOpenAuthModal(true);
                                            }}
                                        >
                                            Cadastrar Produto
                                        </span>
                                    </StyleMenuItem>
                                </>
                            )}
                            {token && (
                                <>
                                    <StyleMenuItem>
                                        <a
                                            href="/cadastrar-produto"
                                            rel="noreferrer"
                                            onClick={() => {
                                                setShowMenu(false);
                                            }}
                                        >
                                            Cadastrar Produto
                                        </a>
                                    </StyleMenuItem>
                                    <StyleMenuItem>
                                        <a
                                            href="/meus-produtos"
                                            role="button"
                                            onClick={() => {
                                                setShowMenu(false);
                                                setOpenAuthModal(true);
                                            }}
                                        >
                                            Meus Números
                                        </a>
                                    </StyleMenuItem>
                                </>
                            )}
                        </StyleMenu>
                        <StyleNavActions>
                            {token && (
                                <>
                                    <StyleButtonActions
                                        as="a"
                                        href="/meu-perfil"
                                        theme="theme3"
                                    >
                                        Meu Perfil
                                    </StyleButtonActions>
                                    <StyleButtonActions
                                        type="button"
                                        theme="theme3"
                                        onClick={() => logout()}
                                    >
                                        Sair
                                    </StyleButtonActions>
                                </>
                            )}
                            {!token && (
                                <>
                                    <StyleButtonActions
                                        role="button"
                                        onClick={() => setOpenAuthModal(true)}
                                    >
                                        <img
                                            src="/assets/img/vivamagia/icon-signin.svg"
                                            alt="Ícone de usuário"
                                        />
                                        Entrar
                                    </StyleButtonActions>
                                </>
                            )}
                        </StyleNavActions>
                    </StyleNav>
                </ContainerLarge>
            </StyleHeader>
        </>
    );
}

import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import api_privada from "./../../components/utils/api-privada";
import StatusMessage from "components/status-message";
import useApiRequest from "hooks/useApiRequest";
import Header from "components/header";
import animationData from "./stars-final.json";
import {
    ContainerMedium,
    StyleTitlePageInternal,
} from "globalStyles";
import { menuList } from "components/utils/constants";
import StyleWrapperPage, {
    StyleSection,
    StyleFormWrapper,
    StyleCallbackResponse,
    StyleFormFlex,
    StyleGanhadoresWrapper,
    StyleTitleCard,
    StyleCard,
} from "./style";
import Button from "components/button";
import Footer from "components/footer";

export default () => {
    const { status, handleRequest } = useApiRequest();
    const searchParams = new URLSearchParams(window.location.search);
    const tokenAccess = searchParams.get("product-id");
    const [chanceId, setChanceId] = useState(0);
    const [disabledPlay, setDisabledPlay] = useState(false);
    const [isStopped, setIsStopped] = useState(true);
    const [waitCTA, setWaitCTA] = useState(true);
    const [played, setPlayed] = useState(false);
    const [winner, setWinner] = useState(false);
    const [premio, setPremio] = useState(0);
    const premiosLiteral = {
        "1 (uma) Caneca Disney Ariel": "1umaCanecaDisneyAriel.png",
        "1 (uma) Toalha Avengers": "1umaToalhaAvengers.png",
        "1 (uma) Garrafa 3d Donald": "1umaGarrafa3dDonald.png",
        "1 (um) Organizador Clutch Branca de Neve (Disney)": "1OrganizadorClutchBrancadeNeveDisney.png",
        "1 (um) Organizador Clutch Tiana": "1umOrganizadorClutchTiana.png",
        "1 (um) Pote Com Tampa Minnie": "1umPoteComTampaMinnie.png",
        "1 (um) Organizador Clutch Ariel": "1umOrganizadorClutchAriel.png",
        "1 (um) Copo Simples Fem Disney Moana": "1umCopoSimplesFemDisneyMoana.png",
        "1 (uma) Marmita Com Travas Pequena Sereia 2023": "1umaMarmitaComTravasPequenaSereia2023.png",
        "1 (um) Copo Canudo Quadrado Cinderela": "1umCopoCanudoQuadradoCinderela.png",
        "1 (um) Pote Quebra Cabeca Spider Children Pink": "1umPoteQuebraCabecaSpiderChildrenPink.png",
        "1 (uma) Garrafa Foguete Lightyear": "1umaGarrafaFogueteLightyear.png",
        "1 (uma) Garrafa C Compart 360ml Fem Children": "1umaGarrafaCCompart360mlFemChildren.png",
        "1 (uma) Escova De Dente Ventosa Mickey Children": "1umaEscovaDeDenteVentosaMickeyChildren.png",
        "1 (um) Kit 2 Potes Com Orelhas Minnie": "1umKit2PotesComOrelhasMinnie.png",
        "1 (uma) Sanduicheira Minnie": "1OrganizadorClutchBrancadeNeveDisney.png"
    }
    const defaultOptions = {
        loop: true,
        autoplay: false,
        animationData: animationData,
    };
    const handleStartGame = () => {
        setWaitCTA(false);
        setIsStopped(false);
        const payload = {
            produtoCadastradoId: tokenAccess,
        };

        const handleSuccessGame = (result) => {
            const { chanceId, acionamentoPremiado, brinde } = result.data;
            setPlayed(true);
            if (acionamentoPremiado) {
                setWinner(true);
                setChanceId(chanceId);
                setPremio(brinde);
            } else {
                setWinner(false);
            }
        };

        setTimeout(() => {
            handleRequest(
                () => api_privada.post("/chance/acionamento", payload),
                handleSuccessGame,
                null
            );
        }, 3500);
    };

    useEffect(() => {
        if (!tokenAccess) {
            window.location.href = "/";
        }
    });

    useEffect(() => {
        if (!waitCTA) {
            setDisabledPlay(true);
        }
    }, [waitCTA]);

    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <StyleSection>
                <ContainerMedium>
                    <StyleTitlePageInternal>
                        Premiador instantâneo
                    </StyleTitlePageInternal>
                    <StyleFormWrapper>
                        <StyleFormFlex>
                            {!played && (
                                <div>
                                    {waitCTA && (
                                        <div className="text-center">
                                            <img
                                                src="/assets/img/vivamagia/estrelas.png"
                                                alt="Imagem ilustrativa - Pre animação"
                                            />
                                        </div>
                                    )}
                                    {!waitCTA && (
                                        <Lottie
                                            options={defaultOptions}
                                            isStopped={isStopped}
                                        />
                                    )}
                                    <Button
                                        type="button"
                                        theme="theme2"
                                        onClick={() => handleStartGame()}
                                        disabled={disabledPlay}
                                    >
                                        Clique aqui e participe!
                                    </Button>
                                </div>
                            )}
                            {played && (
                                <>
                                    {!winner && (
                                        <div>
                                            <StyleGanhadoresWrapper>
                                                <div>
                                                    <img
                                                        src="/assets/img/vivamagia/estrelas-01.png"
                                                        alt="Imagens ilustrativa - Brilhos"
                                                    />
                                                </div>
                                                <div>
                                                    <StyleCard>
                                                        <StyleTitleCard>
                                                            Não foi desta vez!
                                                        </StyleTitleCard>
                                                        <p>
                                                            Mas continue
                                                            aproveitando os
                                                            produtos Casa &
                                                            Estilo e fique
                                                            ligado em nossas
                                                            promoções!
                                                        </p>
                                                    </StyleCard>
                                                </div>
                                                <div>
                                                    <img
                                                        src="/assets/img/vivamagia/estrelas-02.png"
                                                        alt="Imagens ilustrativa - Brilhos"
                                                    />
                                                </div>
                                            </StyleGanhadoresWrapper>
                                            <p>
                                                Aproveite essa oportunidade
                                                única e participe agora mesmo!
                                            </p>
                                            <Button
                                                as="a"
                                                href="/meus-produtos"
                                                theme="theme2"
                                            >
                                                Consultar meus números
                                            </Button>
                                        </div>
                                    )}
                                    {winner && (
                                        <div>
                                            <StyleGanhadoresWrapper>
                                                <div>
                                                    <img
                                                        src="/assets/img/vivamagia/estrelas-01.png"
                                                        alt="Imagens ilustrativa - Brilhos"
                                                    />
                                                </div>
                                                <div>
                                                    <StyleCard>
                                                        <StyleTitleCard>
                                                            Parabéns! Você
                                                            Ganhou!
                                                        </StyleTitleCard>
                                                        <img
                                                            src={`/assets/img/vivamagia/premios/${premiosLiteral[premio]}`}
                                                            alt="imagem ilustrativa"
                                                            className="img-fluid"
                                                        />
                                                        <p>
                                                            01 Sanduicheira
                                                            Minnie
                                                        </p>
                                                    </StyleCard>
                                                </div>
                                                <div>
                                                    <img
                                                        src="/assets/img/vivamagia/estrelas-02.png"
                                                        alt="Imagens ilustrativa - Brilhos"
                                                    />
                                                </div>
                                            </StyleGanhadoresWrapper>
                                            <p>
                                                Clique abaixo para finalizar as
                                                informações e receber o seu
                                                prêmio!
                                            </p>
                                            <Button
                                                as="a"
                                                href={`/confirmar-dados?chanceId=${chanceId}`}
                                                theme="theme2"
                                            >
                                                Continuar
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )}
                        </StyleFormFlex>
                        <StyleCallbackResponse>
                            <StatusMessage
                                error={status.error}
                                messages={status.messages}
                            />
                        </StyleCallbackResponse>
                    </StyleFormWrapper>
                </ContainerMedium>
                <Footer />
            </StyleSection>
        </StyleWrapperPage>
    );
};

import styled from 'styled-components';
import { colorToken } from 'components/utils/constants';
import breakpoint from 'components/utils/responsive';
const ReturnTheme = (theme) => {
    const objThemes = {
        'theme1': {
            background: colorToken.white,
            border: colorToken.white,
            textColor: colorToken.color2,
            backgroundHover: colorToken.color2,
        },
        'theme2': {
            background: colorToken.color2,
            border: colorToken.white,
            textColor: colorToken.white,
            backgroundHover: colorToken.color1,
        },
        'theme3': {
            background: colorToken.color3,
            border: colorToken.white,
            textColor: colorToken.white,
            backgroundHover: colorToken.color1,
        },
    }

    return objThemes[theme] || objThemes.theme1;
}

export const StyleButtonContent = styled.div`
    position: relative;
    border-radius: 8px;
    padding: 8px 32px;
    color: ${colorToken.color1};
    font-size: 1.9rem;
    font-weight: bold;
    @media ${breakpoint.md} {
        font-size: 2rem;
        padding: 8px 64px;
    }
`;

export default styled.button`
    position: relative;
    padding-bottom: 5px;
    padding: 0;
    margin-bottom: 0px;
    background: transparent;
    border: 0;
    display: inline-block;
    text-decoration: none;

    ${StyleButtonContent}{
        color: ${({ theme }) => ReturnTheme(theme).textColor};
        background: ${({ theme }) => ReturnTheme(theme).background};
    }

    &:hover, &:active{
        ${StyleButtonContent}{
            color: ${({ theme }) => ReturnTheme(theme).textColor};
        } 
        &:before {
            background-color: ${({ theme }) => ReturnTheme(theme).backgroundHover};
        } 
    }

    &:disabled{
        opacity: 0.7;
    }
    @media ${breakpoint.md} {
        
    }
    @media ${breakpoint.lg} {

    }
    @media ${breakpoint.xl} {

    }
    @media ${breakpoint.xxl} {

    }
`;

